// eslint-disable-next-line import/no-cycle
import apolloProvider from '@/libs/vue-apollo'
import mutations from './mutations'
import queries from './queries'

export default {
  getAllEmployees(data) {
    return apolloProvider.query({
      query: queries.GET_ALL_EMPLOYEES,
      fetchPolicy: 'no-cache',
      variables: { ...data },
    })
  },
  createInputProvider(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_INPUT_PROVIDER,
      variables: { ...data },
    })
  },
  getRegions(data) {
    return apolloProvider.query({
      query: queries.GET_REGIONS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  updateEmployees(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_EMPLOYEES,
      variables: {
        ...data,
      },
    })
  },
  removeEmployee(data) {
    return apolloProvider.mutate({
      mutation: mutations.REMOVE_EMPLOYEE,
      variables: {
        ...data,
      },
    })
  },
  updateRegion(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_REGION,
      variables: {
        ...data,
      },
    })
  },
  getCoverageTypes() {
    return apolloProvider.query({
      query: queries.GET_ALL_COVERAGE_TYPES,
      fetchPolicy: 'no-cache',
    })
  },
  createCoverageCategory(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_COVERAGE_CATEGORY,
      variables: {
        ...data,
      },
    })
  },
  updateCoverageCategory(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_COVERAGE_CATEGORY,
      variables: {
        ...data,
      },
    })
  },
  updateCoverages(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_COVERAGES,
      variables: {
        ...data,
      },
    })
  },
  getCoveragesByCategoryInRegion(data) {
    return apolloProvider.query({
      query: queries.GET_COVERAGES_BY_CATEGORY_IN_REGION,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  getOnlyRegions(data) {
    return apolloProvider.query({
      query: queries.GET_ONLY_REGIONS,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  getSystemUsers(data) {
    return apolloProvider.query({
      query: queries.GET_SYSTEM_USERS,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  getMyCompanies() {
    return apolloProvider.query({
      query: queries.GET_MY_COMPANIES,
      fetchPolicy: 'no-cache',
    })
  },
  createTempCompany(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_TEMP_COMPANY,
      variables: {
        ...data,
      },
    })
  },
}
