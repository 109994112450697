import apolloProvider from '@/libs/vue-apollo'
import mutation from './mutation'
import queries from './queries'

export default {

  getJobCategory() {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_JOB_CATEGORY,
    })
  },

  saveJobCategory(input) {
    return apolloProvider.mutate({
      mutation: mutation.SAVE_CATEGORY,
      variables: {
        ...input,
      },
    })
  },
}
