<template>
  <div>
    <b-skeleton-wrapper>
      <div
        v-for="index in count"
        :key="index"
        class="d-flex mt-2"
      >
        <b-skeleton
          class="mx-2"
          type="avatar"
          width="52px"
          height="42px"
        />
        <div class="w-100">
          <b-skeleton width="60%" />
          <b-skeleton width="30%" />
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { BSkeletonWrapper, BSkeleton } from 'bootstrap-vue'

export default {
  components: {
    BSkeletonWrapper,
    BSkeleton,
  },
  props: {
    count: {
      type: Number,
      default: () => 1,
    },
  },
}
</script>
