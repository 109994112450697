import gql from 'graphql-tag'

export default {
  CREATE_TEAM: gql`mutation TeamCreate($projectUid: String!, $name: String!, $description: String) {
        createTeam(
          projectUid: $projectUid,
          input: { name: $name, description: $description}
        ) {
          message
        }
    }`,

  ADD_TEAM_MEMBERS: gql`
    mutation TeamUpdate($userUids: [String!]!, $teamUid: String!,$projectUid: String! ) {
      assignUsersToTeam(
        input: {
          userUids: $userUids
          teamUid: $teamUid
          projectUid: $projectUid
        }
      ) {
        message
      }
    }
  `,

  UPDATE_TEAM_MEMBERS_TIME_LIMIT: gql`
  mutation updateUserProjectTimeLimit($userUid:String!, $projectUid: String!,$hours:Int!, $type:ProjectTimeLimitType!){
    updateUserProjectTimeLimit(
        userUid:$userUid
        projectUid:$projectUid
        input:{
            hours:$hours
            type: $type
        }
    ){
        message
    }
  }
  `,
}
