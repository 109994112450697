import apolloProvider from '@/libs/vue-apollo'
import commonMixin from '@/utils/common'
import mutation from './mutation'
import queries from './queries'

export default {
  storeTeam(data) {
    return apolloProvider.mutate({
      mutation: mutation.CREATE_TEAM,
      variables: {
        ...data,
      },
    })
  },
  getTeam(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_TEAM,
      variables: {
        ...data,
      },
    })
  },

  getTeamDetails(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_TEAM_DETAILS,
      variables: {
        ...data,
      },
    })
  },

  updateTeamMemberLimit(data) {
    return apolloProvider.mutate({
      fetchPolicy: 'no-cache',
      mutation: mutation.UPDATE_TEAM_MEMBERS_TIME_LIMIT,
      variables: {
        ...data,
      },
    })
  },

  addTeamMembers(data) {
    return apolloProvider.mutate({
      fetchPolicy: 'no-cache',
      mutation: mutation.ADD_TEAM_MEMBERS,
      variables: {
        ...data,
      },
    })
  },
  getTeamReport(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_TEAM_REPORT,
      variables: {
        ...data,
        startTime: data.startTime,
        endTime: data.endTime,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  getTeamDropdown(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_TEAM_DROPDOWN,
      variables: {
        ...data,
      },
    })
  },
}
