<template>
  <div>
    <video
      :ref="msgData.messageUid"
      class="border-radius-16 mx-1"
      width="320"
      height="auto"
      controls
    />
  </div>
</template>

<script>
export default {
  props: {
    msgData: {
      type: Object,
      required: true,
    },
    attachment: {
      type: String,
      default: () => '',
    },
  },
  watch: {
    attachment(val) {
      if (val) this.$refs[this.msgData.messageUid].src = val
    },
  },
  mounted() {
    if (this.msgData.attachment) {
      this.$refs[this.msgData.messageUid].src = this.msgData.attachment
    }
  },
}
</script>
