/**
 * zh_CN translate
 * osindex - https://github.com/osindex
 * @type Object
 */
/* eslint camelcase: 0 */
const zh_CN = {
    btn: {
        about: '关于',
        back: '返回',
        cancel: '取消',
        clear: '清除',
        copy: '复制',
        cut: '剪切',
        delete: '删除',
        edit: '编辑',
        forward: '前进',
        folder: '创建目录',
        file: '创建文件',
        fullScreen: '全屏',
        grid: '网格',
        paste: '粘贴',
        refresh: '刷新',
        submit: '提交',
        table: '表格',
        upload: '上传',
        uploadSelect: '选择文件',
        hidden: ' 隐藏文件',
    },
    clipboard: {
        actionType: '类型',
        copy: '复制',
        cut: '剪切',
        none: '未选中文件',
        title: '剪切板',
    },
    contextMenu: {
        copy: '复制',
        cut: '剪切',
        delete: '删除',
        download: '下载',
        info: '选择:',
        open: '打开',
        paste: '粘贴',
        properties: '属性',
        rename: '重命名',
        select: '选择',
        view: '查看',
        zip: '压缩',
        unzip: '解压',
        edit: '编辑',
        audioPlay: '播放',
        videoPlay: '播放',
    },
    info: {
        directories: '目录:',
        files: '文件:',
        selected: '已选择:',
        selectedSize: '已选择文件大小:',
        size: '文件大小:',
    },
    manager: {
        table: {
            date: '日期',
            folder: '目录',
            name: '名称',
            size: '大小',
            type: '类型',
        },
    },
    modal: {
        about: {
            developer: '开发者信息',
            name: '文件管理页',
            title: '关于',
            version: '版本',
        },
        delete: {
            noSelected: '暂无选中!',
            title: '删除',
        },
        newFile: {
            fieldName: '文件名',
            fieldFeedback: '文件已存在!',
            title: '创建文件',
        },
        newFolder: {
            fieldName: '目录名',
            fieldFeedback: '目录已存在!',
            title: '创建目录',
        },
        preview: {
            title: '预览',
        },
        properties: {
            disk: '模块',
            modified: '时间',
            name: '名称',
            path: '路径',
            size: '大小',
            title: '属性',
            type: '类型',
            url: '网址',
            access: '授权',
            access_0: '禁止访问',
            access_1: '只读',
            access_2: '读写',
        },
        rename: {
            directoryExist: '目录存在',
            fieldName: '输入名称',
            fieldFeedback: '名称不可用',
            fileExist: '文件存在',
            title: '重命名',
        },
        status: {
            noErrors: '暂无错误!',
            title: '状态',
        },
        upload: {
            ifExist: '如果文件存在:',
            noSelected: '暂无选中!',
            overwrite: '覆盖',
            selected: '已选择:',
            size: '大小:',
            skip: '忽略',
            title: '上传文件',
        },
        editor: {
            title: '编辑',
        },
        audioPlayer: {
            title: '播放音频',
        },
        videoPlayer: {
            title: '播放视频',
        },
        zip: {
            title: '归档文件',
            fieldName: '归档名称',
            fieldFeedback: '文件存在!',
        },
        unzip: {
            title: '解压',
            fieldName: '目录名称',
            fieldRadioName: '解压到:',
            fieldRadio1: '当前目录',
            fieldRadio2: '输入目录',
            fieldFeedback: '目录存在!',
            warning: '注意！如果文件存在将会被覆盖！',
        },
        cropper: {
            title: '裁剪',
            apply: '应用',
            reset: '重置',
            save: '保存',
        },
    },
    notifications: {
        cutToClipboard: '剪切到粘贴板!',
        copyToClipboard: '复制到粘贴板!',
    },
    // todo - need to translate
    response: {
        noConfig: 'Config not found!',
        notFound: 'Not found!',
        diskNotFound: 'Disk not found!',
        pathNotFound: 'Path not found!',
        diskSelected: 'Disk selected!',
        // files
        fileExist: 'File already exists!',
        fileCreated: 'File created!',
        fileUpdated: 'File updated!',
        fileNotFound: 'File not found!',
        // directories
        dirExist: 'Directory already exists!',
        dirCreated: 'Directory created!',
        dirNotFound: 'Directory not found',
        // actions
        uploaded: 'All files uploaded!',
        notAllUploaded: "Some files weren't uploaded!",
        delNotFound: "Some items weren't founded!",
        deleted: 'Deleted!',
        renamed: 'Renamed!',
        copied: 'Copied successfully!',
        // zip
        zipError: 'Error creating archive!',
        // acl
        aclError: 'Access denied!',
    },
};

export default zh_CN;
