import apolloProvider from '@/libs/vue-apollo'
import mutation from './mutation'
import queries from './queries'

export default {
  checkDomain(data) {
    return apolloProvider.mutate({
      mutation: mutation.CHECK_DOMAIN,
      variables: {
        fqdn: data,
      },
    })
  },

  changePassword(data) {
    return apolloProvider.mutate({
      mutation: mutation.CHANGE_PASSWORD,
      variables: {
        ...data,
      },
    })
  },
  forgotPassword({ email }) {
    return apolloProvider.mutate({
      mutation: mutation.FORGOT_PASSWORD,
      variables: {
        email,
      },
    })
  },
  resetForgottenPassword(data) {
    return apolloProvider.mutate({
      mutation: mutation.FORGOT_PASSWORD,
      variables: {
        data,
      },
    })
  },

  // Attempts to log the user in with supplied credentials
  login(data) {
    return apolloProvider.mutate({
      mutation: mutation.LOGIN,
      variables: {
        ...data,
      },
    })
  },

  // Logs the user out and clears local tokens
  logout() {
    return apolloProvider.mutate({
      mutation: mutation.LOGOUT,
    })
  },

  listUsers(data) {
    return apolloProvider.query({
      query: queries.GET_USERS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },

  register(data) {
    return apolloProvider.mutate({
      mutation: mutation.REGISTER,
      variables: {
        ...data,
      },
    })
  },

  verifyAccount(data) {
    return apolloProvider.mutate({
      mutation: mutation.VERIFY,
      variables: {
        ...data,
      },
    })
  },
  verifyInvitation(data) {
    return apolloProvider.mutate({
      mutation: mutation.VERIFY_INVITE,
      variables: {
        ...data,
      },
    })
  },

  resendVerificationEmail(data) {
    return apolloProvider.mutate({
      mutation: mutation.RESEND_VERIFICATION_EMAIL,
      variables: {
        ...data,
      },
    })
  },

  resetPassword(data) {
    return apolloProvider.mutate({
      mutation: mutation.RESET_PASSWORD,
      variables: {
        ...data,
      },
    })
  },

  socialLogin({ token, provider }) {
    return apolloProvider.mutate({
      mutation: mutation.SOCIAL_LOGIN,
      variables: {
        token,
        provider,
      },
    })
  },

  verifyInvoiceLogin(data) {
    return apolloProvider.mutate({
      mutation: mutation.VERIFY_INVOICE_LOGIN,
      variables: {
        ...data,
      },
    })
  },

  updateUserSettingInformation(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_SETTING_INFORMATION,
      variables: {
        ...data,
      },
    })
  },
}
