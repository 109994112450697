import gql from 'graphql-tag'

export default {
  SAVE_CATEGORY: gql`
    mutation Register(
      $category: JobCategoryInput
      $subCategory: JobSubCategoryInput
    ) {
      registerJobCategory(category: $category, subCategory: $subCategory) {
        status
        message
      }
    }
  `,
}
