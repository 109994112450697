import gql from 'graphql-tag'

export default {
  LOGIN: gql`
    mutation login($username: String!, $password: String!) {
      login(input: { username: $username, password: $password }) {
        access_token
        refresh_token
        expires_in
        user {
          id
          name
          email
          uuid
          isAdmin
          avatar
          companyRoles {
            data {
              role {
                name
              }
            }
          }
          extraInformations(type:SETTINGINFORMATION){
            data{
                information{
                    ...on UserSettingInformation{
                        tourComplete
                    }
                }
            }
          }
        }
      }
    }
  `,
  LOGOUT: gql`
    mutation logout {
      logout {
        status
        message
      }
    }
  `,
  FORGOT_PASSWORD: gql`
    mutation Forgot($email: String!) {
      forgotPassword(input:{email:  $email}){
        status
      }
    }
  `,
  // UPDATE_PASSWORD: gql`
  //   mutation updatePassword($data: NewPasswordWithCodeInput!) {
  //     updateForgottenPassword(data: $data) {
  //       email
  //       token
  //       password
  //       password_confirmation
  //     }
  //   }
  // `,
  CHECK_DOMAIN: gql`
    mutation checkDomain($fqdn: String!) {
      checkDomain(fqdn: $fqdn) {
        valid
        message
      }
    }
  `,
  // REGISTER: gql`mutation register(
  //     $name: String!
  //     $password: String!
  //     $password_confirmation: String!
  //     $email: String!
  //     $company: OwnerCompany
  //   ) {
  //     createUser(
  //       input: {
  //         name: $name
  //         email: $email
  //         password: $password
  //         password_confirmation: $password_confirmation
  //         company: $company
  //       }
  //     ) {
  //       id
  //       email
  //     }
  //   }`,

  REGISTER: gql`
    mutation register(
      $name: String!
      $password: String!
      $password_confirmation: String!
      $email: String!
    ) {
      register(
        input: {
          email: $email
          password: $password
          password_confirmation: $password_confirmation
          name: $name
        }
      ) {
        status
        tokens {
          user {
            uuid
            email
            companyRoles {
              data {
                role {
                  name
                }
              }
            }
          }
          access_token
        }
      }
    }
  `,

  VERIFY: gql`
    mutation verifyEmail(
      $name: String!
      $password: String!
      $password_confirmation: String!
      $token: String!
    ) {
      verifyEmail(
        input: {
          token: $token
          password: $password
          password_confirmation: $password_confirmation
          name: $name
        }
      ) {
        access_token
        refresh_token
        user{
          name
          uuid
          email
          companyRoles {
            data {
              role {
                name
              }
            }
          }
        }
      }
    }
  `,

  VERIFY_INVITE: gql`
    mutation verifyInvitation(
      $email: String!
      $token: String!
    ) {
      verifyInvitation(
        input: {
          token: $token
          email: $email
        }
      ) {
        access_token
        refresh_token
        user{
          name
          uuid
          email
          companyRoles {
            data {
              role {
                name
              }
            }
          }
        }
      }
    }
`,
  RESEND_VERIFICATION_EMAIL: gql`
    mutation resendVerificationEmail($email: String!) {
      resendVerificationEmail(
        email: $email
      ) {
        message
        status
      }
    }
  `,

  CHANGE_PASSWORD: gql`
    mutation ChangePassword ($old_password: String!, $password: String!, $password_confirmation: String!) {
      updatePassword(input:{ old_password:$old_password, password:$password, password_confirmation: $password_confirmation }){
        status
        message
      }
    }`,

  RESET_PASSWORD: gql`
    mutation resetPassword($email: String!, $password: String!, $password_confirmation: String!,$token: String!) {
      updateForgottenPassword(input:{
        email:$email,
        password:$password,
        password_confirmation:$password_confirmation,
        token:$token
      }) {
        status
        message
      }
    }
  `,

  SOCIAL_LOGIN: gql`
    mutation socialLogin($provider: String!, $token: String!) {
      socialLogin(input: { provider: $provider, token: $token  }) {
        access_token
        refresh_token
        expires_in
        user {
          id
          name
          uuid
        }
      }
    }
  `,

  VERIFY_INVOICE_LOGIN: gql`
  mutation verifyInvoiceLogin($input: VerifyInvoiceInput!) {
    verifyInvoiceViaOTP(input: $input) {
      access_token
      refresh_token
      expires_in
      user {
        id
        name
        uuid
      }
    }
  }
  `,

  UPDATE_USER_SETTING_INFORMATION: gql`
mutation updateUserSettingInformation($userUid:String!,$input:UserSettingInput!){
    updateUserSettingInformation(userUid:$userUid, input:$input){
        message
        status
    }
}
`,
}
