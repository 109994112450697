import gql from 'graphql-tag'

export default {
  GET_TEAM: gql`
    query getTeams($uuid: String, $first: Int, $page: Int) {
      project(uuid: $uuid) {
        uuid
        teams(page: $page, first: $first) {
          data {
            uuid
            name
            description
            users {
              data {
                uuid
                name
              }
              total
            }
            project {
              uuid
              name
            }
          }
        }
      }
    }
  `,

  GET_TEAM_DROPDOWN: gql`
    query getTeams($uuid: String, $first: Int, $page: Int) {
      project(uuid: $uuid) {
        uuid
        teams(page: $page, first: $first) {
          data {
            uuid
            name
          }
        }
      }
    }
  `,

  GET_TEAM_DETAILS: gql`
   query getTeams($projectUid:String!,$teamUid: String!) {
      project(uuid: $projectUid) {       
        teams(team:{keyword:[$teamUid],searchKey:UUID}){
          data {
            uuid
            name
            users{
                data{
                    name
                    uuid
                    isOnline
                    projectSettings(
                        type:PROJECTTIMELIMIT
                        project:{keyword:[$projectUid],searchKey:UUID}
                        first:1)
                        {
                            total
                            data{
                                information{
                                    ...on ProjectTimeLimit{
                                        hours
                                        type
                                    }
                                }
                            }
                    }
                }
            }
          }
        }
      }
    }
  `,

  GET_TEAM_REPORT: gql`query getTeamReport(
            $uuid: String!
            $startTime: DateTime!
            $endTime: DateTime!
            $keyword: [String!]
            $timezone: String,
        ) {
            project(uuid: $uuid) {
            teams(team: {searchKey: UUID, keyword: $keyword}) {
                data {
                uuid
                name
                recordsDuration: activityDuration(
                    between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
                    project: { keyword: [$uuid], searchKey: UUID }
                )
                productiveDuration: recordsDuration(
                    productivity: PRODUCTIVE
                    between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
                    project: { keyword: [$uuid], searchKey: UUID }

                )
                unproductiveDuration: recordsDuration(
                    productivity: UNPRODUCTIVE
                    between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
                    project: { keyword: [$uuid], searchKey: UUID }
                )
                neutralDuration: recordsDuration(
                  productivity: NEUTRAL
                  between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
                  project: { keyword: [$uuid], searchKey: UUID }
                )
                users {
                    total
                    data {
                    name
                    recordsDuration: activityDuration(
                      between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
                      project: { keyword: [$uuid], searchKey: UUID }
                    )
                    productiveDuration: recordsDuration(
                        productivity: PRODUCTIVE
                        between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
                        project: { keyword: [$uuid], searchKey: UUID }
                    )
                    unproductiveDuration: recordsDuration(
                        productivity: UNPRODUCTIVE
                        between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
                        project: { keyword: [$uuid], searchKey: UUID }
                    )
                    idlePercent(
                      between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
                      project: { keyword: [$uuid], searchKey: UUID }
                    )
                    }
                }
                }
            }
            }
        }`,
}
