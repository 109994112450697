import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vue from 'vue'

export default {
  showSuccessMessage(success) {
    vue.$toast({
      component: ToastificationContent,
      props: {
        title: 'Success',
        icon: 'CoffeeIcon',
        variant: 'success',
        text: `${success.data.message}`,
      },
    }, {
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
    })
  },
  showSuccess(message) {
    vue.$toast({
      component: ToastificationContent,
      props: {
        title: 'Success',
        icon: 'CoffeeIcon',
        variant: 'success',
        text: message,
      },
    }, {
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
    })
  },
  showAppUpdatedToast() {
    vue.$toast({
      component: ToastificationContent,
      props: {
        title: 'GEC has been upgraded.',
        icon: 'ChevronsUpIcon',
        variant: 'success',
        text: 'Refresh to get the latest features and bug fixes.',
        actionTitle: 'Refresh Page',
        actionLink: window.location.href,
      },
    }, {
      position: 'top-right',
      timeout: false,
    })
  },
  showErrorMessage(error) {
    vue.$toast({
      component: ToastificationContent,
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
      props: {
        title: 'Error',
        icon: 'InfoIcon',
        variant: 'danger',
        text: `${error.response?.data?.message ? error.response.data.message : error}`,
      },
    }, {
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
    })
  },
  showError(error) {
    vue.$toast({
      component: ToastificationContent,
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
      props: {
        title: 'Error',
        icon: 'InfoIcon',
        variant: 'danger',
        text: error,
      },
    },
    {
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
    })
  },
  showMessage(message) {
    vue.$toast({
      component: ToastificationContent,
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
      props: {
        title: 'Success',
        icon: 'InfoIcon',
        variant: 'success',
        text: message,
      },
    }, {
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
    })
  },
  showInfoMessage(data) {
    vue.$toast({
      component: ToastificationContent,
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
      props: {
        title: data.title,
        icon: 'InfoIcon',
        variant: 'info',
        text: data.message,
      },
    }, {
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
    })
  },
  showDangerMessage(data) {
    vue.$toast({
      component: ToastificationContent,
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
      props: {
        title: data.title,
        icon: 'InfoIcon',
        variant: 'danger',
        text: data.message,
      },
    }, {
      position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
    })
  },
}
