import useApollo from '@/plugins/graphql/useApollo'
import moment from 'moment'
import EventBus from '@/event-bus'

export default {
  namespaced: true,
  state: {
    notifications: [],
    unReadNotifications: 0,
    enabledPushNotification: null,
    blockedPushNotification: null,
  },
  mutations: {
    SET_NOTIFICATION(state, payload) {
      state.notifications = payload
    },
    SET_UNREAD_NOTIFICATIONS(state, paylod) {
      state.unReadNotifications = paylod
    },
  },
  actions: {
    async fetchNotifications({ commit, state }, { projectUid, page = 1 }) {
      return new Promise(resolve => {
        useApollo.users.getUserNotifications({ projectUid, page }).then(res => {
          const { notifications } = res.data.me
          commit('SET_UNREAD_NOTIFICATIONS', res.data.me.unRead.total)

          // eslint-disable-next-line no-underscore-dangle
          const walletShareApprovedNotifications = notifications.data.filter(notification => moment(notification.createdAt).isAfter(moment().utc().subtract(2, 'minutes').format('YYYY-MM-DD h:mm:ss')) && !notification.isRead && notification.notification.__typename === 'NotificationWalletShareInvite' && notification.notification.walletShareInviteAction === 'APPROVED')
          if (walletShareApprovedNotifications.length) EventBus.$emit('fetch-wallets')

          //   notificationsData.forEach(notification => {
          //   // eslint-disable-next-line import/no-dynamic-require, global-require, no-underscore-dangle
          //     // const notificationFile = require(`@/utils/notification/${notification.notification.__typename}`)
          //     // notifications.push(notificationFile.default.handle(notification))

          //   })
          const oldData = state.notifications?.data || []
          if (page > 1) {
            notifications.data = [...oldData, ...notifications.data]
          }

          commit('SET_NOTIFICATION', notifications)
        }).finally(() => {
          resolve(true)
        })
      })
    },
  },
}
