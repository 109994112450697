export const BankWalletFragment = `
  fragment BankWalletFragment on BankWallet {
    id
    information {
      ... on AchBillingInformation {
        alias
        bankAccount
      }
      ... on FxBillingInformation {
        alias
        bankAccount
      }
      ... on BillingInformation {
        alias
        bankAccount
      }
    }
  }
`

export const BankTransferSuccessFragment = `
  fragment BankTransferSuccessFragment on BankTransferSuccess {
    id
    amount
    description
    source {
      description
    }
  }
`

export const WalletToWalletTransferFragment = `
  fragment WalletToWalletTransferFragment on WalletToWalletTransfer {
    document
    source {
      description
      # owner {
      #   ... on Company {
      #     name
      #   }
      # }
    }
    destination {
      ... on User {
        uuid
        name
      }
      ... on Company {
        uuid
        name
      }
    }
  }
`

export const ReconciliationTransactionFragment = `
  fragment ReconciliationTransactionFragment on ReconciliationTransaction {
    id
    amount
    description
    share {
      description
    }
    document
  }
`

export const UserVendorFragment = `
  fragment UserVendorFragment on
  User {
    uuid
    name
  }
`

export const CompanyVendorFragment = `
  fragment CompanyVendorFragment on
  Company {
    uuid
    name
  }
`

export const VendorFragment = `
  fragment VendorFragment on
  Vendor {
    uuid
    name
  }
`

export const BillingInformationFragment = `
  fragment BillingInformationFragment on
  BillingInformation {
    id
    alias
    bankAccount
    bankName
    accountHolder
  }
`

export const AchBillingInformationFragment = `
  fragment AchBillingInformationFragment on
  AchBillingInformation {
    id
    alias
    bankAccount
    bankName
    accountHolder
  }
`

export const FxBillingInformationFragment = `
  fragment FxBillingInformationFragment on
  FxBillingInformation {
    id
    alias
    bankAccount
    bankName
    accountHolder
  }
`

export const FullBillingInformationFragment = `
  fragment FullBillingInformationFragment on
  BillingInformation {
    id
    alias
    bankAccount
    bankName
    accountHolder
    bankAddress
    bankSwiftCode
    bankIbanCode
    country {
      name
      code
    }
  }
`

export const FullAchBillingInformationFragment = `
  fragment FullAchBillingInformationFragment on
  AchBillingInformation {
    id
    alias
    bankAccount
    bankName
    accountHolder
    bankAddress
    routingNumber
    country {
      name
      code
    }
  }
`

export const FullFxBillingInformationFragment = `
  fragment FullFxBillingInformationFragment on
  FxBillingInformation {
    id
    alias
    bankAccount
    bankName
    accountHolder
    bankAddress
    bankSwiftCode
    country {
      name
      code
    }
  }
`

export const applicationFragment = `
  fragment applicationFragment on ApplicationBuild
  {
    version
    platform
    filename
    description
    createdAt
    architecture
  }
`
