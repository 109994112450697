<template>
  <div
    class="w-100 chat-tick text-secondary"
    :class="{ 'margin-left-18': msgData.messageType !== 'MEDIACALL' }"
  >
    <div class="w-100 d-flex indicators">
      <p
        v-if="msgData.messageType !== 'GROUPACTION'"
        class="small font-weight-bold mb-0 text-center"
        :class="{
          'no-opacity': hoveredMsg !== msgData.messageUid,
          'w-100': msgData.messageType === 'MEDIACALL'
        }"
      >
        {{ showTimeForChat(msgData.time) }}
      </p>
      <div v-if="senderId === self.uuid && msgData.messageType!=='MEDIACALL'">
        <div
          v-if="msgData.seen"
          v-b-tooltip.hover.left="`Seen by ${getMsgSeenByString(msgData.seenBy)}`"
          class="double-check text-info"
        >
          <i class="fa fa-check" />
          <i class="fa fa-check position-relative second-check" />
        </div>
        <div
          v-else-if="msgData.delivered && msgData.messageType"
          v-b-tooltip.hover.left="showTimeForChat(msgData.deliveredAt)"
          class="double-check"
        >
          <i class="fa fa-check" />
          <i class="fa fa-check position-relative second-check" />
        </div>
        <i
          v-else
          class="fa fa-check single-check"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    self: {
      type: Object,
      required: true,
    },
    msgData: {
      type: Object,
      required: true,
    },
    senderId: {
      type: String,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
    hoveredMsg: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    getMsgSeenByString(users) {
      if (users.length <= 2) return users.join(', ')
      const remainingUsers = users.length - 2
      return `${users.slice(0, 2).join(', ')} and ${remainingUsers} ${remainingUsers === 1 ? 'other' : 'others'}`
    },
  },
}
</script>
