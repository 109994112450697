import gql from 'graphql-tag'

export default {

  CREATE_COMPANY: gql`mutation createCompany(
        $name: String!
        $address: String!
        $email: String!
        $trackingType: TrackingType!
        $timezoneOffset: String
        $weekStartDay: StartDayOfWeek!
        $ownerEmail: String!
        $timezones: [String!]
      ) {
        createCompany(
          input: {
            name: $name
            address: $address
            email: $email
            trackingType: $trackingType
            timezoneOffset: $timezoneOffset
            weekStartDay: $weekStartDay
            timezones: $timezones
          }
          ownerEmail: $ownerEmail
        ) {
          data {
            uuid
          }
        }
    }`,
  DELETE_COMPANY: gql`mutation deletecompany($id: ID!) {
        deletecompany(id: $id) {
          id
          name
          address
        }
      }`,
  UPDATE_COMPANY: gql`mutation updateCompany(
    $companyUid: String!
    $name: String!
    $address: String!
    $email: String!
    $trackingType: TrackingType!
    $timezoneOffset: String
    $weekStartDay: StartDayOfWeek!
    $timezones: [String!]
  ) {
    updateCompany(
      companyUid: $companyUid,
      input: {
        name: $name
        address: $address
        email: $email
        trackingType: $trackingType
        timezoneOffset: $timezoneOffset
        weekStartDay: $weekStartDay
        timezones: $timezones
      }
    ) {
      message
    }
  }`,

  UPDATE_REPORT_TIMEZONE: gql`mutation updateReportTimezone($companyUid: String!, $timezones: [String!]!) {
    updateCompanyTimezones(
      input: {
        companyUid: $companyUid,
        timezones: $timezones
      }
      
    ) {
      message
    }
  }`,

  UPLOAD_APPLICATION: gql`mutation uploadBuild($input: ApplicationBuildInput!, $file: Upload!) {
    uploadBuild(
      input: $input,
      file: $file,
    ) {
      __typename
    }

  }`,
  UPDATE_AIRWALLEX_BILLING_INFORMATION: gql`
  mutation updateAirwallexBillingInformation(
    $companyUid: String!
    $alias: String!
    $input: AirwallexBillingInformationInput!
  ) {
    updateAirwallexBillingInformation(companyUid: $companyUid, alias: $alias, input: $input) {
      status
      message
    }
  }
`,
  UPDATE_STRIPE_BILLING_INFORMATION: gql`
  mutation updateStripeBillingInformation(
    $companyUid: String!
    $alias: String!
    $input: StripeBillingInformationInput!
  ) {
    updateStripeBillingInformation(companyUid: $companyUid, alias: $alias, input: $input) {
      status
      message
    }
  }
`,
  UPDATE_DEFAULT_BILLING_INFO: gql`
    mutation updateDefaultBillingInfo(
      $companyUid: String!, 
      $input: DefaultBillingInformationInput!
    ) {
      updateDefaultBillingInformation(
      companyUid: $companyUid, 
      input: $input
    ) {
      status
      message
    } 
  }
`,
  CREATE_COMPANY_LEAD: gql`
      mutation createCompanyLead(
        $projectUid: String!,
        $input: CompanyLeadUpdateInput!,
        $contacts: [CompanyLeadContactInput!]!
      ) {
        createCompanyLead(
          projectUid: $projectUid,
          input: $input,
          contacts: $contacts
        ) {
          status
          message
        }
      }
  `,
  UPDATE_COMPANY_LEAD: gql`
  mutation updateCompanyLead(
    $projectUid: String!,
    $leadUid: String!,
    $input: CompanyLeadUpdateInput!,
    $contacts: [CompanyLeadContactInput!]!
  ) {
    updateCompanyLead(
      projectUid: $projectUid,
      leadUid: $leadUid,
      input: $input,
      contacts: $contacts
    ) {
      status
      message
    }
  }
`,
  UPDATE_COMPANY_LEAD_STATUS: gql`
    mutation updateCompanyLeadStatus(
      $projectUid: String!,
      $leadUid: String!,
      $status: CompanyLeadStatus!,
      $description:String!,
      $priority: CompanyLeadPriority,
    ) {
      updateCompanyLeadStatus(
        projectUid: $projectUid,
        leadUid: $leadUid,
        status: $status,
        description: $description,
        priority: $priority
      ) {
        status
        message
      }
    }
`,

  SEND_EMAIL_FOR_LEAD: gql`
mutation($input:LeadEmailInput!,$projectUid:String!,$leadUid:String!,$contact:ID){
  sendEmailForLead(
    input:$input
    projectUid: $projectUid
    leadUid: $leadUid
    contact: $contact
  ) {
    status
    message
  }
}
`,

  FINALIZE_LEADS: gql`
mutation finalizeLead($ownerEmail:String!, $projectUid:String!, $leadUid: String!, $input:LeadFinalizeInput!) {
  finalizeLead(
    ownerEmail:$ownerEmail
    projectUid:$projectUid
    leadUid:$leadUid
    input:$input
  ) {
    status
    message
  }
}
`,

  IMPORT_EXCEL: gql`
    mutation createLeadsFromFile(
    $projectUid: String!
    $file: Upload!
    ){
    createLeadsFromFile(
        projectUid: $projectUid,
        file: $file,
    ) {
        status
        message
    }
    }
`,
  CONFIG_COMPANY_WALLET: gql`
    mutation configCompanyWallet(
      $companyUid: String!,
      $description: String!,
      $input: [WalletShareInput!]!,
      $nonDefaultWallet: Boolean,
      $familyType: WalletFamilyType
    ) {
      configCompanyWallet(
        companyUid: $companyUid,
        description: $description,
        nonDefaultWallet: $nonDefaultWallet,
        input: $input,
        familyType: $familyType
      ) {
        status
        message
      }
    }
  `,

  MAKE_COMPANY_WALLET_DEPOSIT: gql`
    mutation makeCompanyWalletDeposit(
      $companyUid: String!,
      $input: WalletDepositInput!,
    ) {
      makeCompanyWalletDeposit(
        companyUid: $companyUid
        input: $input
      ) {
        status 
        message
      }
    }
  `,

  SPLIT_COMPANY_WALLET: gql`
  mutation splitCompanyWallet(
    $companyUid: String!
    $wallet: WalletIdentifierInput!
    $input: [WalletShareInput!]!
  ) {
    splitCompanyWallet(
      companyUid: $companyUid,
      wallet: $wallet,
      input: $input
    ) {
      status
      message
    }
  }
  `,

  MERGE_COMPANY_WALLETS: gql`
  mutation mergeCompanyWallets(
    $companyUid: String!,
    $destination: WalletIdentifierInput!,
    $shareUids: [String!]!,
  ) {
    mergeCompanyWallets(
      companyUid: $companyUid,
      destination: $destination,
      shareUids: $shareUids
    ) {
      status
      message
    }
  }
  `,

  UPDATE_COMPANY_WALLET: gql`
  mutation updateCompanyWalletShareDetails(
    $companyUid: String!,
    $wallet: WalletIdentifierInput!,
    $description: String!,
  ) {
    updateCompanyWalletShareDetails(
      companyUid: $companyUid,
      wallet: $wallet,
      description: $description
    ) {
      status
      message
    }
  }
  `,
  SAVE_BILLING_SETTINGS: gql`
  mutation saveBillingSettings($companyUid: String!,$input: BillingInformationInput!) {
    updateCompanyBillingInformation(companyUid: $companyUid,input: $input ) {
      message,
      status
    }
  }
`,
  SAVE_ACH_BILLING_SETTINGS: gql`
  mutation saveAchBillingSettings($companyUid: String!,$input: AchBillingInformationInput!) {
    updateCompanyAchBillingInformation(companyUid: $companyUid,input: $input ) {
      message,
      status
    }
  }
  `,

  SAVE_FX_BILLING_SETTINGS: gql`
  mutation saveFxBillingSettings($companyUid: String!,$input: FxBillingInformationInput!) {
    updateCompanyFxBillingInformation(companyUid: $companyUid,input: $input ) {
      message,
      status
    }
  }
`,
  ATTACH_BANK_TO_INTERNAL_WALLET: gql`
    mutation attachBankingInformationToCompanyInternalWallet(
      $companyUid: String!,
      $wallet: WalletIdentifierInput!,
      $input: AttachBankInformationType!
    ) {
      attachBankingInformationToCompanyInternalWallet(
        companyUid: $companyUid,
        wallet: $wallet,
        input: $input,
      ) {
        status
        message
      }
    }
  `,
  WITHDRAW_FROM_COMPANY_BANK_WALLET: gql`
  mutation withdrawFromCompanyBankWallet(
    $companyUid: String!,
    $wallet: WalletIdentifierInput!,
    $input: BankWalletWithdrawInput!
  ) {
    withdrawFromCompanyBankWallet(
      companyUid: $companyUid
      wallet: $wallet
      input: $input
    ) {
      status
      message
    }
  }
`,
  COMPANY_INTERNAL_WALLET_TRANSFER: gql`
    mutation makeCompanyInternalWalletTransfer(
      $companyUid: String!,
      $source: WalletIdentifierInput!,
      $destination: WalletIdentifierInput!,
      $input: WalletTransferInput!
      $documentData: String
    ) {
      makeCompanyInternalWalletTransfer(
        companyUid: $companyUid,
        source: $source,
        destination: $destination,
        input: $input
        documentData: $documentData
      ) {
        status
        message
      }
    }
  `,
  UPDATE_BILLING_CHARGES: gql`
    mutation updateBillingCharges(
      $input: CompanyChargingInformationInput!
    ) {
      updateCompanyBillingCharges(
        input: $input
      ) {
        status
        message
      }
    }
  `,
  STRIPE_DEPOSIT_COMPANY_WALLET: gql`
    mutation stripeDepositCompanyWallet(
      $companyUid: String!,
      $companyProduct: CompanyProductType,
      $input: WalletDepositInput!
    ) {
      makeCompanyWalletDepositByStripe(
        companyUid: $companyUid,
        companyProduct: $companyProduct,
        input: $input
      ) {
        status
        message
        checkoutLink
      }
    }
  `,
  VERIFY_STRIPE_COMPANY_WALLET_DEPOSIT: gql`
    mutation verifyCompanyWalletDepositByStripe(
      $companyUid: String!,
      $companyProduct: CompanyProductType,
      $input: StripeWalletDepositVerificationInput!
    ) {
      verifyCompanyWalletDepositByStripe(
        companyUid: $companyUid,
        companyProduct: $companyProduct,
        input: $input
      ) {
        status
        message
      }
    }
  `,
  MAKE_COMPANY_ALT_FAMILY_TRANSFER: gql`
  mutation makeCompanyAltFamilyTransfer(
    $companyUid: String!
    $source: WalletIdentifierInput!
    $destinationWalletUid: String!
    $input: WalletTransferInput!
    $documentData: String
  ) {
    makeCompanyAltFamilyTransfer(
      companyUid: $companyUid,
      source: $source,
      destinationWalletUid: $destinationWalletUid,
      input: $input
      documentData: $documentData
    ) {
      status
      message
    }
  }
`,
  UPDATE_COMPANY_FAMILY_DETAIL: gql`
    mutation updateCompanyWalletDetails(
      $walletUid: String!
      $companyUid: String!
      $description: String!
    ) {
      updateCompanyWalletDetails(
        walletUid: $walletUid
        companyUid: $companyUid
        description: $description
      ) {
      status
      message
      }
    }
  `,
  COMPANY_WALLET_SHARE_INVITE: gql`
    mutation inviteForCompanyWalletShare(
      $companyUid: String!,
      $wallet: WalletIdentifierInput!,
      $input: ShareWalletInput!
    ) {
      inviteForCompanyWalletShare(
        companyUid: $companyUid,
        wallet: $wallet,
        input: $input
      ) {
        status
        message
      }
    }
  `,
  REVOKE_COMPANY_WALLET_SHARE_INVITE: gql`
    mutation revokeCompanyWalletShareInvite(
      $companyUid: String!,
      $shareUid: String!,
      $checksum: String!
    ) {
      revokeCompanyWalletShareInvite(
        companyUid: $companyUid,
        shareUid: $shareUid,
        checksum: $checksum
      ) {
        status
        message
      }
    }
  `,
  REVOKE_COMPANY_WALLET_SHARE: gql`
  mutation revokeCompanyWalletShare(
    $companyUid: String!,
    $wallet: WalletIdentifierInput!,
    $remarks: String,
  ) {
    revokeCompanyWalletShare(
      companyUid: $companyUid,
      wallet: $wallet,
      remarks: $remarks,
    ) {
      status
      message
    }
  }
`,
  CREATE_COMPANY_WALLET_TRANSFER_SCHEDULE: gql`
  mutation createCompanyWalletTransferSchedule(
    $companyUid: String
    $source: WalletIdentifierInput!
    $destination: WalletShareIdentifierInput!
    $schedule: PaymentSettingInput!
    $input: WalletTransferSettingInput!
    $remarks: RemarkInput!
  ) {
    createWalletTransferSchedule(
      companyUid: $companyUid
      source: $source
      destination: $destination
      schedule: $schedule
      input: $input
      remarks: $remarks
    ) {
      status
      message
    }
  }
`,
  UPDATE_COMPANY_WALLET_TRANSFER_SCHEDULE: gql`
    mutation updateCompanyWalletTransferSchedule(
      $companyUid: String
      $scheduleUid: String!
      $destination: WalletShareIdentifierInput
      $schedule: PaymentSettingInput
      $input: WalletTransferSettingInput
      $remarks: RemarkInput
    ) {
      updateWalletTransferSchedule(
        companyUid: $companyUid
        scheduleUid: $scheduleUid
        destination: $destination
        schedule: $schedule
        input: $input
        remarks: $remarks
      ) {
        status
        message
      }
    }
  `,
  ACTIVATE_COMPANY_WALLET_TRANSFER_SCHEDULE: gql`
    mutation activateCompanyWalletTransferSchedule(
      $companyUid: String,
      $scheduleUid: String!,
      $activate: Boolean!
    ) {
      activateWalletTransferSchedule(
        companyUid: $companyUid,
        scheduleUid: $scheduleUid,
        activate: $activate
      ) {
        status
        message
      }
    }
  `,
  MAKE_COMPANY_RECONCILIATION_TRANSACTION: gql`
  mutation makeCompanyReconciliationTransaction(
    $companyUid: String!,
    $source: WalletIdentifierInput!,
    $input: WalletTransferInput!,
    $documentData: String,
    $transactionType: WalletAction!,
    $vendor: TransactionVendorInput,
  ) {
    makeCompanyReconciliationTransaction(
      companyUid: $companyUid,
      source: $source,
      input: $input,
      documentData: $documentData,
      transactionType: $transactionType,
      vendor: $vendor,
    ) {
      status
      message
    }
  }
`,
  DEACTIVATE_OPEN_WALLET: gql`
    mutation deactivateCompanyWalletShare(
      $companyUid: String!
      $wallet: WalletIdentifierInput!
    ) {
      deactivateCompanyWalletShare(
        companyUid: $companyUid
        wallet: $wallet
      ) {
        status
        message
      }
    }
  `,
  ADD_NEW_OPEN_WALLET: gql`
    mutation addNewCompanyWalletShares(
      $companyUid: String!
      $walletUid: String!
      $input: [WalletShareInput!]!
    ) {
      addNewCompanyWalletShares(
        companyUid: $companyUid
        walletUid: $walletUid
        input: $input
      ) {
        status
        message
      }
    }
  `,
  UPDATE_COMPANY_RECONCILIATION_TRANSACTION: gql`
  mutation updateCompanyReconciliationTransaction(
    $companyUid: String!
    $transactionUid: String!
    $input: WalletTransferInput
    $transactionType: WalletAction,
    $documentData: String,
    $action: UpdateActionType!,
    $vendor: TransactionVendorInput,
  ) {
    updateCompanyReconciliationTransaction(
      companyUid: $companyUid
      transactionUid: $transactionUid
      input: $input
      transactionType: $transactionType
      documentData: $documentData
      action: $action
      vendor: $vendor
    ) {
      status
      message
    }
  }
`,
  EDIT_COMPANY_TRANSACTION_ALLOCATION: gql`
  mutation editCompanyTransactionAllocation(
    $companyUid: String!
    $transactionUid: String!
    $allocation: WalletIdentifierInput!
  ) {
    editCompanyTransactionAllocation(
      companyUid: $companyUid,
      transactionUid: $transactionUid,
      allocation: $allocation,
    ) {
      status
      message
    }
  }
`,
  UPDATE_COMPANY_WALLET_ORDERS: gql`
  mutation updateCompanyWalletOrders(
    $companyUid: String!,
    $orderedWalletUids: [String!]!
  ) {
    updateCompanyWalletOrders(
      companyUid: $companyUid,
      orderedWalletUids: $orderedWalletUids,
    ) {
      status
      message
    }
  }
  `,
  UPDATE_COMPANY_WALLET_SHARE_ORDERS: gql`
  mutation updateCompanyWalletShareOrders(
    $companyUid: String!,
    $walletUid: String!,
    $orderedShareUids: [String!]!
  ) {
    updateCompanyWalletShareOrders(
      companyUid: $companyUid,
      walletUid: $walletUid,
      orderedShareUids: $orderedShareUids,
    ) {
      status
      message
    }
  }
  `,
  DELETE_COMPANY_TRANSFER_TRANSACTION: gql`
    mutation deleteCompanyTransferTransaction(
      $companyUid: String!,
      $transactionUid: String!,
      $reason: String!
    ) {
      deleteCompanyTransferTransaction(
        companyUid: $companyUid,
        transactionUid: $transactionUid,
        reason: $reason
      ) {
        status
        message
      }
    }
  `,
  CREATE_COMPANY_VENDOR: gql`
    mutation createNewCompanyVendor(
      $companyUid: String!,
      $name: String!
    ) {
      createNewCompanyVendor(
        companyUid: $companyUid,
        name: $name,
      ) {
        status
        message
        data {
          uuid
        }
      }
    }
  `,
  UPDATE_PROJECT_CLOUD_SETTINGS: gql`
    mutation updateProjectCloudSettings(
      $companyUid: String!,
      $projectUid: String!,
      $input: ProjectCloudSettingsInput!
    ) {
      updateProjectCloudSettings(
        companyUid: $companyUid,
        projectUid: $projectUid,
        input: $input
      ) {
        status
        message
      }
    }
  `,
  CANCEL_USER_PAYMENT: gql`
    mutation cancelUserPayment(
      $userUid: String!,
      $projectUid: String!,
      $invoiceId: Int!,
      $reason: String
    ) {
      cancelUserPayment(
        userUid: $userUid,
        projectUid: $projectUid,
        invoiceId: $invoiceId,
        reason: $reason
      ) {
        status
        message
      }
    }
  `,
}
