/**
 * Polish
 * @type Object
 */
const pl = {
    btn: {
        about: 'O Programie',
        back: 'Wstecz',
        cancel: 'Anuluj',
        clear: 'Wyczyść',
        copy: 'Kopiuj',
        cut: 'Wytnij',
        delete: 'Usuń',
        edit: 'Edycja',
        forward: 'Do przodu',
        folder: 'Nowy folder',
        file: 'Nowy plik',
        fullScreen: 'Pełny ekran',
        grid: 'Siatka',
        paste: 'Wklej',
        refresh: 'Odśwież',
        submit: 'Zatwierdź',
        table: 'Tabela',
        upload: 'Wyślij plik',
        uploadSelect: 'Wybierz pliki',
        hidden: 'Ukryte pliki',
    },
    clipboard: {
        actionType: 'Rodzaj',
        copy: 'Kopiuj',
        cut: 'Przytnij',
        none: 'Nic nie zostało zaznaczone',
        title: 'Schowek',
    },
    contextMenu: {
        copy: 'Kopiuj',
        cut: 'Przytnij',
        delete: 'Usuń',
        download: 'Pobierz',
        info: 'Wybrano:',
        open: 'Otwórz',
        paste: 'Wklej',
        properties: 'Właściwości',
        rename: 'Zmień nazwę',
        select: 'Wybierz',
        view: 'Widok',
        zip: 'Spakuj',
        unzip: 'Rozpakuj',
        edit: 'Edycja',
        audioPlay: 'Odtwórz',
        videoPlay: 'Odtwórz',
    },
    info: {
        directories: 'Katalogi',
        files: 'Pliki:',
        selected: 'Wybrany:',
        selectedSize: 'Rozmiar plików:',
        size: 'Rozmiar plików:',
    },
    manager: {
        table: {
            date: 'Data',
            folder: 'Folder',
            name: 'Nazwa',
            size: 'Rozmiar',
            type: 'Rodzaj',
        },
    },
    modal: {
        about: {
            developer: 'Deweloper',
            name: 'Menedżer plików',
            title: 'O',
            version: 'Wersja',
        },
        delete: {
            noSelected: 'Nic nie zostało zaznaczone!',
            title: 'Usuń',
        },
        newFile: {
            fieldName: 'Nazwa pliku',
            fieldFeedback: 'Plik istnieje!',
            title: 'Utwórz nowy plik',
        },
        newFolder: {
            fieldName: 'Nazwa folderu',
            fieldFeedback: 'Katalog istnieje!',
            title: 'Stwórz nowy folder',
        },
        preview: {
            title: 'Podgląd',
        },
        properties: {
            disk: 'Dysk',
            modified: 'Zmodyfikowano',
            name: 'Nazwa',
            path: 'Ścieżka',
            size: 'Rozmiar',
            title: 'Właściwości',
            type: 'Rodzaj',
            url: 'URL',
            access: 'Dostęp',
            access_0: 'Brak dostępu',
            access_1: 'Tylko do odczytu',
            access_2: 'Odczyt i zapis',
        },
        rename: {
            directoryExist: 'Katalog istnieje',
            fieldName: 'Wpisz nową nazwę',
            fieldFeedback: 'Błędna nazwa',
            fileExist: 'Plik istnieje',
            title: 'Zmień nazwę',
        },
        status: {
            noErrors: 'Bez błędów!',
            title: 'Status',
        },
        upload: {
            ifExist: 'Jeśli plik istnieje:',
            noSelected: 'Nie wybrano plików!',
            overwrite: 'Nadpisz',
            selected: 'Wybrano:',
            size: 'Rozmiar:',
            skip: 'Pomiń',
            title: 'Prześlij pliki',
        },
        editor: {
            title: 'Edytor',
        },
        audioPlayer: {
            title: 'Odtwarzacz muzyki',
        },
        videoPlayer: {
            title: 'Odtwarzacz wideo',
        },
        zip: {
            title: 'Utwórz archiwum',
            fieldName: 'Nazwa archiwum',
            fieldFeedback: 'Archiwum istnieje!',
        },
        unzip: {
            title: 'Rozpakuj archiwum',
            fieldName: 'Nazwa folderu',
            fieldRadioName: 'Wypakować do:',
            fieldRadio1: 'Do bieżącego folderu',
            fieldRadio2: 'W nowym folderze',
            fieldFeedback: 'Katalog istnieje!',
            warning: 'Uwaga! Przy identycznej nazwie, pliki zostaną nadpisane!',
        },
        cropper: {
            title: 'Przycinanie',
            apply: 'Zastosuj',
            reset: 'Reset',
            save: 'Zapisz',
        },
    },
    notifications: {
        cutToClipboard: 'Wytnij do schowka!',
        copyToClipboard: 'Skopiowane do schowka!',
    },
    response: {
        noConfig: 'Nie znaleziono konfiguracji!',
        notFound: 'Nie znaleziono!',
        diskNotFound: 'Nie znaleziono dysku!',
        pathNotFound: 'Ścieżka nie znaleziona!',
        diskSelected: 'Dysk wybrany!',
        // files
        fileExist: 'Plik już istnieje!',
        fileCreated: 'Plik utworzony!',
        fileUpdated: 'Plik zaktualizowany!',
        fileNotFound: 'Nie znaleziono pliku!',
        // directories
        dirExist: 'Katalog już istnieje!',
        dirCreated: 'Katalog utworzony!',
        dirNotFound: 'Nie znaleziono katalogu',
        // actions
        uploaded: 'Przesłano wszystkie pliki!',
        notAllUploaded: 'Niektóre pliki nie zostały przesłane!',
        delNotFound: 'Niektóre pozycje nie zostały znalezione!',
        deleted: 'Usunięto!',
        renamed: 'Zmieniono nazwę!',
        copied: 'Skopiowano pomyślnie!',
        // zip
        zipError: 'Błąd podczas tworzenia archiwum!',
        // acl
        aclError: 'Brak dostępu!',
    },
};

export default pl;
