<template>
  <div
    class="call-bottom pt-2"
    :class="{'justify-content-center border-top border-2': !minimized}"
  >
    <div class="call-btns">
      <div class="d-flex grouped-btns mr-2 centralize">
        <div
          class="call-btn"
          :class="[
            webcamState === 'on' ? 'bg-primary call-btn-on' : '',
            { disabled: me.webcamInProgress || me.shareInProgress }
          ]"
          @click="toggleWebcam"
        >
          <feather-icon
            :icon="webcamState === 'on' ? 'VideoIcon' : 'VideoOffIcon'"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
        <div
          class="call-btn"
          :class="micState === 'on' ? 'bg-primary call-btn-on' : ''"
          @click="toggleMicState"
        >
          <feather-icon
            :icon="micState === 'on' ? 'MicIcon' : 'MicOffIcon'"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
      </div>

      <!-- <div
        class="call-btn"
        :class="recordingState === 'on' ? 'bg-danger' : 'bg-primary'"
        @click="toggleRecordingState"
      >
        <feather-icon
          :icon="recordingState === 'on' ? 'SquareIcon' : 'CircleIcon'"
          fill="#ea5455"
          class="cursor-pointer"
          :class="recordingState === 'on' ? 'text-white' : 'text-danger'"
          size="21"
        />
      </div> -->

      <div class="text-white">
        <div
          class="call-btn call-btn-on"
          :class="[
            shareState === 'on' ? 'bg-danger' : 'bg-primary',
            { disabled: me.shareInProgress || me.webcamInProgress }
          ]"
          @click="toggleShare"
        >
          <feather-icon
            :icon="shareState === 'on' ? 'XSquareIcon' : 'CastIcon'"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
        <p class="mb-0">
          Share
        </p>
      </div>
      <div
        v-if="!minimized"
        class="mr-2 text-center text-white"
      >
        <b-badge
          v-show="unseenMsgCount"
          ref="messageBadge"
          v-b-tooltip.hover="'New Message'"
          class="float-right"
          variant="primary"
          pill
        >
          {{ unseenMsgCount }}
        </b-badge>
        <div
          class="call-btn call-btn-on mx-0"
          :class="showChat ? 'bg-wz-teal' : 'bg-primary'"
          @click="() => { $emit('toggle-chat'); unseenMsgCount = 0 }"
        >
          <feather-icon
            icon="MessageSquareIcon"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
        <p class="mb-0">
          Chat
        </p>
      </div>

      <div
        class="call-btn call-btn-on bg-danger ml-1"
        @click="endCall"
      >
        <feather-icon
          icon="PhoneIcon"
          class="cursor-pointer text-white"
          size="21"
        />
      </div>

      <div class="minimize-btn d-flex flex-column align-items-center text-white">
        <div
          class="call-btn call-btn-on bg-primary mr-0"
          @click="() =>$emit('toggle-call-view')"
        >
          <feather-icon
            :icon="minimized ? 'MinimizeIcon' : 'Minimize2Icon'"
            class="cursor-pointer text-white"
            size="21"
          />
        </div>
        <p class="mb-0">
          Minimize
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip, BBadge } from 'bootstrap-vue'
import EventBus from '@/event-bus'
import { ChatEvents } from 'workzone-chat-sdk'

export default {
  components: { BBadge },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    roomClient: {
      type: Object,
      required: true,
    },
    me: {
      type: Object,
      required: true,
    },
    minimized: {
      type: Boolean,
      default: () => false,
    },
    state: {
      type: Object,
      default: () => {},
    },
    roomUid: {
      type: String,
      required: true,
    },
    peers: {
      type: Array,
      default: () => [],
    },
    showChat: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isCameraOff: false,
      hideVideoCall: false,
      unseenMsgCount: 0,
    }
  },
  computed: {
    producers() {
      return Object.values(this.state?.producers)
    },
    videoConsumers() {
      return Object.values(this.state?.consumers)?.filter(consumer => consumer.track.kind === 'video')
    },
    screenShareConsumers() {
      // type simple is for screen sharing
      return this.videoConsumers?.filter(consumer => consumer.type === 'simple').length
    },
    audioProducer() {
      return this.producers?.find(producer => producer.track.kind === 'audio')
    },
    videoProducers() {
      return this.producers?.filter(producer => producer.track.kind === 'video')
    },
    recordingState() {
      return this.getVideoProducer('recording') ? 'on' : 'off'
    },
    shareState() {
      return this.getVideoProducer('share') ? 'on' : 'off'
    },
    micState() {
      let micState

      if (!this.me.canSendMic) {
        micState = 'unsupported'
      } else if (!this.audioProducer) {
        micState = 'unsupported'
      } else if (!this.audioProducer.paused) {
        micState = 'on'
      } else {
        micState = 'off'
      }
      return micState
    },
    webcamState() {
      let webcamState

      if (!this.me.canSendWebcam) {
        webcamState = 'unsupported'
      } else if (this.getVideoProducer('webcam')) {
        webcamState = 'on'
      } else {
        webcamState = 'off'
      }

      return webcamState
    },
  },
  watch: {
    screenShareConsumers(val) {
      if (val && this.getVideoProducer('share')) this.toggleShare()
    },
  },
  mounted() {
    this.$chatService.on(ChatEvents.CallLeftByUser, data => {
      if (data.participants < 1 && this.shareState === 'on') this.roomClient.disableShare()
    })

    EventBus.$on('message-received', ({ roomUid }) => {
      if (!this.showChat && this.roomUid === roomUid) {
        this.unseenMsgCount += 1
        this.bounceAnimation()
      }
    })
  },
  methods: {
    getVideoProducer(type) {
      if (type === 'webcam') {
        return this.videoProducers?.find(producer => producer.type === 'front' || producer.type === 'back')
      }
      return this.videoProducers?.find(producer => producer.type === type)
    },
    toggleRecordingState() {
      if (this.recordingState === 'on') this.roomClient.disableRecording()
      else this.roomClient.enableRecording()
    },
    toggleWebcam() {
      if (this.webcamState === 'on') {
        this.roomClient.disableWebcam()
      } else {
        this.roomClient.enableWebcam()
      }
    },
    toggleMicState() {
      if (this.micState === 'on') this.roomClient.muteMic()
      else this.roomClient.unmuteMic()
    },
    toggleShare() {
      if (this.shareState === 'on') {
        this.roomClient.disableShare()
      } else {
        this.roomClient.enableShare()
      }
    },
    endCall() {
      EventBus.$emit('call-ended')
      this.$chatService.endMediaCall(this.roomUid)
    },
    bounceAnimation() {
      if (this.$refs.messageBadge) {
        this.$refs.messageBadge.classList.add('message-badge')
        setTimeout(() => {
          this.$refs.messageBadge.classList.remove('message-badge')
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/chat/assets/mediasoup.scss";
</style>
