import gql from 'graphql-tag'

export default {
  GET_USERS: gql`
    query getUsers($keyword: [String!], $page: Int, $first: Int, $companyId: [String!], $projectId: [String!], $includeInactive: Boolean) {
      users(
        first: $first,
        page: $page,
        user: { searchKey: NAME, keyword: $keyword },
        project: { searchKey: UUID, keyword: $projectId },
        includeInactive: $includeInactive
      ) {
        data {
          name
          avatar
          email
          id
          uuid
          isOnline(project: { keyword: $projectId, searchKey: UUID })
          companyRoles(company: { searchKey: UUID, keyword: $companyId }, first: -1 ) {
            data {
              role {
                name
                displayName
              }
            }
          }
          projectRoles(project: { searchKey: UUID, keyword: $projectId }, first: -1, includeInactiveProjects: $includeInactive) {
            data {
              role {
                name
                displayName
              }
              deactivatedAt
            }
          }
        }
        total
      }
    }
  `,
}
