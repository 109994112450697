<template>
  <transition appear>
    <div>
      <!-- Float Call Bar -->
      <div
        v-show="hideVideoCall"
        class="float-call"
        :class="{ 'float-call-position': $route.name === 'chat' }"
      >
        <div class="detail col-6 d-flex align-items-center mt-1 px-0">
          <p
            v-b-tooltip.hover="activeChat.contact.name"
            class="contact-name text-truncate"
          >
            {{ activeChat.contact.name }}
          </p>
          <p
            class="pl-1"
            v-text="timer"
          />
        </div>
        <call-buttons
          v-if="roomClient"
          class="col-6 justify-content-end"
          :room-client="roomClient"
          :me="me"
          :minimized="true"
          :state="state"
          :room-uid="roomUid"
          @toggle-call-view="hideVideoCall = !hideVideoCall"
        />
        <div class="volume-container justify-content-end">
          <div
            class="bar"
            :class="`level${audioVolume}`"
          />
        </div>
      </div>

      <!-- Call Screen -->
      <div
        v-show="!hideVideoCall"
        class="video-call"
      >
        <div
          v-if="peers"
          class="call-screen"
        >
          <div
            v-show="peers.length > 0"
            class="local-video_wrap"
          >
            <div
              v-if="roomClientReady"
              class="video-box h-100"
              :class="{ 'active-speaker': amActiveSpeaker }"
            >
              <Peers
                :room-client="roomClient"
                :state="state"
                :video-peers="videoSharingPeers"
              />
            </div>
          </div>
          <perfect-scrollbar
            v-if="roomClientReady"
            :options="scrollbarOptions"
            :class="peers.length === 0 ? 'local-video_wrap w-100' : 'remote-video_wrap'"
            class="text-light scroll-area"
          >
            <div class="w-100">
              <Me
                :style="`height: ${peers.length === 0 ? '' : '140px'}; margin-bottom: 12px;`"
                :room-client="roomClient"
                :peer="peers.length === 0 ? peer : null"
                :state="state"
              />

              <Peers
                v-if="normalPeers && normalPeers.length"
                :room-client="roomClient"
                :state="state"
                :video-peers="normalPeers"
                :peer-style="'height: 140px; margin-bottom: 12px'"
                sidebar
              />
            </div>
          </perfect-scrollbar>
        </div>
        <div class="d-flex justify-content-center text-secondary">
          <p
            v-if="activeChat.contact.type === 'group'"
            class="font-weight-bold mr-2 text-light"
          >
            <feather-icon icon="UsersIcon" />
            {{ activeChat.contact.name }}
          </p>
          <p v-text="timer" />
        </div>
        <call-buttons
          v-if="roomClient"
          :room-client="roomClient"
          :me="me"
          :state="state"
          :room-uid="roomUid"
          :peers="peers"
          :show-chat="showChat"
          @toggle-call-view="hideVideoCall = !hideVideoCall"
          @toggle-chat="showChat = !showChat"
        />

        <div
          class="chat-sidebar position-absolute top-0 right-0 h-100"
          :class="{ show: showChat }"
        >
          <chat
            key="sidebar-chat"
            :current-active-chat="activeChat"
            :sidebar-chat="true"
            @hide-sidebar-chat="showChat = false"
          />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { VBTooltip } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { ChatEvents, StateActions } from 'workzone-chat-sdk'
import EventBus from '@/event-bus'
import Chat from '@/views/chat/Chat.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import Me from './Me.vue'
import Peers from './Peers.vue'
import CallButtons from './CallButtons.vue'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

export default {
  components: {
    Me, Peers, CallButtons, Chat, PerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    roomUid: {
      type: String,
      required: true,
    },
    peer: {
      type: Object,
      default: () => null,
    },
    roomClient: {
      type: Object,
      required: true,
    },
    activeChat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      self: getUserData(),
      roomClientReady: false,
      hideVideoCall: false,
      timer: '00 min, 00 sec',
      sec: 0,
      min: 0,
      hr: 0,
      NETWORK_THROTTLE_SECRET: window.NETWORK_THROTTLE_SECRET,
      audioVolume: 0,
      showChat: false,
      bothSharingPeer: null,
      videoSharingPeerPool: [],
      timerInterval: null,
      state: window.STORE.getState(),
      scrollbarOptions: {
        suppressScrollX: true,
      },
      videoSharingPeers: null,
      normalPeers: null,
      resizableOptions: {
        resizable: false,
        aspectRatio: false,
      },
    }
  },
  computed: {
    me() {
      return this.state.me
    },
    amActiveSpeaker() {
      return this.state.me.id === this.state.room.activeSpeakerId
    },
    room() {
      return this.state.room
    },
    peers() {
      return Object.values(this.state.peers)
    },
  },
  watch: {
    hideVideoCall(val) {
      if (val) {
        this.showChat = false
      }
    },
  },
  async mounted() {
    window.STORE.subscribe(() => {
      this.state = window.STORE.getState()
    })

    EventBus.$on('user-on-call', () => { this.hideVideoCall = false })

    EventBus.$on('audio-volume', volume => { this.audioVolume = volume })
    EventBus.$on('call-ended', () => {
      EventBus.$off('audio-volume')
      EventBus.$off('video-sharing')
      EventBus.$off('user-on-call')
    })

    window.STORE.dispatch(StateActions.setMe({
      peerId: this.self.uuid,
      displayName: this.self.name,
      avatar: this.self.avatar,
    }))

    this.roomClient = await this.$chatService.activeMediaRoom()
    this.roomClientReady = true
    this.$chatService.on(ChatEvents.CallEstablished, async () => {
      if (!this.timerInterval) this.startTimer()
      this.state = window.STORE.getState()
    })

    EventBus.$on('video-sharing', payload => this.handleVideoPeers(payload))
  },
  methods: {
    handleVideoPeers({
      peerId, sharing, type, consumerId,
    }) {
      // webcam or screen sharing turned on
      if (sharing) {
        // type 'simple' is for screen sharing
        if (type === 'simple') {
          // find if the peer is already sharing webcam
          const webcamConsumers = this.videoSharingPeerPool.filter(peer => peer.type === 'simulcast')
          const webcamConsumerOfPeer = webcamConsumers.find(peer => peer.id === peerId)

          // if current peer is sharing both webcam and screen
          // store the consumerId of simulcast consumer, since we used simple consumer for middle screen
          if (webcamConsumerOfPeer) {
            this.bothSharingPeer = { peerId, type: 'simulcast', consumerId: webcamConsumerOfPeer.consumerId }
          }

          // push only that peer to videoSharingPeerPool since we want only the shared screen in the middle
          this.showConsumerInFullScreen(peerId, type, consumerId)
        } else {
          // webcam sharing
          // check if anyone is sharing their screen
          const screenSharingPeer = this.videoSharingPeerPool.find(peer => peer.type === 'simple')
          // if no one is sharing their screen, push this webcam to videoSharingPeerPool
          if (!screenSharingPeer) {
            if (!this.videoSharingPeerPool.find(peer => peer.consumerId === consumerId)) {
              this.videoSharingPeerPool.push({ id: peerId, type: 'simulcast', consumerId })
            }
          // check if the current peer is already sharing screen
          } else if (peerId === screenSharingPeer.id) {
            // store the consumerId of simulcast consumer
            this.bothSharingPeer = { peerId, type, consumerId }
          }
        }
      // webcam or screen sharing turned off
      } else {
        // consumers in state
        const consumerSet = new Set(this.filterStateConsumers(null, null, 'id'))
        // consumers in local
        const videoSharingSet = new Set(this.videoSharingPeerPool.map(peer => peer.consumerId))
        // remove the disabled consumer
        this.removeConsumerFromUI(videoSharingSet, consumerSet, peerId)

        if (!this.videoSharingPeerPool.length || this.videoSharingPeerPool[0].type !== 'simple') {
          // add other consumers to video sharing peer pool
          const otherConsumers = consumerSet.filter(consumer => !videoSharingSet.has(consumer))
          Array.from(otherConsumers).forEach(id => {
            const consumerData = this.findStateConsumers('id', id)
            this.videoSharingPeerPool.push({
              id: consumerData.peerId,
              type: consumerData.type,
              consumerId: id,
            })
          })
        }
      }

      this.videoSharingPeers = this.getVideoPeers(true)
      this.normalPeers = this.getVideoPeers(false, peerId)
    },
    getVideoPeers(sharing, peerId = null) {
      if (!this.videoSharingPeerPool.length) return null
      let peerSharingBoth

      // array of ids from videoSharingPeerPool (local)
      const videoSharingPeerIds = this.videoSharingPeerPool.map(peer => peer.id)
      // array of peers (state) that are in videoSharingPeerPool (local)
      const videoSharingPeers = this.peers.filter(peer => videoSharingPeerIds.includes(peer.id))

      if (sharing) {
        if (this.bothSharingPeer && this.bothSharingPeer.type === 'simulcast') {
          peerSharingBoth = videoSharingPeers.find(peer => peer.id === this.bothSharingPeer.peerId)
          if (peerSharingBoth) peerSharingBoth.consumers = this.filterStateConsumers('type', 'simple', 'id')
        }
        return this.mapWithAudioConsumer(videoSharingPeers)
      }

      // add to the middle screen if the peer is still sharing webcam or screen
      const currentPeer = this.videoSharingPeerPool.find(peer => peer.id === peerId)
      const currentPeerInMiddleScreen = this.videoSharingPeers?.find(peer => peer.id === currentPeer?.id)
      if (currentPeerInMiddleScreen) {
        if (!currentPeerInMiddleScreen.consumers.includes(currentPeer.consumerId)) {
          currentPeerInMiddleScreen.consumers.push(currentPeer.consumerId)
        }

        // remove the video consumer from middle screen if the peer stops sharing
        const consumerSet = new Set(this.filterStateConsumers(null, null, 'id'))
        const consumerSetOfCurrentPeer = new Set(currentPeerInMiddleScreen.consumers)

        const removedConsumer = consumerSetOfCurrentPeer.find(consumer => !consumerSet.has(consumer))
        if (currentPeerInMiddleScreen.consumers.includes(removedConsumer)) {
          const index = currentPeerInMiddleScreen.consumers.indexOf(removedConsumer)
          currentPeerInMiddleScreen.consumers.splice(index, 1)
        }
      }

      // return non-sharing peers
      const normalPeers = this.peers.filter(peer => !videoSharingPeerIds.includes(peer.id))
      if (this.bothSharingPeer) {
        peerSharingBoth = videoSharingPeers.find(peer => peer.id === this.bothSharingPeer.peerId)
        normalPeers.push({
          ...peerSharingBoth,
          consumers: this.filterStateConsumers('type', this.bothSharingPeer.type, 'id'),
        })
      }

      return this.mapWithAudioConsumer(normalPeers)
    },
    mapWithAudioConsumer(peers) {
      if (!peers) return null
      return peers.map(peer => {
        const audioConsumer = this.findStateConsumers('peerId', peer.id, 'id', 'audio')

        if (audioConsumer) {
          peer.consumers.push(audioConsumer)
        }
        return peer
      })
    },
    showConsumerInFullScreen(peerId, type, consumerId) {
      this.videoSharingPeerPool = []
      const peer = {
        id: peerId,
        type,
        consumerId,
      }
      this.videoSharingPeerPool.push(peer)
    },
    removeConsumerFromUI(videoSharingSet, consumerSet, peerId) {
      // find the consumerId that was removed from state.consumers
      const removedConsumerId = videoSharingSet.find(el => !consumerSet.has(el))
      const removedPeer = this.videoSharingPeerPool.find(peer => peer.consumerId === removedConsumerId)

      if (this.bothSharingPeer?.peerId === peerId) {
        this.bothSharingPeer = null
      }
      const removedPeerIndex = this.videoSharingPeerPool.indexOf(removedPeer)
      // remove the peer from videoSharingPeerPool
      if (removedPeerIndex !== -1) this.videoSharingPeerPool.splice(removedPeerIndex, 1)
    },
    filterStateConsumers(key, value, mapKey = null, trackType = 'video') {
      return Object.values(this.state.consumers).filter(consumer => consumer.track.kind === trackType && (key ? consumer[key] === value : true))?.map(consumer => (mapKey ? consumer[mapKey] : consumer))
    },
    findStateConsumers(key, value, mapKey = null, trackType = 'video') {
      const consumer = Object.values(this.state.consumers).find(stateConsumer => stateConsumer.track.kind === trackType && (key ? stateConsumer[key] === value : true))

      return consumer && mapKey ? consumer[mapKey] : consumer
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.sec = Number(this.sec) + 1
        if (Number(this.sec) > 59) {
          this.sec = '00'
          this.min = Number(this.min) + 1
        }
        if (Number(this.min) > 59) {
          this.min = '00'
          this.hr = Number(this.hr) + 1
        }
        this.timer = `
          ${Number(this.hr) > 0 ? `${this.formatTime(this.hr, 'hr')},` : ''}
          ${this.formatTime(this.min, 'min')},
          ${this.formatTime(this.sec, 'sec')}
        `
      }, 1000)
    },
    formatTime(time, type) {
      if (String(time).length <= 1) return `0${this[type]} ${type}`
      return `${this[type]} ${type}`
    },
  },
}
</script>

<style lang="scss">
@import "@/chat/assets/mediasoup.scss";
</style>

<style lang="scss" scoped>
.float-call-position {
  top: 156px;
  right: 30px;
}

.contact-name {
  max-width: 250px;
}

.chat-sidebar {
  width: 35%;
  transform: translateX(110%);
  transition: transform 0.3s ease-in;
  z-index: 100;
  &.show {
    transform: translateX(0%);
  }
}
</style>
