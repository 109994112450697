import { initializeApp } from 'firebase/app'
import { getMessaging, isSupported } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
}

const checkSupport = async () => {
  const supports = await isSupported()
  return supports
}

let messaging

const app = initializeApp(firebaseConfig)
checkSupport().then(value => {
  if (value) {
    try {
      messaging = getMessaging(app)
    } catch (e) {
      console.log('Error:', e)
    }
  } else {
    console.log('FCM Error: Not Supported!')
  }
})

export default messaging
