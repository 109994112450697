import data from '@/views/chat/data'

export default {
  namespaced: true,
  state: {
    showFloatingChat: false,
    activeChat: {},
    isLoading: data.isLoading,
    rooms: [],
    initialChat: [],
    unseenMsgCounts: [],
  },
  mutations: {
    UPDATE_SHOW_FLOATING_CHAT(state, payload) {
      state.activeChat = payload.activeChat ?? null
      state.showFloatingChat = payload.isFloating
    },
    PUSH_ROOM(state, payload) {
      state.rooms.push(payload)
    },
    REMOVE_ROOM(state, payload) {
      state.rooms.splice(state.rooms.indexOf(payload), 1)
    },
    PUSH_INITIAL_CHAT(state, payload) {
      if (payload.participants?.length) {
        if (!state.initialChat.find(chat => chat.roomUid === payload.roomUid)) {
          state.initialChat.push(payload)
        }
      }
    },
    STORE_UNSEEN_MSG_COUNT(state, payload) {
      state.unseenMsgCounts.push(payload)
    },
    REMOVE_UNSEEN_MSG_COUNT(state, roomUid) {
      const index = state.unseenMsgCounts.findIndex(unseenMsg => unseenMsg.roomUid === roomUid)
      if (index !== -1) state.unseenMsgCounts.splice(index, 1)
    },
  },

  actions: {
    pushRoom({ commit }, payload) {
      commit('PUSH_ROOM', payload)
    },
    removeRoom({ commit }, payload) {
      commit('REMOVE_ROOM', payload)
    },
    pushInitialChat({ commit }, payload) {
      commit('PUSH_INITIAL_CHAT', payload)
    },
    setInitialChatLoad(ctx, payload) {
      return new Promise(resolve => {
        resolve(data.setInitialChatLoad(payload))
      })
    },
    storeUnseenMsgCount({ commit }, payload) {
      commit('STORE_UNSEEN_MSG_COUNT', payload)
    },
    removeUnseenMsgCount({ commit }, roomUid) {
      commit('REMOVE_UNSEEN_MSG_COUNT', roomUid)
    },
    setUnseenMsgCount(ctx, payload) {
      return new Promise(resolve => {
        resolve(data.setUnseenMsgCount(payload))
      })
    },
    fetchChatsAndContacts() {
      return new Promise(resolve => {
        resolve(data.getChatsAndContacts())
      })
    },
    getProfileUser() {
      return data.getProfileUser()
    },
    getChats(ctx, payload) {
      return new Promise(resolve => {
        resolve(data.getChats(payload))
      })
    },
    setChat(ctx, {
      roomUid, sender, message, messageUid, type,
    }) {
      return new Promise(resolve => {
        resolve(data.setChat({
          roomUid, sender, message, messageUid, type,
        }))
      })
    },
    increaseUnseenMsg(ctx, roomUid) {
      return new Promise(resolve => {
        resolve(data.increaseUnseenMsg(roomUid))
      })
    },
    inviteUser(ctx, payload) {
      return new Promise(resolve => {
        resolve(data.sendInvite(payload))
      })
    },
    sendMessage(ctx, {
      roomUid, contactId, message, senderId, messageRequestId, messageType, messageUid, type = 'sent',
    }) {
      return new Promise(resolve => {
        resolve(data.sendMessage({
          roomUid, message, senderId, contactId, messageRequestId, messageType, messageUid, type,
        }))
      })
    },
    removeGroup(ctx, { roomUid }) {
      data.removeGroup(roomUid)
    },
    getChatContact(ctx, roomUid) {
      return new Promise(resolve => resolve(data.getChatContact(roomUid)))
    },
    trimChat(ctx, roomUid) {
      data.trimChat(roomUid)
    },
  },
}
