/* eslint-disable import/extensions */
import isAuthenticated from '@/middlewares/isAuthenticated'
import onlyAdmin from '@/middlewares/onlyAdmin'

export default [
  {
    path: '/admin-settings',
    name: 'admin-settings',
    component: () => import('@/views/adminSettings/index.vue'),
    meta: {
      middleware: [isAuthenticated, onlyAdmin],
      breadcrumb: [
        {
          text: 'Admin Settings',
          active: true,
        },
      ],
    },
  },
]
