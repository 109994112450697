import gql from 'graphql-tag'

export default {
  GET_COUNTRIES: gql`
        query countries {
            countries {
                data {
                    name,
                    code
                }
            }
        }
    `,
  GET_LANGUAGES: gql`
     query langauges {
        languages {
             data {
                 name,
                 code
             }
         }
     }
 `,

}
