<template>
  <div class="file-wrapper text-light">
    <div>
      <!-- Image Messages -->
      <div
        v-if="showImage"
        class="w-100"
        @click="$emit('show-image', msgData.messageUid)"
      >
        <img
          class="object-fit-cover"
          :src="msgData.attachment ? msgData.attachment : attachment"
          alt=""
        >
      </div>
      <div
        v-else
        class="image-skeleton overflow-hidden"
      >
        <b-skeleton-wrapper>
          <b-skeleton width="80%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
          <b-skeleton width="100%" />
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import { BSkeletonWrapper, BSkeleton } from 'bootstrap-vue'

export default {
  components: {
    BSkeletonWrapper,
    BSkeleton,
  },
  props: {
    msgData: {
      type: Object,
      required: true,
    },
    attachment: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      showImage: false,
    }
  },
  watch: {
    attachment: {
      handler(val) {
        if (val && !this.msgData.attachment) {
          setTimeout(() => {
            this.showImage = true
          }, 150)
        }
      },
    },
  },
  mounted() {
    if (this.msgData.attachment) this.showImage = true
  },
}
</script>
