import HTTP from './axios'

export default {
/**
     * Revoke file or folder share
     * @param shareId
     * @returns {*}
     */
  revokeShare({ disk, shareId }) {
    return HTTP.delete('deleteShare', { params: { disk, file: shareId } })
  },
}
