/* eslint-disable import/no-cycle */
import { isUserLoggedIn } from '@/auth/utils'

export default function isNotAuthenticated({ next, router }) {
  if (isUserLoggedIn()) {
    return router.push({ name: 'home' })
  }

  return next()
}
