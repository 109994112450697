import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import { BRow, BCol }
from 'bootstrap-vue'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.component(BRow.name, BRow)
Vue.component(BCol.name, BCol)
