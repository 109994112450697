import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import project from './app/project'
import notification from './app/notification'
import chat from './app/chat'
import permissions from './app/permissions'
import tour from './app/tour'
import fm from './fm'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    project,
    notification,
    chat,
    permissions,
    tour,
    fm,
  },
  strict: process.env.DEV,
})
