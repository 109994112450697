import gql from 'graphql-tag'

export default {
  GET_SERVICES: gql`
query services{
    services {
      data {
        id
        name
        description
        image
        nameAlignment,
        imageAlignment
      }
    }
  }
`,

  GET_FAQS: gql`
query {
  FAQ{
    data{
      id
      question
      answer
    }
  }
}
`,

  GET_TESTIMONIALS: gql`
query {
  testimonial{
    data{
      id
      name
      company
      position
      testimonial
      image
    }
  }
}`,

  GET_WHOWEARE: gql`
query {
    whoWeAre {
    data {
      id
      title
    }
  }
}
`,

  GET_SLIDERS: gql`
query slider{
    slider {
      data {
        id
        title
        description
        image
      }
    }
  }
`,

  GET_RESULTS: gql`
query {
  result {
    data {
      title
      id
      percentage
    }
  }
}
`,

  GET_ALL_SECTIONS: gql`
query{
    cmsSections{
    id
    title
    description
    image
    section
  }
}
`,

  GET_SCREENSHOTTABS: gql`
query {
    screenshotTabs{
    id
    heading
    title
    subtitle
    icon
    image
}
}
`,
}
