import gql from 'graphql-tag'

export default {
  GET_MY_PROJECTS: gql`
    query projects($first: Int, $page: Int, $includeInactive: Boolean) {
      me {
        id
        projects(first: $first, page: $page, includeInactive: $includeInactive) {
          total
          data {
            uuid
            name
            company { 
              name
              address
              uuid
              currency
              weekStartDay
              timezoneOffset {
                name
                displayName
                filter
              }
            }
            users {
              total
            }
          }
        }
      }
    }
  `,
  SEARCH_PROJECTS: gql`
    query searchProjects($keyword: String!, $first: Int!, $page: Int!) {
      me {
        id
        projects(
          project: { searchKey: NAME, keyword: [$keyword], type: LIKE }
          first: $first
          page: $page
        ) {
          total
          data {
            uuid
            name
            company { 
              uuid
              currency
              weekStartDay
              timezoneOffset {
                name
                displayName
                filter
              }
            }
            users {
              total
            }
          }
        }
      }
    }
  `,
  GET_COMPANY_PROJECT: gql`
    query companyProject($uuid: String!, $first: Int, $page: Int) {
      company(uuid: $uuid) {
        projects(first: $first, page: $page) {
          total
          data {
            name
            description
            uuid
            teams {
              total
            }
            users {
              total
            }
          }
        }
      }
    }
  `,

  GET_USER_WITH_ROLE: gql`
    query userProjectRole($uuid: String!, $first: Int) {
      project(uuid: $uuid) {
        userRoles(first: $first) {
          data {
            user {
              uuid
              name
            }
            role {
              displayName
              name
            }
          }
        }
      }
    }
  `,
  GET_ALL_USERS_IN_PROJECT: gql`
    query userProject($uuid: String, $first: Int) {
      project(uuid: $uuid) {
          users(first: $first) {
            data {
              uuid
              name
            }
          }
        }
    }`,

  GET_ALL_USERS_IN_PROJECT_WITH_PERMISSION: gql`
    query userProjectWithPermission($keyword: [String!], $slug: String!, $projectId: String!, $first: Int, $page: Int) {
      project(uuid: $projectId) {
        users(user: { searchKey: NAME, keyword: $keyword }, first: $first, page: $page) {
          total
          data {
            uuid
            name
            isOnline
            hasPermission(
              slug:$slug,
              project:{searchKey:UUID, keyword:[$projectId]}
            )
          }
        }
      }
    }
  `,

  GET_PROJECT_PRODUCTIVITY_SETTINGS: gql`
    query projectProductivity($projectId: String!, $first: Int, $page: Int, $between: TimeRangeInput!) {
      project(uuid: $projectId) {
        productivitySettings(first: $first, page: $page, orderBy: { column: ACTIVITY_DURATION, order: DESC } ,between: $between) {
          total
          count
          data {
            title
            productivity
            duration
          }
        }
      }
    }
  `,

  GET_PROJECT_ROLE: gql`
    query projectRoles {
      projectRoles(first: -1, page: 1) {
        data {
          id
          name
          displayName
        }
      }
    }
  `,

  GET_ALL_PERMISSIONS: gql`
    query getAllPermissions(
      $projectUid: [String!]
      $slug: String
      $first: Int
      $page: Int
    ) {
      permissions(
        slug: $slug
        first: $first
        page: $page
      ){
        total
        data{
          slug
          description
          globalPermissions(project: {keyword: $projectUid, searchKey: UUID}){
              allow
              permissible{
                ... on UserRole{
                  name
                  displayName
                }
                ... on ProjectRole{
                  name
                  displayName
                }
              }
          }
          localPermissions(project: {keyword: $projectUid, searchKey: UUID}){
              allow
              permitted{
                ... on Project{
                  uuid
                  name
                }
                ... on Company{
                  uuid
                  name
                }
              }
              permissible{
                ... on User{
                  uuid
                  name
                }
                ... on UserRole{
                  name
                  displayName
                }
                ... on ProjectRole{
                  name
                  displayName
                }
            }
          }
        }
      }
    }
  `,
  GET_PERMISSIONS: gql`
  query getPermissions {
    permissions{
      total
      data{
        slug
        description
      }
    }
  }
`,
  GET_USER_TIME_SETTINGS: gql`
  query getUserTimeLimit(
    $userUid:String
    $projectUid: [String!]
  )
  {
    user(
      uuid: $userUid
    ){
      uuid,
      name,
      avatar,
      isOnline,
      projectSettings(
        type:PROJECTTIMELIMIT
        project: {keyword: $projectUid, searchKey:UUID}
        first: 1
      ) {
        total
        data {
          information {
            ... on ProjectTimeLimit {
              hours
              type
            }
          }
        }
      }
    } 
  }
  `,
}
