import auth from './Auth'
import company from './company/index'
import project from './projects'
import activities from './Activities'
import users from './users'
import team from './Team'
import payroll from './payroll'
import jobs from './jobs'
import jobPortal from './jobPortal'
import cms from './cms'
import calculator from './calculator'
// eslint-disable-next-line no-global-assign
export default Plugin = {
  auth,
  company,
  project,
  activities,
  users,
  team,
  payroll,
  jobs,
  jobPortal,
  cms,
  calculator,
}
