<template>
  <div>
    <div class="snapshot-preview text-white d-flex flex-wrap">
      <div
        v-for="(fileData, index) in files"
        :key="`${fileData.file.name}${index}`"
        class="snapshot-wrapper position-relative"
      >
        <img
          v-if="fileData.type === 'image'"
          :src="fileData.url"
          alt=""
        >
        <div
          v-else
          class="text-6 text-center border-light rounded-lg px-05"
        >
          <i
            class="fas"
            :class="fileData.icon"
          />
          <p class="text-1 text-truncate mb-0">
            {{ fileData.file.name }}
          </p>
        </div>
        <div
          v-b-tooltip.hover="`Remove ${fileData.type === 'image' ? 'Image' : 'File'}`"
          class="action-btn centralize text-white bg-danger cursor-pointer position-absolute right-0 top-0 fhw-28"
          @click="$emit('remove-file', index)"
        >
          <feather-icon
            icon="XIcon"
            size="14"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
}
</script>
