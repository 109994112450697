/**
 * zh_TW translate
 * @type Object
 */
/* eslint camelcase: 0 */
const zh_TW = {
    btn: {
        about: '關於',
        back: '返回',
        cancel: '取消',
        clear: '清除',
        copy: '複製',
        cut: '剪下',
        delete: '刪除',
        edit: '編輯',
        forward: '前進',
        folder: '新增目錄',
        file: '新增文件',
        fullScreen: '全螢幕',
        grid: '網格',
        paste: '貼上',
        refresh: '重新整理',
        submit: '送出',
        table: '表格',
        upload: '上傳',
        uploadSelect: '選擇文件',
        hidden: ' 隱藏文件',
    },
    clipboard: {
        actionType: '類型',
        copy: '複製',
        cut: '剪下',
        none: '未選取',
        title: '剪貼簿',
    },
    contextMenu: {
        copy: '複製',
        cut: '剪下',
        delete: '刪除',
        download: '下載',
        info: '選擇:',
        open: '打開',
        paste: '貼上',
        properties: '屬性',
        rename: '重新命名',
        select: '選擇',
        view: '查看',
        zip: '壓縮',
        unzip: '解壓縮',
        edit: '編輯',
        audioPlay: '播放',
        videoPlay: '播放',
    },
    info: {
        directories: '目錄:',
        files: '文件:',
        selected: '已選擇:',
        selectedSize: '已選擇文件大小:',
        size: '文件大小:',
    },
    manager: {
        table: {
            date: '日期',
            folder: '目錄',
            name: '名稱',
            size: '大小',
            type: '類型',
        },
    },
    modal: {
        about: {
            developer: '開發者資訊',
            name: '文件管理頁',
            title: '關於',
            version: '版本',
        },
        delete: {
            noSelected: '暫無選中!',
            title: '刪除',
        },
        newFile: {
            fieldName: '文件名',
            fieldFeedback: '文件已存在!',
            title: '新增文件',
        },
        newFolder: {
            fieldName: '目錄名',
            fieldFeedback: '目錄已存在!',
            title: '新增目錄',
        },
        preview: {
            title: '預覽',
        },
        properties: {
            disk: '磁碟',
            modified: '時間',
            name: '名稱',
            path: '路徑',
            size: '大小',
            title: '屬性',
            type: '類型',
            url: '網址',
            access: '授權',
            access_0: '禁止訪問',
            access_1: '唯獨',
            access_2: '讀寫',
        },
        rename: {
            directoryExist: '目錄存在',
            fieldName: '輸入名稱',
            fieldFeedback: '名稱不可用',
            fileExist: '文件存在',
            title: '重命名',
        },
        status: {
            noErrors: '暫無錯誤!',
            title: '狀態',
        },
        upload: {
            ifExist: '如果文件存在:',
            noSelected: '暫無選中!',
            overwrite: '覆蓋',
            selected: '已選擇:',
            size: '大小:',
            skip: '忽略',
            title: '上傳文件',
        },
        editor: {
            title: '編輯',
        },
        audioPlayer: {
            title: '播放音樂',
        },
        videoPlayer: {
            title: '播放影片',
        },
        zip: {
            title: '壓縮文件',
            fieldName: '壓縮名稱',
            fieldFeedback: '文件存在!',
        },
        unzip: {
            title: '解壓縮',
            fieldName: '目錄名稱',
            fieldRadioName: '解壓到:',
            fieldRadio1: '當前目錄',
            fieldRadio2: '輸入目錄',
            fieldFeedback: '目錄存在!',
            warning: '注意！如果文件存在將會被覆蓋！',
        },
        cropper: {
            title: '裁剪',
            apply: '套用',
            reset: '重設',
            save: '儲存',
        },
    },
    notifications: {
        cutToClipboard: '剪下到剪貼簿!',
        copyToClipboard: '複製到剪貼簿!',
    },
    response: {
        noConfig: '找不到設定檔!',
        notFound: '找不到項目!',
        diskNotFound: '找不到磁碟!',
        pathNotFound: '找不到路徑!',
        diskSelected: '磁碟已選取!',
        // files
        fileExist: '文件已存在!',
        fileCreated: '文件已建立!',
        fileUpdated: '文件已更新!',
        fileNotFound: '找不到文件!',
        // directories
        dirExist: '目錄已存在!',
        dirCreated: '目錄已存在!',
        dirNotFound: '找不到目錄',
        // actions
        uploaded: '全部文件已上傳完畢!',
        notAllUploaded: '部分文件未上傳!',
        delNotFound: '部分項目未被找到!',
        deleted: '已刪除!',
        renamed: '已重新命名!',
        copied: '已複製完成!',
        // zip
        zipError: '壓縮檔建立失敗!',
        // acl
        aclError: '存取拒絕!',
    },
};

export default zh_TW;
