import gql from 'graphql-tag'

export default {
  UPDATE_MANUAL_TIME: gql`mutation PostManualTime($startTime: DateTime!,$endTime: DateTime!,$remarks: String!, $userUid: String!, $projectUid: String!) {
    postManualTime(
        userUid:$userUid
        projectUid:$projectUid
        input:{
            startTime: $startTime
            endTime: $endTime
            remarks: $remarks
        }
    ){
        startedAt
        endedAt
    }
    } `,

  DESTROY_ACTIVITY: gql`mutation destroyActivities($projectUid: String!, $screencastUid: String!) {
        deleteScreencastActivity(projectUid: $projectUid, screencastUid: $screencastUid) {
            message
        }
    }`,
  POST_MEMO: gql`mutation updateScreencastMemo(
    $projectUid: String!
    $screencastUid: String!
    $memo: String!
    ) {
      updateScreencastMemo (
            projectUid: $projectUid
          screencastUid: $screencastUid
          memo: $memo
      ) {
        message
        
      }
    }`,

  UPDATE_PENDING_TIME: gql`
    mutation updatePendingTime(
      $projectUid: String!
      $userUid: String!
      $record: Int!
      $decline: Boolean
    ){
      approveManualTimeRecord(userUid: $userUid
        projectUid: $projectUid
        record: $record
        decline: $decline
      )
      {
        message
        status
      }
    }`,

  DESTROY_MANUAL_TIME: gql`
    mutation DeleteManualTime($projectUid: String!, $activityUid: String!) {
      deleteManualTime (
        projectUid: $projectUid
        activityUid: $activityUid
      ) {
        status
        message
        __typename
      }
    }
    `,
}
