import gql from 'graphql-tag'

export default {
  UPDATE_PAYMENT_TYPE: gql`
    mutation updatePaymentType(
      $projectUid: String!
      $userUid: String!
      $paymentType: UserPaymentType
      $amount: Float!
      $effectiveFrom: Date!
    ) {
      updateUserPaymentInfo(
        input: {
          projectUid: $projectUid
          userUid: $userUid
          paymentType: $paymentType
          amount: $amount
        }
        effectiveFrom: $effectiveFrom
      ) {
        message
      }
    }
  `,

  MAKE_PAYMENT: gql`
    mutation makePayment(
      $userUid: String!
      $projectUid: String!
      $between: DateRangeInput!
      $extraPayments: [ExtraPaymentInput!]
      $paymentMemo: String
      $alreadyPaidInfo: AlreadyPaidInfoInput
      $payrollWalletUid: String!
      $sourceWallet: WalletIdentifierInput!
    ) {
      makeUserPayment(
        userUid: $userUid,
        projectUid: $projectUid,
        between: $between,
        extraPayments: $extraPayments,
        paymentMemo: $paymentMemo,
        alreadyPaidInfo: $alreadyPaidInfo,
        payrollWalletUid: $payrollWalletUid,
        sourceWallet: $sourceWallet
      ) {
        status
        message
      }
    }
  `,

  UPDATE_PAY_DAY: gql`
    mutation updateCompanyPaymentSetting(
      $companyUid: String!
      $input: PaymentSettingInput!
    ) {
      updateCompanyPaymentSetting(companyUid: $companyUid, input: $input) {
        status
        message
      }
    }
  `,
  FINALIZE_PAYMENT: gql`
  mutation finalizeUserPayment(
    $projectUid: String!
    $userUid: String!
    $invoiceId: Int!
    $reason: String
    $documentData: String
  ) {
    finalizeUserPayment(userUid: $userUid,
      projectUid: $projectUid,
      invoiceId: $invoiceId,
      reason: $reason,
      documentData: $documentData
    ) {
      status
      message
    }
  }
`,
}
