<template>
  <div class="sticker-wrapper">
    <img
      :src="getSticker"
      alt=""
    >
  </div>
</template>

<script>
import stickers from '@/const/stickers'

export default {
  props: {
    msgData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stickers,
    }
  },
  computed: {
    getSticker() {
      let message
      try {
        message = JSON.parse(this.msgData.msg)
      } catch (e) {
        message = this.msgData.msg.message ?? this.msgData.msg
      }
      const [category, code] = message.stickerCode ? message.stickerCode.split('.') : message.split('.')
      const stickerUrl = stickers.find(stickerPool => stickerPool.category === category)
        .stickers.find(sticker => sticker.code === code).sticker
      return stickerUrl
    },
  },
}
</script>
