// eslint-disable-next-line import/no-cycle
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'

const createGroupName = (data, users, projectUsers) => {
  let name
  if (data.participants.length > 2) {
    let first2Participants = data.participants.filter(participant => users.includes(participant.uuid))
      .slice(0, 2).map(participant => participant.name)

    if (!first2Participants.length) {
      first2Participants = data.participants.filter(participant => users.includes(participant))
        .slice(0, 2)
        .map(participant => projectUsers.find(user => user.uuid === participant).name)
    }

    const remainingUsers = users.length - 1
    name = `${first2Participants.join(', ')}${remainingUsers ? ` and ${remainingUsers} other${remainingUsers > 1 ? 's' : ''}` : ''}`
  } else if (data.participants.length === 2) {
    name = `${projectUsers.find(user => user.uuid === users[0]).name} and You`
  }
  return name
}

export default {
  namespaced: true,
  state: {
    authUser: JSON.parse(localStorage.getItem('userData') ?? '{}'),
    selectedProject: localStorage.getItem('selected_project'),
    selectedCompany: localStorage.getItem('selected_company'),
    timezones: [],
    timezone: localStorage.getItem('timezone') ?? 'Asia/Kathmandu',
    timezoneFilter: JSON.parse(localStorage.getItem('timezone_filter')),
    company: JSON.parse(localStorage.getItem('company') ?? '{}'),
    project: JSON.parse(localStorage.getItem('project') ?? '{}'),
    userRole: localStorage.getItem('projectUserRole') ?? 'user',
    allPermissions: null,
    companyRole: localStorage.getItem('companyRole') ?? 'member',
    selectedUser: null,
    projectUsers: [],
    projectGroups: [],
    ongoingCall: null,
    hasGeneralInformation: true,
    hasBankingInformation: true,
    projectSettings: {},
  },
  getters: {
    GET_PROJECT_SETTINGS: state => state.projectSettings,
    GET_CURRENT_PROJECT: state => state.project,
    GET_CURRENT_COMPANY: state => state.company,
    GET_COMPANY_ROLE: state => state.companyRole,
    GET_INFORMATION_STATUS: state => ({
      general: state.hasGeneralInformation,
      banking: state.hasBankingInformation,
    }),

    USER_ROLE(state) {
      return state.userRole
    },
    COMPANY_CURRENCY(state) {
      return state.company?.currency
    },
    FILTERED_TIMEZONE_OR_DEFAULT(state) {
      if (state.timezoneFilter) {
        return {
          filter: state.timezoneFilter.filter,
          value: state.timezoneFilter.name || state.timezoneFilter.value,
        }
      }
      return {
        filter: state.company.timezoneOffset?.filter,
        value: state.company.timezoneOffset?.name,
      }
    },
    USER_MENU(state) {
      if (getUserData() && getUserData().isAdmin) {
        return 'admin'
      }
      switch (state.userRole) {
        case 'admin':
          return 'admin'

        default:
          return 'user'
      }
    },
    GET_SELECTED_USER: (state => state.selectedUser),
  },
  mutations: {
    SET_PROJECT_SETTINGS(state, val) {
      state.projectSettings = val
    },
    SET_INFORMATION_STATUS(state, val) {
      state.hasGeneralInformation = val.hasGeneralInformation ?? state.hasGeneralInformation
      state.hasBankingInformation = val.hasBankingInformation ?? state.hasBankingInformation
    },
    SET_SELECTED_PROJECT(state, val) {
      state.selectedProject = val
    },
    SET_SELECTED_COMPANY(state, val) {
      state.selectedCompany = val
    },
    SET_SELECTED_TIMEZONE(state, val) {
      state.timezone = val
    },
    STORE_COMPANY(state, val) {
      state.company = val
    },
    SET_SELECTED_TIMEZONE_FILTER(state, val) {
      state.timezoneFilter = val
    },
    STORE_PROJECT(state, val) {
      state.project = val
    },
    SET_USER_ROLE(state, val) {
      state.userRole = val
    },
    SET_MANAGE_PERMISSION(state, val) {
      state.managePermissions = val
    },
    SET_COMPANY_ROLE(state, val) {
      state.companyRole = val
      localStorage.setItem('companyRole', val)
    },
    SET_ALL_PERMISSIONS: (state, val) => {
      state.allPermissions = val
    },
    SET_PROJECT_USERS: (state, val) => {
      state.projectUsers = val
    },
    UPDATE_PROJECT_USER: (state, { users, data }) => {
      if (state.projectUsers.length) {
        if (!data.isGroupChat) {
          const projectUser = state.projectUsers.find(u => u.uuid === users[0])
          if (projectUser) {
            projectUser.roomUid = data.roomUid
            projectUser.isGroupChat = false
          }
        } else {
          const projectGroup = state.projectGroups.find(group => group.roomUid === data.roomUid)
          if (projectGroup) {
            projectGroup.adminUid = data.adminUid
            return
          }
          const name = data.name ?? createGroupName(data, users, state.projectUsers)

          const group = {
            name,
            roomUid: data.roomUid,
            type: 'group',
            adminUid: data.adminUid,
            participants: users.map(participant => state.projectUsers
              .find(user => user.uuid === participant)),
          }
          state.projectGroups.push(group)
        }
      }
    },
    REMOVE_USER_FROM_GROUP: (state, payload) => {
      const projectGroup = state.projectGroups.find(group => group.roomUid === payload.roomUid)
      if (payload.participant) {
        const userIndex = projectGroup.participants.indexOf(projectGroup.participants
          .find(participant => participant.uuid === payload.participant))
        projectGroup.participants.splice(userIndex, 1)
      } else if (payload.peerUids) {
        if (payload.peerUids.includes(getUserData().uuid)) {
          state.projectGroups.splice(state.projectGroups.indexOf(projectGroup), 1)
        } else {
          payload.peerUids.forEach(peer => {
            const peerIndex = projectGroup.participants.indexOf(projectGroup.participants
              .find(participant => participant.uuid === peer))
            projectGroup.participants.splice(peerIndex, 1)
          })
        }

        projectGroup.name = payload.name ?? createGroupName(projectGroup, projectGroup.participants.map(participant => participant.uuid), state.projectUsers)
      }
    },
    UPDATE_USERS_IN_ROOM: (state, payload) => {
      const projectGroup = state.projectGroups.find(group => group.roomUid === payload.roomUid)
      const participants = payload.participants.map(participantUid => state.projectUsers.find(user => user.uuid === participantUid))
      const projectParticipants = projectGroup.participants.map(participant => participant.uuid)
      participants.filter(participant => !projectParticipants.includes(participant.uuid))
        .map(participant => projectGroup.participants.push(participant))

      projectGroup.name = payload.name ?? createGroupName(projectGroup, participants.map(participant => participant.uuid), state.projectUsers)
    },
    REMOVE_ROOM: (state, payload) => {
      const groupToBeRemoved = state.projectGroups.find(group => group.roomUid === payload.roomUid)
      state.projectGroups.splice(state.projectGroups.indexOf(groupToBeRemoved), 1)
    },
    RENAME_ROOM: (state, payload) => {
      const projectGroup = state.projectGroups.find(group => group.roomUid === payload.roomUid)
      projectGroup.name = payload.name
    },
    SET_SELECTED_USER: (state, payload) => {
      state.selectedUser = payload
    },
    SET_ONGOING_CALL: (state, payload) => {
      state.ongoingCall = payload
    },
    SET_TIMEZONES: (state, payload) => {
      state.timezones = payload
    },
  },
  actions: {
    STORE_PROJECT_USERS({ commit }, value) {
      commit('SET_PROJECT_USERS', value)
    },
    updateProjectUser({ commit }, { users, data }) {
      commit('UPDATE_PROJECT_USER', { users, data })
    },
    STORE_SELECTED_PROJECT({ commit }, value) {
      commit('SET_SELECTED_PROJECT', value)
      localStorage.setItem('selected_project', value)
    },
    STORE_SELECTED_COMPANY({ commit }, value) {
      commit('SET_SELECTED_COMPANY', value)
      localStorage.setItem('selected_company', value)
    },
    STORE_SELECTED_TIMEZONE({ commit }, value) {
      localStorage.setItem('timezone', value)
      commit('SET_SELECTED_TIMEZONE', value)
    },
    STORE_SELECTED_TIMEZONE_FILTER({ commit }, value) {
      localStorage.setItem('timezone_filter', JSON.stringify(value))
      commit('SET_SELECTED_TIMEZONE_FILTER', value)
    },
    STORE_COMPANY({ commit }, value) {
      commit('STORE_COMPANY', value)
      localStorage.setItem('company', JSON.stringify(value))
    },
    STORE_PROJECT({ commit }, value) {
      commit('STORE_PROJECT', value)
      localStorage.setItem('project', JSON.stringify(value))
    },
    SET_USER_ROLE({ commit }, val) {
      commit('SET_USER_ROLE', val)
      localStorage.setItem('projectUserRole', val)
    },
    RESET_PROJECT_COMPANY({ commit }) {
      commit('SET_SELECTED_PROJECT', null)
      commit('SET_SELECTED_COMPANY', null)
      commit('STORE_PROJECT', null)
      commit('SET_USER_ROLE', null)
      commit('SET_COMPANY_ROLE', null)
    },
    SET_PROJECT_BASIC_REQUIREMENT({ dispatch, commit }, payload) {
      const { value, project } = payload
      dispatch('STORE_SELECTED_COMPANY', project.company.uuid)
      dispatch('STORE_SELECTED_PROJECT', value)
      dispatch('STORE_COMPANY', project.company)
      dispatch('STORE_SELECTED_TIMEZONE', project.company?.timezoneOffset.name)
      dispatch('STORE_SELECTED_TIMEZONE_FILTER', project.company?.timezoneOffset)
      dispatch('STORE_PROJECT', project)
      // set up project roles
      useApollo.users.getUserCompanyRole(project.company.uuid, project.uuid).then(response => {
        let roleName = response.data.me.projectRoles.data[0]?.role?.displayName
        const companyRole = response.data.me.companyRoles.data[0]?.role?.name
        commit('SET_COMPANY_ROLE', companyRole)
        if (response.data.me.isAdmin) {
          roleName = 'Administrator'
        } else if (!roleName) {
          roleName = response.data.me.project.data[0]?.role?.displayName || 'Project Member'
        }
        dispatch('SET_USER_ROLE', roleName)
        // dispatch('SET_COMPANY_ROLE', companyRole)
      })
    },
    STORE_ALL_PERMISSIONS({ commit }, projectUid) {
      useApollo.project.getAllPermissionsByProject({ projectUid }).then(response => {
        const permissions = response.data.permissions.data.map(permission => ({
          slug: permission.slug,
          description: permission.description,
        }))
        commit('SET_ALL_PERMISSIONS', permissions)
      })
    },
    removeUserFromRoom({ commit }, payload) {
      commit('REMOVE_USER_FROM_GROUP', payload)
    },
    updateUsersInRoom({ commit }, payload) {
      commit('UPDATE_USERS_IN_ROOM', payload)
    },
    removeRoom({ commit }, payload) {
      commit('REMOVE_ROOM', payload)
    },
    renameRoom({ commit }, payload) {
      commit('RENAME_ROOM', payload)
    },
    setSelectedUser({ commit }, payload) {
      commit('SET_SELECTED_USER', payload)
    },
    setOngoingCall({ commit }, payload) {
      commit('SET_ONGOING_CALL', payload)
    },
    setInformationStatus({ commit }, payload) {
      commit('SET_INFORMATION_STATUS', payload)
    },
    setProjectSettings({ commit }, payload) {
      commit('SET_PROJECT_SETTINGS', payload)
    },
    setTimezones({ commit }, payload) {
      commit('SET_TIMEZONES', payload)
    },
  },
}
