import { getUserData } from '@/auth/utils'
import store from '../store'

const moment = require('moment-timezone')

const fileTypes = ['image', 'pdf', 'csv', 'word', 'spreadsheet', 'powerpoint', 'audio', 'video']

export default {
  getRandomColor(name) {
    // get first alphabet in upper case
    const firstAlphabet = name.charAt(0).toLowerCase()
    const a = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']

    return {
      color: a[Math.floor(a.length * Math.random())],
      character: firstAlphabet.toUpperCase(),
    }
  },
  FORMAT_DATE(date, format = 'YYYY-MM-DD') {
    return moment(date).format(format)
  },
  ORDINAL_NUMBER(date) {
    return moment.localeData().ordinal(date)
  },
  GROUP_BY(array, key) {
    return array.reduce((r, a) => {
      // eslint-disable-next-line no-param-reassign
      r[a[key]] = r[a[key]] || []
      r[a[key]].push(a)
      return r
    }, Object.create(null))
  },

  FORMAT_HHMMSS(time) {
    try {
      const Numbestring = time.split(':')
      const hours = Number(Numbestring[0]) > 0 ? `${Numbestring[0]} Hours` : ''
      const minutes = `${Numbestring[1]} Minutes`
      const seconds = Number(Numbestring[2]) > 0 ? `${Numbestring[2]} Sec` : ''
      return hours + minutes + seconds
    } catch (err) {
      return '-'
    }
  },

  CONVERT_HM(value) {
    const sec = parseInt(value, 10) // convert value to number if it's string
    if (sec < 60 && sec > 0) return '< 1m'
    if (sec <= 0) return '0m'
    const hours = Math.floor(sec / 3600) // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
    // add 0 if value < 10; Example: 2 => 02
    if (hours > 0 && minutes < 10) {
      minutes = `0${minutes}`
    }
    if (hours <= 0) {
      return `${minutes}m`
    }
    return `${hours}h ${minutes}m` // Return is HH : MM : SS
  },

  CONVERT_HMS(value) {
    const sec = parseInt(value, 10) // convert value to number if it's string
    let hours = Math.floor(sec / 3600) // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
    let seconds = sec - hours * 3600 - minutes * 60 //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = `0${hours}`
    }
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    if (seconds < 10) {
      seconds = `0${seconds}`
    }
    return `${hours}:${minutes}:${seconds}` // Return is HH : MM : SS
  },

  TODAY({ format = 'YYYY-MM-DD', localTime = true } = {}) {
    if (!localTime) {
      return moment().utcOffset(store.state.project.timezoneFilter?.filter).format(format)
    }
    return moment().format(format)
  },

  isAfterTODAY(date) {
    return moment(date).isAfter(this.TODAY())
  },

  TODAY_COMPANY_TIME(format = 'YYYY-MM-DD') {
    return moment().utcOffset(store.state.project.company.timezoneOffset.filter).format(format)
  },

  INTERVAL_IN_WEEKS(date, format = 'YYYY-MM-DD HH:mm:ss') {
    const startDay = store.state.project.company?.weekStartDay ?? 'SUNDAY'
    let weekStart
    if (moment(date).day(startDay).diff(moment(), 'minute') > 0) {
      weekStart = moment(date)
        .day(startDay)
        .subtract('7', 'days')
        .format(format)
    } else {
      weekStart = moment(date).day(startDay).format(format)
    }
    const weekEnd = moment(weekStart).add('7', 'days').format(format)
    return {
      start: weekStart,
      end: weekEnd,
    }
  },

  INTERVAL_IN_MONTH(date, format = 'YYYY-MM-DD HH:mm:ss') {
    const now = moment(date)
    return {
      start: now.clone().startOf('month').format(format),
      end: this.ADD_DAYS(now.clone().endOf('month').format(format), 1, format)
        .start,
    }
  },

  ADD_DAYS(from, days, format = 'YYYY-MM-DD HH:mm:ss') {
    return {
      start: moment(from).add(days, 'days').startOf('day').format(format),
      end: moment(from).add(days, 'days').endOf('day').format(format),
    }
  },

  SUBTRACT_DAYS(from, days, format = 'YYYY-MM-DD HH:mm:ss') {
    return {
      start: moment(from).subtract(days, 'days').startOf('day').format(format),
      end: moment(from).endOf('day').format(format),
    }
  },

  YESTERDAY(from) {
    return {
      start: moment(from)
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      end: moment(from)
        .subtract(1, 'days')
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
    }
  },

  CALC_PERCENTAGE({ total, value }) {
    return total > 0 ? (value / total) * 100 : 0
  },

  toUTC(from) {
    const utcTime = moment
      .tz(from, 'YYYY-MM-DD HH:mm:ss', localStorage.timezone)
      .toISOString()
      .slice(0, -1)
      .replace('T', ' ')
      .replace('.000', '')
    return utcTime
  },

  fromNow(from) {
    return moment(from).fromNow()
  },

  isBeforeAWeek(date) {
    const startDate = moment().subtract(7, 'days')
    return moment(date).isBefore(startDate)
  },

  showTimeForChat(date) {
    if (moment(date).isSame(moment().format(), 'day')) return moment(date).format('hh:mm A')
    // const yesterday = moment().subtract(1, 'day')
    if (moment(date).isSame(moment().subtract(1, 'days').format(), 'day')) return moment(date).format('[Yesterday], hh:mm A')
    if (!this.isBeforeAWeek(date)) return moment(date).format('dddd, hh:mm A')
    return moment(date).format('DD MMM, hh:mm A')
  },

  getTimezoneOffset() {
    function z(n) {
      return (n < 10 ? '0' : '') + n
    }
    let offset = new Date().getTimezoneOffset()
    const sign = offset < 0 ? '+' : '-'
    offset = Math.abs(offset)
    // eslint-disable-next-line no-bitwise
    return `${sign + z((offset / 60) | 0)}:${z(offset % 60)}`
  },

  getSavedTimezoneOffset(isLocal = false) {
    if (isLocal) {
      return this.getTimezoneOffset()
    }
    return store.state.project.timezoneFilter?.filter
  },

  timeWithOutTimeZone(from, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!from) return '-'
    return moment(from).format(format)
  },

  timeWithTimeZone(from, timezone, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!from) return '-'
    const cutoffString = from // in utc
    const utcCutoff = moment.utc(cutoffString, 'YYYYMMDD HH:mm:ss')
    const displayCutoff = utcCutoff.clone().tz(timezone)
    return displayCutoff.format(format)
  },

  number_format(str, round = false) {
    let number = parseFloat(str)
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(number)) {
      return '--'
    }
    if (round || round === 0) {
      number = number.toFixed(round)
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },

  startOfThisMonth() {
    return moment().startOf('month')
  },

  GET_TIME_DIFFERENCE(start, end, type = 'seconds') {
    return moment(end).diff(moment(start), type)
  },

  // for online offline
  GET_CURRENT_START_END_TIME() {
    return {
      startTimeNow: moment().subtract('1', 'minute').format('YYYY-MM-DD HH:mm:ss'),
      endTimeNow: moment().format('YYYY-MM-DD HH:mm:ss'),
    }
  },

  GET_DAY_BETWEEN_DAYS(start, end) {
    const days = []
    const startDay = moment(start)
    const endDay = moment(end)
    while (startDay < endDay) {
      days.push({
        date: startDay.format('YYYY-MM-DD'),
        start: startDay.format('YYYY-MM-DD 00:00:00'),
        end: startDay.add('1', 'day').format('YYYY-MM-DD 00:00:00'),
      })
    }
    return days
  },

  getRandomVariant() {
    const a = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']
    return a[Math.floor(a.length * Math.random())]
  },

  getAvatar(path, noSuffix = 0) {
    if (path) {
      if (path === 'users/default.png') return null
      return noSuffix ? `${process.env.VUE_APP_STORAGE_URL}${path}` : `${process.env.VUE_APP_STORAGE_URL}${path}?r=${this.unixDate()}`
    }
    return null
  },

  getStorage(path) {
    if (path) {
      return `${process.env.VUE_APP_STORAGE_URL}${path}`
    }
    return null
  },

  getInitials(name) {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

    let initials = [...name.matchAll(rgx)] || []

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase()

    return initials
  },

  CAPITALIZE(text) {
    return `${text.charAt(0).toUpperCase()}${text.slice(1, text.length)}`
  },

  unixDate() {
    return moment().unix()
  },
  formatAmount(amount, decimalPlaces = true) {
    let amt = Number(amount)
    if (!amt) amt = 0

    const amountString = amt >= 0
      ? `${store.state.project.company.currency}${amt.toFixed(decimalPlaces ? 2 : 0)}`
      : `- ${store.state.project.company.currency}${Math.abs(amt.toFixed(decimalPlaces ? 2 : 0))}`
    return amountString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  UTCtoLocal(date) {
    return moment.utc(date).local().format()
  },
  UTCOffset(date, offset) {
    return moment(date).utcOffset(offset)
  },
  ACCUMULATE(array, key = null) {
    return key ? array.reduce((a, item) => a + Number(item[key]), 0) : array.reduce((a, b) => Number(a) + Number(b), 0)
  },
  TRUNCATE_TEXT(text, length) {
    return text.length > length ? `${text.slice(0, length)}...` : text
  },
}
