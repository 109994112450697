<template>
  <div class="file-wrapper text-light p-1">
    <div class="d-flex align-items-center">
      <i
        class="fas mr-1 text-3"
        :class="msgData.messageType === 'ATTACHMENT' ? fileIcon : ''"
      />
      <div class="text-truncate">
        <p class="mb-0">
          {{ msgData.msg.filename }}
        </p>
        <p class="mb-0">
          <span class="text-capitalize">{{ messageType }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import useChat from '../../useChat'

const { GET_FILE_ICON } = useChat()
export default {
  props: {
    msgData: {
      type: Object,
      required: true,
    },
    messageType: {
      type: String,
      required: true,
    },
  },
  computed: {
    fileIcon() {
      return GET_FILE_ICON(this.messageType)
    },
  },
}
</script>
