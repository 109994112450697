export default {
  namespaced: true,
  state: {
    canEditCompany: {
      slug: 'company.edit',
      value: false,
    },
    canInviteUser: {
      slug: 'projects.invite',
      value: false,
    },
    canEditTimezones: {
      slug: 'timezones.update',
      value: false,
    },
    canEditCompanyBilling: {
      slug: 'company.billing.edit',
      value: false,
    },
    canViewUsers: {
      slug: 'project.viewusers',
      value: false,
    },
    canManageUsers: {
      slug: 'project.manageusers',
      value: false,
    },
    canEditTimeLimits: {
      slug: 'timing.limit',
      value: false,
    },
    canEditUserSettings: {
      slug: 'project.usersettings',
      value: false,
    },
    canViewPayment: {
      slug: 'payment.view',
      value: false,
    },
    canEditPayment: {
      slug: 'payment.edit',
      value: false,
    },
    canApproveManualTime: {
      slug: 'timing.manual.approve',
      value: false,
    },
    canUpdateProductivity: {
      slug: 'productivity.update',
      value: false,
    },
    canCreateLeads: {
      slug: 'leads.create',
      value: false,
    },
    canFollowLeads: {
      slug: 'leads.follow',
      value: false,
    },
    canCloseLeads: {
      slug: 'leads.close',
      value: false,
    },
    canUpdateCMS: {
      slug: 'cms.update',
      value: false,
    },
    canManagePermissions: {
      slug: 'permissions.manage',
      value: false,
    },
    isCompanyAccountant: {
      slug: 'accounts.company',
      value: false,
    },
  },
  getters: {
    canEditCompany: state => state.canEditCompany.value,
    canInviteUser: state => state.canInviteUser.value,
    canEditTimezones: state => state.canEditTimezones.value,
    canEditCompanyBilling: state => state.canEditCompanyBilling.value,
    canViewUsers: state => state.canViewUsers.value,
    canManageUsers: state => state.canManageUsers.value,
    canEditTimeLimits: state => state.canEditTimeLimits.value,
    canEditUserSettings: state => state.canEditUserSettings.value,
    canViewPayment: state => state.canViewPayment.value,
    canEditPayment: state => state.canEditPayment.value,
    canApproveManualTime: state => state.canApproveManualTime.value,
    canUpdateProductivity: state => state.canUpdateProductivity.value,
    canCreateLeads: state => state.canCreateLeads.value,
    canFollowLeads: state => state.canFollowLeads.value,
    canCloseLeads: state => state.canCloseLeads.value,
    canUpdateCMS: state => state.canUpdateCMS.value,
    canManagePermissions: state => state.canManagePermissions.value,
    isCompanyAccountant: state => state.isCompanyAccountant.value,
    getPermissionsCount: state => payload => Object.values(state).filter(permission => payload.includes(permission.slug))
      .filter(permission => permission.value === true).length,
  },
  mutations: {
    SET_CAN_EDIT_COMPANY(state, val) {
      state.canEditCompany.value = val
    },
    SET_CAN_INVITE_USER(state, val) {
      state.canInviteUser.value = val
    },
    SET_CAN_EDIT_TIMEZONES(state, val) {
      state.canEditTimezones.value = val
    },
    SET_CAN_EDIT_COMPANY_BILLING(state, val) {
      state.canEditCompanyBilling.value = val
    },
    SET_CAN_VIEW_USERS(state, val) {
      state.canViewUsers.value = val
    },
    SET_CAN_MANAGE_USERS(state, val) {
      state.canManageUsers.value = val
    },
    SET_CAN_EDIT_TIME_LIMITS(state, val) {
      state.canEditTimeLimits.value = val
    },
    SET_CAN_EDIT_USER_SETTINGS(state, val) {
      state.canEditUserSettings.value = val
    },
    SET_CAN_VIEW_PAYMENT(state, val) {
      state.canViewPayment.value = val
    },
    SET_CAN_EDIT_PAYMENT(state, val) {
      state.canEditPayment.value = val
    },
    SET_CAN_APPROVE_MANUAL_TIME(state, val) {
      state.canApproveManualTime.value = val
    },
    SET_CAN_UPDATE_PRODUCTIVITY(state, val) {
      state.canUpdateProductivity.value = val
    },
    SET_CAN_CREATE_LEADS(state, val) {
      state.canCreateLeads.value = val
    },
    SET_CAN_FOLLOW_LEADS(state, val) {
      state.canFollowLeads.value = val
    },
    SET_CAN_CLOSE_LEADS(state, val) {
      state.canCloseLeads.value = val
    },
    SET_CAN_UPDATE_CMS(state, val) {
      state.canUpdateCMS.value = val
    },
    SET_CAN_MANAGE_PERMISSIONS(state, val) {
      state.canManagePermissions.value = val
    },
    SET_IS_COMPANY_ACCOUNTANT(state, val) {
      state.isCompanyAccountant.value = val
    },
  },
  actions: {
    setCompanyEditPermission({ commit }, payload) {
      commit('SET_CAN_EDIT_COMPANY', payload)
    },
    setUserInvitePermission({ commit }, payload) {
      commit('SET_CAN_INVITE_USER', payload)
    },
    setTimezonesEditPermission({ commit }, payload) {
      commit('SET_CAN_EDIT_TIMEZONES', payload)
    },
    setCompanyBillingPermission({ commit }, payload) {
      commit('SET_CAN_EDIT_COMPANY_BILLING', payload)
    },
    setUserViewPermission({ commit }, payload) {
      commit('SET_CAN_VIEW_USERS', payload)
    },
    setManageUserPermission({ commit }, payload) {
      commit('SET_CAN_MANAGE_USERS', payload)
    },
    setTimeLimitsPermission({ commit }, payload) {
      commit('SET_CAN_EDIT_TIME_LIMITS', payload)
    },
    setUserSettingsEditPermission({ commit }, payload) {
      commit('SET_CAN_EDIT_USER_SETTINGS', payload)
    },
    setPaymentViewPermission({ commit }, payload) {
      commit('SET_CAN_VIEW_PAYMENT', payload)
    },
    setPaymentEditPermission({ commit }, payload) {
      commit('SET_CAN_EDIT_PAYMENT', payload)
    },
    setManualTimeApprovePermission({ commit }, payload) {
      commit('SET_CAN_APPROVE_MANUAL_TIME', payload)
    },
    setProductivityEditPermission({ commit }, payload) {
      commit('SET_CAN_UPDATE_PRODUCTIVITY', payload)
    },
    setCreateLeadsPermission({ commit }, payload) {
      commit('SET_CAN_CREATE_LEADS', payload)
    },
    setFollowLeadsPermission({ commit }, payload) {
      commit('SET_CAN_FOLLOW_LEADS', payload)
    },
    setCloseLeadsPermission({ commit }, payload) {
      commit('SET_CAN_CLOSE_LEADS', payload)
    },
    setCMSEditPermission({ commit }, payload) {
      commit('SET_CAN_UPDATE_CMS', payload)
    },
    setPermissionsManagePermission({ commit }, payload) {
      commit('SET_CAN_MANAGE_PERMISSIONS', payload)
    },
    setAccountantPermission({ commit }, payload) {
      commit('SET_IS_COMPANY_ACCOUNTANT', payload)
    },
    setPermissions({ dispatch }, payload) {
      payload.forEach(permission => {
        dispatch(permission.key, permission.value)
      })
    },
    clearPermissions({ dispatch }) {
      const permissions = [
        { key: 'setCompanyEditPermission', value: false },
        { key: 'setCompanyEditPermission', value: false },
        { key: 'setUserInvitePermission', value: false },
        { key: 'setTimezonesEditPermission', value: false },
        { key: 'setAccountantPermission', value: false },
        { key: 'setPermissionsManagePermission', value: false },
        { key: 'setCMSEditPermission', value: false },
        { key: 'setCloseLeadsPermission', value: false },
        { key: 'setFollowLeadsPermission', value: false },
        { key: 'setCreateLeadsPermission', value: false },
        { key: 'setProductivityEditPermission', value: false },
        { key: 'setManualTimeApprovePermission', value: false },
        { key: 'setPaymentEditPermission', value: false },
        { key: 'setPaymentViewPermission', value: false },
        { key: 'setUserSettingsEditPermission', value: false },
        { key: 'setTimeLimitsPermission', value: false },
        { key: 'setManageUserPermission', value: false },
        { key: 'setCompanyBillingPermission', value: false },
        { key: 'setUserViewPermission', value: false },
      ]

      dispatch('setPermissions', permissions)
    },
  },
}
