import gql from 'graphql-tag'

export default {
  UPDATE_EMPLOYEES: gql`
    mutation updateEmployees(
      $company: TempCompanyInput!
      $input: [UpdateEmployeesInput!]!
    ) {
      updateEmployees(
        input: $input
        company: $company
      ) {
        status
        message
      }
    }
  `,
  REMOVE_EMPLOYEE: gql`
    mutation removeEmployee(
      $id: ID!
      $companyId: ID!
      $type: String!
    ) {
      removeEmployee(
        id: $id,
        companyId: $companyId
        type: $type
      ) {
        status
        message
      }
    }
  `,
  UPDATE_REGION: gql`
    mutation updateRegion(
      $id: ID,
      $input: RegionInput!,
    ) {
      updateRegion(
        id: $id,
        input: $input,
      ) {
        status
        message
      }
    }
  `,
  CREATE_COVERAGE_CATEGORY: gql`
    mutation createCoverageCategory(
      $name: String!
    ) {
      createCoverageCategory(name:$name){
        __typename
      }
    }
  `,
  UPDATE_COVERAGE_CATEGORY: gql`
    mutation updateCoverageCategory(
      $id: ID!,
      $name: String!
    ) {
      updateCoverageCategory(
        id: $id,
        name: $name,
      ) {
        __typename
      }
    }
  `,
  UPDATE_COVERAGES: gql`
    mutation updateCoverages(
      $region: ID!,
      $coverageCategory: ID!,
      $input: [CoverageInput!]!
    ) {
      updateCoverages(
        region: $region,
        coverageCategory: $coverageCategory,
        input: $input
      ) {
        status
        message
      }
    }
  `,
  CREATE_TEMP_COMPANY: gql`
    mutation createTempCompany(
      $input: TempCompanyInput!
    ) {
      createTempCompany(
        input: $input
      ) {
        __typename
      }
    }
  `,
}
