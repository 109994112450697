import apolloProvider from '@/libs/vue-apollo'
import mutation from './mutation'
import queries from './queries'

export default {
  updatePaymentSetting(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_PAYMENT_TYPE,
      variables: {
        ...data,
      },
    })
  },

  getPaymentSettings({
    first, page, companyId, projectId,
  }) {
    return apolloProvider.query({
      query: queries.GET_USERS_PAYMENT,
      fetchPolicy: 'no-cache',
      variables: {
        first,
        page,
        companyId,
        projectId,
      },
    })
  },

  getUserPaymentSettingHistory({
    first, page, userId, projectUUid,
  }) {
    return apolloProvider.query({
      query: queries.GET_USER_PAYMENT_SETTING_HISTORY,
      fetchPolicy: 'no-cache',
      variables: {
        first,
        page,
        userId,
        projectUUid,
      },
    })
  },

  getUserPayments({
    first, page, companyId, startTime, endTime,
    timezone, projectUUid, selectedUser,
  }) {
    return apolloProvider.query({
      query: queries.GET_PAYMENT_USER,
      fetchPolicy: 'no-cache',
      variables: {
        first,
        page,
        companyId,
        startTime,
        endTime,
        timezone,
        projectUUid,
        selectedUser,
      },
    })
  },

  getExtraPaymentCategory() {
    return apolloProvider.query({
      query: queries.GET_EXTRA_PAYMENT_CATEGORY,
      fetchPolicy: 'no-cache',
      variables: {},
    })
  },

  postPayment(data) {
    return apolloProvider.mutate({
      mutation: mutation.MAKE_PAYMENT,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },

  getUserInvoiceHistory(variables) {
    return apolloProvider.query({
      query: queries.GET_USER_INVOICE_HISTORY,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
      },
    })
  },

  getUserInvoiceDates(variables) {
    return apolloProvider.query({
      query: queries.GET_USER_INVOICE_DATES,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
      },
    })
  },

  getUserTotals(data) {
    return apolloProvider.query({
      query: queries.GET_USER_TOTALS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },

  updatePayDay(variables) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_PAY_DAY,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
      },
    })
  },
  finalizePayment(variables) {
    return apolloProvider.mutate({
      mutation: mutation.FINALIZE_PAYMENT,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
      },
    })
  },
}
