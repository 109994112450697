/* eslint-disable no-restricted-syntax */
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-boost'
import { setContext } from 'apollo-link-context'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

// eslint-disable-next-line import/no-extraneous-dependencies
// import { createHttpLink } from 'apollo-link-http'
// eslint-disable-next-line import/no-extraneous-dependencies
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import store from '../store'

function customFetch(url, opts = {}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open(opts.method || 'get', url)

    // eslint-disable-next-line no-restricted-syntax
    // eslint-disable-next-line guard-for-in
    for (const k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])

    xhr.onload = e => resolve({
      ok: true,
      text: () => Promise.resolve(e.target.responseText),
      json: () => Promise.resolve(JSON.parse(e.target.responseText)),
    })

    xhr.onerror = reject

    if (xhr.upload) {
      xhr.upload.onprogress = event => store.commit('app/UPDATE_FILE_UPLOAD_PROGRESS', (event.loaded / event.total) * 100)
    }

    xhr.send(opts.body)
  })
}
const cache = new InMemoryCache()
// const { typeDefs } = require('./server-schema')

Vue.use(VueApollo)
const AUTH_TOKEN = 'accessToken'

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_GEC_GRAPHQL,
  fetch: typeof window === 'undefined' ? global.fetch : customFetch,
})

const authLink = setContext(async (_, { headers }) => {
  // Use your async token function here:
  const token = await localStorage.getItem(AUTH_TOKEN)
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: `${jwtDefaultConfig.tokenType} ${token}` || '',
    },
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,

})

export default apolloProvider.defaultClient
