import gql from 'graphql-tag'

export default {
  STORE_PROJECT: gql`
    mutation createProject(
      $name: String!
      $description: String!
      $companyUid: String!
      $ownerUid: String!
    ) {
      createProject(
        input: { 
          name: $name, 
          description: $description 
        }
        ownerUid: $ownerUid
        companyUid: $companyUid
      ) {
        data {
          uuid
          name
          description
          company {
            uuid
            name
          }
        }
      }
    }
  `,
  UPDATE_PROJECT: gql`
    mutation updateProject(
      $id: String!
      $name: String!
      $description: String!
    ) {
      updateProject(
        input: { name: $name, description: $description }
        projectUid: $id
      ) {
        data {
          uuid
          name
          description
          company {
            uuid
            name
          }
        }
      }
    }
  `,

  ADD_PROJECT_USER: queries => gql`mutation {
      \n 
        ${queries}
      \n
    }`,
  UPDATE_PROJECT_PRODUCTIVITY_SETTINGS: gql`
    mutation updateProductivityInfo(
      $projectId: String!
      $title: String!
      $productivity: Productivity!
      $updateSince: Date
    ) {
      updateProductivityInfo(
        projectUid: $projectId
        input: { title: $title, productivity: $productivity, updateSince: $updateSince}
      ) {
        message
        status
      }
    }
  `,
  UPDATE_PROJECT_PERMISSION: gql`
    mutation updateProjectPermission(
      $projectUid: String!
      $companyUid: String!
      $slug: String!
      $input: PermissionUpdateInput! 
    ) {
      updateProjectPermission(
        slug: $slug,
        input: $input,
        projectUid: $projectUid,
        companyUid: $companyUid,
      ) {
        message
        status
      }
    }
  `,
  UPDATE_COMPANY_PERMISSION: gql`
  mutation updateCompanyPermission(
    $companyUid: String!
    $slug: String!
    $input: PermissionUpdateInput! 
  ) {
    updateCompanyPermission(
      slug: $slug,
      input: $input,
      companyUid: $companyUid,
    ) {
      message
      status
    }
  }
`,
  // #endregion
}
