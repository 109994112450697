<template>
  <b-modal
    :id="`${chatType}-chat-images-preview-modal`"
    size="lg"
    no-close-on-backdrop
    hide-footer
  >
    <swiper
      ref="chatImageSwiper"
      class="swiper"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="chatImage of chatImages"
        :key="chatImage.messageUid"
        class="mb-2 centralize image-preview-wrapper"
      >
        <div class="image-preview-wrapper centralize">
          <div
            v-if="isLoading"
            class="w-100"
          >
            <b-skeleton-wrapper>
              <b-skeleton-img
                no-aspect
                height="580px"
              />
            </b-skeleton-wrapper>
          </div>
          <img
            v-else
            class="object-fit-cover"
            :src="chatImage.attachment"
            alt=""
          >
        </div>
      </swiper-slide>
      <div
        v-show="chatImages.length > 1"
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        v-show="chatImages.length > 1"
        slot="button-next"
        class="swiper-button-next"
      />
    </swiper>
  </b-modal>
</template>

<script>
import { BModal, BSkeletonWrapper, BSkeletonImg } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    BModal,
    BSkeletonWrapper,
    BSkeletonImg,
    Swiper,
    SwiperSlide,
  },
  props: {
    chatImages: {
      type: Array,
      default: () => [],
    },
    initialImage: {
      type: String,
      default: () => '',
    },
    chatType: {
      type: String,
      default: () => 'regular',
    },
  },
  data() {
    return {
      image: null,
      isLoading: false,
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  watch: {
    initialImage: {
      handler(val) {
        if (val) {
          this.isLoading = true
          const image = this.chatImages.find(chat => chat.messageUid === val)
          setTimeout(() => { this.slideTo(this.chatImages.indexOf(image)) }, 500)
        }
      },
      immediate: true,
    },
  },
  methods: {
    slideTo(index) {
      if (this.$refs.chatImageSwiper) {
        this.$refs.chatImageSwiper.$swiper.slideTo(index)
        this.$nextTick(() => { this.isLoading = false })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.image-preview-wrapper {
  width: 760px;
  height: 620px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
