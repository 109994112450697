<template>
  <div
    class="bordered d-flex align-items-center"
    :class="callInfo.iconColorClass"
  >
    <div class="circle-icon centralize">
      <feather-icon
        :icon="callInfo.icon"
        size="18"
      />
    </div>
    {{ callInfo.text }}
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import useChat from '../../useChat'

const { GET_CALL_INFO } = useChat()

export default {
  props: {
    msgData: {
      type: Object,
      required: true,
    },
    senderId: {
      type: String,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  computed: {
    jsonMessage() {
      return JSON.parse(this.msgData.msg)
    },
    callInfo() {
      return GET_CALL_INFO(this.jsonMessage, this.self.uuid)
    },
  },
}
</script>
