import gql from 'graphql-tag'

export default {

  CREATE_SERVICE: gql`
    mutation createService(
    $input: ServiceUpdateInput!
    $projectUid:String!
        ){
        createService(
            input: $input
            projectUid:$projectUid
        ) {
            status
            message
        }
        }  
    `,

  UPDATE_SERVICE: gql`
   mutation updateService(
  $id: ID!
  $input: ServiceUpdateInput!
  $projectUid:String!
    ){
    updateService(
        id: $id
        input: $input
        projectUid: $projectUid
    ) {
        status
        message
    }
    }  
    `,

  DELETE_SERVICE: gql`
    mutation deleteService($id:ID!,    $projectUid:String!) {
    deleteService(
        id: $id
        projectUid:$projectUid
    ) {
        status
        message
    }
    }
`,

  CREATE_FAQ: gql`
mutation createFAQ(
  $input:FAQUpdateInput!
  $projectUid: String!
){
  createFAQ(
    input:$input
    projectUid:$projectUid
  )
  {
    status
    message
  }
}`,

  UPDATE_FAQ: gql`
mutation updateFAQ(
  $id:ID!
  $projectUid:String!
  $input:FAQUpdateInput!
){
  updateFAQ(
    id:$id
    projectUid:$projectUid
    input:$input
  )
  {
    status
    message
  }
}`,

  DELETE_FAQ: gql`
mutation deleteFAQ($id:ID!,$projectUid:String!){
  deleteFAQ(
    id:$id,
    projectUid:$projectUid
  ) {
    status
    message
  }
}
`,

  CREATE_TESTIMONIAL: gql`
mutation createTestimonial(
  $input: TestimonialUpdateInput!,
  $projectUid:String!
)
{
    createTestimonial(
    input:$input
    projectUid:$projectUid
  ) {
    status
    message
  }
}`,

  UPDATE_TESTIMONIAL: gql`
mutation updateTestimonial(
  $id:ID!
  $input: TestimonialUpdateInput!
  $projectUid:String!
)
{
    updateTestimonial(
    id:$id
    input:$input
    projectUid:$projectUid
  ) {
    status
    message
  }
}`,

  DELETE_TESTIMONIAL: gql`
mutation deleteTestimonial($id:ID!,$projectUid:String!) {
  deleteTestimonial(
    id:$id
    projectUid:$projectUid
  ) {
    status
    message
  }
}`,

  CREATE_WHOWEARE: gql`
mutation createWhoWeAre(
  $title:String!
  $projectUid:String!
){
    createWhoWeAre(
    title: $title
    projectUid:$projectUid
  ) {
    status
    message
  }
}`,

  UPDATE_WHOWEARE: gql`
mutation updateWhoWeAre(
  $id:ID!
  $projectUid:String!
  $title:String!
){
    updateWhoWeAre(
    id:$id,
    title: $title
    projectUid:$projectUid
  ) {
    status
    message
  }
}`,

  DELETE_WHOWEARE: gql`
mutation deleteWhoWeAre($id:ID!,$projectUid:String!){
    deleteWhoWeAre(
    id:$id,
    projectUid:$projectUid
  ) {
    status
    message
  }
}
`,

  CREATE_SLIDER: gql`
mutation createSlider(
$input: SliderInput!
$projectUid:String!
    ){
    createSlider(
        input: $input
        projectUid:$projectUid
    ) {
        status
        message
    }
    }  
`,

  UPDATE_SLIDER: gql`
mutation updateSlider(
$input: SliderInput!
$projectUid:String!
){
updateSlider(
    input: $input
    projectUid:$projectUid
) {
    status
    message
}
}  
`,

  DELETE_SLIDER: gql`
mutation deleteSlider($id:ID!,$projectUid:String!) {
deleteSlider(
    id: $id
    projectUid:$projectUid
) {
    status
    message
}
}
`,

  ADD_RESULT: gql`
mutation createResult(
$title: String!
$percentage:Int!
$projectUid:String!
    ){
    createResult(
        title: $title
        percentage: $percentage
        projectUid:$projectUid
    ) {
        status
        message
    }
    }  
`,

  UPDATE_RESULT: gql`
    mutation updateResult(
        $title: String!
        $percentage:Int!
        $id:ID!
        $projectUid:String!
    ){
    updateResult(
        title: $title,
        percentage:$percentage,
        id:$id
        projectUid:$projectUid
    ) {
        status
        message
    }
    }  
    `,

  DELETE_RESULT: gql`
    mutation deleteResult($id:ID!,$projectUid:String!) {
    deleteResult(
        id: $id
        projectUid:$projectUid
    ) {
        status
        message
    }
    }
    `,

  UPDATE_SECTION: gql`
    mutation updateCmsSection($id:ID!, $input:CMSSectionInput!, $projectUid:String!){
        updateCMSSection(
        id: $id,
        projectUid: $projectUid,
        input: $input
     ) {
         __typename
     }
    }
    `,

  CREATE_SCREENSHOT_TAB: gql`
  mutation createTab(
  $input: ScreenshotTabInput!
  $projectUid:String!
){
  createScreenshotTab(
    input:$input,
    projectUid:$projectUid
  ) {
    __typename
  }
}
`,

  UPDATE_SCREENSHOT_TAB: gql`
mutation updateScreenshotTab($id:ID!,$projectUid:String!,$input:ScreenshotTabInput!){
    updateScreenshotTab(id:$id,projectUid:$projectUid,input:$input){
        __typename
    }
}`,

  DELETE_SCREENSHOT_TAB: gql`
mutation deleteScreenshotTab(
  $id:ID!
  $projectUid:String!
){
  deleteScreenshotTab(
    id:$id
    projectUid:$projectUid
  ) {
    __typename
  }
} 
`,
}
