import useApollo from '@/plugins/graphql/useApollo'
import store from '@/store/app/project'

export const checkPermission = (slugs, callback) => {
  useApollo.users.checkUserPermission({
    slugs,
    project: store.state.selectedProject,
  }).then(response => {
    const assignedPermissions = {}
    response.data.me.permissions.data.forEach(permission => {
      assignedPermissions[permission.slug] = permission.allow
    })

    callback(assignedPermissions)
  })
}

export const projectSettings = (callback = null) => useApollo.company.getProjectSettings({
  project: store.state.selectedProject,
  type: ['CLOUDSETTING'],
}).then(response => {
  const settings = response.data.project?.settings?.[0]?.information
  if (callback) callback(settings)
  return settings
})
