import store from '@/store'
import { projectSettings } from '@/utils/permissions'

// eslint-disable-next-line consistent-return
export default function enabledProjectSetting({ to, next, router }) {
  const projectSetting = store.getters['project/GET_PROJECT_SETTINGS']
  if (Object.keys(projectSetting).length && projectSetting[to.meta.setting]) return next()

  projectSettings(settings => {
    if (settings[to.meta.setting]) return next()
    return router.push('error-404')
  })
}
