<template>
  <div
    data-component="PeerView"
    :class="audioVolume ? isMe ? 'me-border-speaker' : 'border-speaker' : 'border-default'"
  >
    <video
      ref="videoElem"
      class="video"
      :class="{
        'is-me': isMe,
        hidden: !videoVisible || !videoCanPlay,
        // 'network-error':
        //   videoVisible &&
        //   videoMultiLayer &&
        //   consumerCurrentSpatialLayer === null,
      }"
      autoPlay
      muted
      :controls="false"
    />

    <div
      v-if="!videoVisible || !videoCanPlay"
      class="centralize h-100"
    >
      <b-avatar
        :src="getAvatar(peerInfo.avatar)"
        :text="peer.displayName.charAt(0)"
        variant="primary"
        :size="isMe || sidebar ? 72 : 96"
      >
        <i
          v-if="peer.type === 'group'"
          class="fas fa-user-friends"
          :style="'font-size: 36px;'"
        />
      </b-avatar>

      <div
        v-if="videoVisible && videoScore < 5"
        class="centralize position-absolute"
      >
        <div class="spinner-container">
          <b-spinner />
        </div>
      </div>
    </div>

    <div
      class="name d-flex align-items-center w-100"
      :class="isMe || sidebar ? 'centralize' : 'justify-content-end pr-2'"
    >
      <feather-icon
        v-if="remoteAudioMute"
        class="mute-mic text-white mr-1"
        size="20"
        icon="MicOffIcon"
      />
      <p class="mb-0">
        {{ peer.displayName }}
        {{ isMe && state.peers && Object.values(state.peers).length ? '(You)' : '' }}
      </p>
    </div>

    <div
      v-if="videoVisible && !sidebar && !isMe"
      class="video-buttons"
    >
      <b-button
        v-b-tooltip.hover="'Picture-in-picture'"
        class="cursor-pointer p-0 mr-1"
        variant="link"
        @click="togglePIP"
      >
        <img
          class="picture-in-picture-icon"
          :src="require('@/assets/icons/picture-in-picture.png')"
          alt=""
          width="40"
          height="40"
        >
      </b-button>

      <b-button
        v-b-tooltip.hover="'Maximize'"
        class="cursor-pointer p-0"
        variant="link"
        @click="toggleFullScreen"
      >
        <feather-icon
          icon="MinimizeIcon"
          size="20"
        />
      </b-button>
    </div>

    <audio
      ref="audioElem"
      autoPlay
      muted
      :controls="false"
    />

    <canvas
      ref="canvas"
      class="face-detection"
      :class="{ 'is-me': isMe }"
    />

    <div class="volume-container justify-content-center align-items-center">
      <div
        class="bar"
        :class="`level${audioVolume}`"
      />
    </div>

    <div v-if="videoElemPaused">
      <div class="video-elem-paused" />
    </div>
  </div>
</template>

<script>
import { BAvatar, BSpinner, BButton } from 'bootstrap-vue'
import hark from 'hark'
import EventBus from '@/event-bus'
import PeerViewProps from './PeerViewProps'

export default {
  components: {
    BAvatar,
    BSpinner,
    BButton,
  },
  props: PeerViewProps,
  data() {
    return {
      audioVolume: 0, // Integer from 0 to 10.,
      showInfo: window.SHOW_INFO || false,
      videoResolutionWidth: null,
      videoResolutionHeight: null,
      videoCanPlay: false,
      videoElemPaused: false,
      maxSpatialLayer: null,

      hark: null,
      p_videoTrack: null,
      p_audioTrack: null,
      videoResolutionPeriodicTimer: null,
      faceDetectionRequestAnimationFrame: null,
    }
  },
  computed: {
    me() {
      return this.state.me
    },
    sortedAudioScores() {
      const score = this.audioScore
      const scores = Array.isArray(score) ? score : [score]

      return scores.sort((a, b) => {
        if (a.rid) return a.rid > b.rid ? 1 : -1
        return a.ssrc > b.ssrc ? 1 : -1
      })
    },
    sortedVideoScores() {
      const score = this.videoScore
      const scores = Array.isArray(score) ? score : [score]

      return scores.sort((a, b) => {
        if (a.rid) return a.rid > b.rid ? 1 : -1
        return a.ssrc > b.ssrc ? 1 : -1
      })
    },
    peerInfo() {
      return {
        ...(this.$store.state.project.projectUsers.find(user => user.uuid === this.peer.id)),
      }
    },
  },
  watch: {
    audioMuted() {
      if (this.$refs.audioElem) {
        this.$refs.audioElem.muted = this.isMe || this.audioMuted
      }
    },
    audioTrack() {
      // eslint-disable-next-line no-underscore-dangle
      this._updateTracks(this.audioTrack, this.videoTrack)
    },
    videoTrack() {
      // eslint-disable-next-line no-underscore-dangle
      this._updateTracks(this.audioTrack, this.videoTrack)
    },
    videoRtpParameters() {
      // eslint-disable-next-line no-underscore-dangle
      this._updateTracks(this.audioTrack, this.videoTrack)
    },
  },
  mounted() {
    // eslint-disable-next-line func-names
    this.$nextTick(() => {
      if (this.$refs.audioElem) {
        this.$refs.videoElem.muted = true
        this.$refs.audioElem.muted = this.isMe || this.audioMuted
      }

      this.setTracks(this.audioTrack, this.videoTrack)
    })
  },
  methods: {
    toggleFullScreen() {
      const videoEl = this.$refs.videoElem
      if (videoEl.requestFullscreen) videoEl.requestFullscreen()
      else if (videoEl.webkitRequestFullscreen) videoEl.webkitRequestFullscreen()
      else if (videoEl.msRequestFullscreen) videoEl.msRequestFullscreen()
    },
    togglePIP() {
      const videoEl = this.$refs.videoElem

      if (!document.pictureInPictureElement) {
        videoEl.requestPictureInPicture()
      } else document.exitPictureInPicture()
    },
    // eslint-disable-next-line no-underscore-dangle
    _updateTracks(audioTrack, videoTrack) {
      if (
        this.isMe
        && this.videoRtpParameters
        && this.maxSpatialLayer === null
      ) {
        this.maxSpatialLayer = this.videoRtpParameters.encodings.length - 1
      } else if (
        this.isMe
        && !this.videoRtpParameters
        && this.maxSpatialLayer !== null
      ) {
        this.maxSpatialLayer = null
      }

      this.setTracks(audioTrack, videoTrack)
    },
    setTracks(audioTrack, videoTrack) {
      if (this.p_audioTrack === audioTrack && this.p_videoTrack === videoTrack) { return }

      this.p_audioTrack = audioTrack
      this.p_videoTrack = videoTrack

      // eslint-disable-next-line no-underscore-dangle
      if (this._hark) {
        // eslint-disable-next-line no-underscore-dangle
        this._hark.stop()
      }

      // eslint-disable-next-line no-underscore-dangle
      this._stopVideoResolution()

      if (this.faceDetection) {
        // eslint-disable-next-line no-underscore-dangle
        this._stopFaceDetection()
      }

      const { audioElem, videoElem } = this.$refs

      if (audioTrack) {
        const stream = new MediaStream()

        stream.addTrack(audioTrack)
        audioElem.srcObject = stream

        audioElem
          .play()
          .catch(error => console.warn('audioElem.play() failed:%o', error))

        // eslint-disable-next-line no-underscore-dangle
        this._runHark(stream)
      } else {
        audioElem.srcObject = null
      }

      if (videoTrack) {
        const stream = new MediaStream()

        stream.addTrack(videoTrack)
        videoElem.srcObject = stream

        videoElem.oncanplay = () => {
          this.videoCanPlay = true
        }

        videoElem.onplay = () => {
          this.videoElemPaused = false

          audioElem
            .play()
            .catch(error => console.warn('audioElem.play() failed:%o', error))
        }

        videoElem.onpause = () => {
          this.videoElemPaused = true
        }

        videoElem
          .play()
          .catch(error => console.warn('videoElem.play() failed:%o', error))

        // eslint-disable-next-line no-underscore-dangle
        this._startVideoResolution()

        if (this.faceDetection) {
          // eslint-disable-next-line no-underscore-dangle
          this._startFaceDetection()
        }
      } else {
        videoElem.srcObject = null
      }
    },
    // eslint-disable-next-line no-underscore-dangle
    _printConsumerScore(id, score) {
      return `score:${score.score}, producerScore:${score.producerScore}`
    },
    // eslint-disable-next-line no-underscore-dangle
    _startVideoResolution() {
      const $this = this
      this.videoResolutionPeriodicTimer = setInterval(() => {
        const { videoElem } = $this.$refs

        if (
          videoElem !== undefined
          && (videoElem.videoWidth !== $this.videoResolutionWidth
            || videoElem.videoHeight !== $this.videoResolutionHeight)
        ) {
          $this.videoResolutionWidth = videoElem.videoWidth
          $this.videoResolutionHeight = videoElem.videoHeight
        }
      }, 500)
    },
    // eslint-disable-next-line no-underscore-dangle
    _runHark(stream) {
      if (!stream.getAudioTracks()[0]) { throw new Error('_runHark() | given stream has no audio track') }

      this.hark = hark(stream, { play: false })

      // eslint-disable-next-line no-unused-vars
      this.hark.on('volume_change', (dBs, threshold) => {
        // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
        //   Math.pow(10, dBs / 20)
        // However it does not produce a visually useful output, so let exagerate
        // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
        // minimize component renderings.
        let audioVolume = Math.round(10 ** (dBs / 85) * 10)

        if (audioVolume === 1) audioVolume = 0

        if (audioVolume !== this.audioVolume) {
          this.audioVolume = audioVolume
        }
        if (!this.isMe) EventBus.$emit('audio-volume', this.audioVolume)
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    _stopFaceDetection() {
      cancelAnimationFrame(this.faceDetectionRequestAnimationFrame)

      const { canvas } = this.$refs

      canvas.width = 0
      canvas.height = 0
    },
    // eslint-disable-next-line no-underscore-dangle
    _stopVideoResolution() {
      clearInterval(this.videoResolutionPeriodicTimer)

      this.videoResolutionWidth = null
      this.videoResolutionHeight = null
    },
    onClickDownSpatialLayer() {
      const newMaxSpatialLayer = this.maxSpatialLayer - 1

      this.$emit('onChangeMaxSendingSpatialLayer', newMaxSpatialLayer)
      this.maxSpatialLayer = newMaxSpatialLayer
    },

    onClickUpSpatialLayer() {
      const newMaxSpatialLayer = this.maxSpatialLayer + 1

      this.$emit('onChangeMaxSendingSpatialLayer', newMaxSpatialLayer)
      this.maxSpatialLayer = newMaxSpatialLayer
    },

    onClickDownVideoPreferredLayers() {
      let newPreferredSpatialLayer = this.consumerPreferredSpatialLayer
      let newPreferredTemporalLayer

      if (this.consumerPreferredTemporalLayer > 0) {
        newPreferredTemporalLayer = this.consumerPreferredTemporalLayer - 1
      } else {
        if (this.consumerPreferredSpatialLayer > 0) { newPreferredSpatialLayer = this.consumerPreferredSpatialLayer - 1 } else newPreferredSpatialLayer = this.consumerSpatialLayers - 1

        newPreferredTemporalLayer = this.consumerTemporalLayers - 1
      }

      this.$emit(
        'onChangeVideoPreferredLayers',
        newPreferredSpatialLayer,
        newPreferredTemporalLayer,
      )
    },

    onClickUpVideoPreferredLayers() {
      let newPreferredSpatialLayer = this.consumerPreferredSpatialLayer
      let newPreferredTemporalLayer

      if (
        this.consumerPreferredTemporalLayer
        < this.consumerTemporalLayers - 1
      ) {
        newPreferredTemporalLayer = this.consumerPreferredTemporalLayer + 1
      } else {
        if (this.consumerPreferredSpatialLayer < this.consumerSpatialLayers - 1) { newPreferredSpatialLayer = this.consumerPreferredSpatialLayer + 1 } else newPreferredSpatialLayer = 0

        newPreferredTemporalLayer = 0
      }

      this.$emit(
        'onChangeVideoPreferredLayers',
        newPreferredSpatialLayer,
        newPreferredTemporalLayer,
      )
    },

    onStatsClick() {
      if (this.isMe) {
        this.$emit('onStatsClick', this.me.id)
      } else {
        this.$emit('onStatsClick', this.peer.id)
      }
    },
    onClickRequestKeyframe() {
      this.$emit('onRequestKeyFrame')
    },
    onChangeVideoPriority(priority) {
      this.$emit('onChangeVideoPriority', priority)
    },
  },
}
</script>
