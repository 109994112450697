<template>
  <div>
    <div class="message-render d-flex align-items-center w-100">
      <div
        class="d-flex flex-column position-relative"
        :class="{ 'w-100': messageType === 'groupaction' }"
      >
        <component
          :is="getComponent"
          :msg-data="msgData"
          :attachment="msgData.attachment ? msgData.attachment : attachment"
          :sender-id="senderId"
          :contact-id="contactId"
          :message-type="messageType"
          @show-image="$emit('show-image', msgData.messageUid)"
        />
        <!-- Text Messages -->
        <p
          v-if="messageType === 'text'"
          :id="`chat-message-${msgData.messageUid}`"
          class="chat-content mb-0"
          :class="{ 'mw-84': senderId !== self.uuid }"
          v-html="handleText(msgData.msg)"
        />
        <!-- Reacts -->
        <div
          v-if="Object.keys(groupedReacts).length"
          class="d-flex reaction"
        >
          <div
            v-for="(reacts) in groupedReacts"
            :id="`react-${msgData.messageUid}-${reacts[0].reaction}`"
            :key="reacts[0].reaction"
            class="react-btn cursor-pointer centralize"
            :class="reacts.length > 1 ? 'react-count-btn' : 'rounded-circle'"
            @click="$emit('send-react', msgData.messageUid, msgData.reacts, reacts[0].reaction, 'unsend')"
          >
            <div class="d-flex">
              <h5
                class="mb-0"
                v-html="getReaction(reacts[0].reaction)"
              />
              <small
                v-if="reacts.length > 1"
                class="react-count"
              >{{ reacts.length }}</small>
            </div>
            <!-- React Info Popover -->
            <b-popover
              :target="`react-${msgData.messageUid}-${reacts[0].reaction}`"
              triggers="hover"
            >
              <div class="react-popover">
                <div class="emote-wrapper centralize">
                  <p
                    class="emote mb-0"
                    v-html="getReaction(reacts[0].reaction)"
                  />
                </div>
                <p
                  v-for="react in reacts"
                  :key="`${msgData.messageUid}-${react.reactedBy}`"
                  class="mt-1 mb-0"
                >
                  <b-avatar
                    :src="getAvatar(getUser(react.reactedBy).avatar)"
                  />
                  <span class="ml-1">{{ getUser(react.reactedBy).name }}</span>
                </p>
              </div>
            </b-popover>
          </div>
        </div>

        <!-- React Options -->
        <div
          v-if="senderId !== self.uuid && reactIndex === msgData.messageUid && msgData.messageType !== 'MEDIACALL' && msgData.messageType !== 'GROUPACTION'"
          class="react-options position-absolute"
        >
          <!-- Smiley Button -->
          <feather-icon
            id="reacts"
            icon="SmileIcon"
            size="18"
            class="cursor-pointer mb-1"
            @click="$emit('showing-react-options', true)"
          />
          <!-- React Options Popover -->
          <b-popover
            target="reacts"
            custom-class="react-popover"
            placement="right"
          >
            <div class="d-flex">
              <b-button
                v-for="react in reactGroup"
                :key="react.value"
                v-b-tooltip.hover="CAPITALIZE(react.text)"
                variant="link"
                class="p-0"
              >
                <div
                  class="h1 mr-1 mb-0"
                  :class="{ reacted: isReacted(msgData.reacts) === react.text }"
                  @click="$emit('send-react', msgData.messageUid, msgData.reacts, react.text)"
                  v-html="react.value"
                />
              </b-button>
            </div>
          </b-popover>
        </div>
      </div>

      <!-- Download Button for Files -->
      <b-button
        v-if="showDownloadButton"
        v-b-tooltip.hover.bottom="'Download'"
        variant="link"
        size="sm"
        class="cursor-pointer mt-2 p-06"
        @click="getChatResource('download')"
      >
        <feather-icon
          icon="DownloadIcon"
          size="24"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BSkeleton, BSkeletonWrapper, BPopover, VBTooltip,
} from 'bootstrap-vue'
import jwt from '@/auth/jwt/useJwt'
import { reacts as reactGroup } from '@/const/common'
import AudioPlayer from './MessageTypes/AudioPlayer.vue'
import VideoPlayer from './MessageTypes/VideoPlayer.vue'
import MediaCall from './MessageTypes/MediaCall.vue'
import ImageMessage from './MessageTypes/ImageMessage.vue'
import Files from './MessageTypes/Files.vue'
import Sticker from './MessageTypes/Sticker.vue'
import GroupAction from './MessageTypes/GroupAction.vue'
import useChat from '../useChat'

const { GET_MSG_TYPE } = useChat()

export default {
  components: {
    BAvatar,
    BButton,
    BSkeleton,
    BSkeletonWrapper,
    BPopover,
    AudioPlayer,
    VideoPlayer,
    MediaCall,
    ImageMessage,
    Files,
    Sticker,
    GroupAction,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    msgData: {
      type: [Object, String],
      required: true,
    },
    self: {
      type: Object,
      required: true,
    },
    senderId: {
      type: String,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
    reactIndex: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      reactGroup,
      attachment: null,
    }
  },
  computed: {
    messageType() {
      return GET_MSG_TYPE(this.msgData.messageType, this.msgData.msg)
    },
    showDownloadButton() {
      return (this.msgData.messageType === 'ATTACHMENT' && this.messageType !== 'image')
    },
    groupedReacts() {
      if (this.msgData.reacts) {
        return this.GROUP_BY(this.msgData.reacts, 'reaction')
      }
      return {}
    },
    getComponent() {
      switch (this.messageType) {
        case 'text':
          return ''
        case 'audio':
          return AudioPlayer
        case 'video':
          return VideoPlayer
        case 'mediacall':
          return MediaCall
        case 'image':
          return ImageMessage
        case 'sticker':
          return Sticker
        case 'groupaction':
          return GroupAction
        default:
          return Files
      }
    },
  },
  mounted() {
    if (!this.msgData.attachment && (this.messageType === 'image' || this.messageType === 'video')) this.getChatResource()
  },
  methods: {
    getChatResource(action = '') {
      jwt.getChatResource(this.msgData.msg.key).then(async response => {
        this.attachment = response.data.url
        this.$emit('store-resource', this.attachment)
        if (action === 'download') {
          window.open(this.attachment, '_self')
        }
      })
    },
    getReaction(reaction) {
      return reactGroup.find(react => react.text === reaction)?.value
    },
    getUser(userUid) {
      const user = this.$store.state.project.projectUsers.find(u => u.uuid === userUid)
      return {
        name: user.uuid === this.self.uuid ? `${user.name} (You)` : user.name,
        avatar: user.avatar,
      }
    },
    isReacted(reacts) {
      if (reacts && reacts.length) {
        const reaction = reacts.find(react => react.reactedBy === this.self.uuid)
        if (reaction) return reaction.reaction
      }
      return null
    },
    handleText(text) {
      const urlRegex = /((https?|ftp|mailto):\/\/\S+)/g

      const textWithStyledLinks = text.replace(urlRegex, url => `<a class="chat-link" href="${url}" target="_blank">${url}</a>`)

      return textWithStyledLinks
    },
  },
}
</script>
