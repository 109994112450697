import apolloProvider from '@/libs/vue-apollo'
import mutation from './mutation'
import queries from './queries'

export default {
  addCountries(input) {
    return apolloProvider.mutate({
      mutation: mutation.REGISTER_COUNTRIES,
      variables: {
        input,
      },
    })
  },
  getCountries() {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_COUNTRIES,
    })
  },
  addLanguages(input) {
    return apolloProvider.mutate({
      mutation: mutation.REGISTER_LANGUAGE,
      variables: {
        input,
      },
    })
  },
  getLanguages() {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_LANGUAGES,
    })
  },
}
