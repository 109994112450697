import { checkPermission } from '@/utils/permissions'
import store from '@/store'

// eslint-disable-next-line consistent-return
export default function hasPermission({ to, next, router }) {
  if (store.getters['permissions/getPermissionsCount'](to.meta.permissions)) return next()

  checkPermission(to.meta.permissions, permissions => {
    let allowed = false
    to.meta.permissions.forEach(permission => {
      if (permissions[permission] === true) allowed = true
    })
    if (allowed) return next()
    return router.push('error-404')
  })
}
