/* eslint-disable import/extensions */
import isAuthenticated from '@/middlewares/isAuthenticated'
import onlyAdmin from '@/middlewares/onlyAdmin'
export default [
  {
    path: '/job-basic-setup',
    name: 'job-basic-setup',
    meta: {
      middleware: [isAuthenticated, onlyAdmin],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Basic',
          active: true,
        },
      ],
    },
    component: () => import('@/views/job-settings/category-setting'),
  },
  {
    path: '/job-category',
    name: 'job-category',
    meta: {
      middleware: [isAuthenticated, onlyAdmin],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Productive Rating',
          active: true,
        },
      ],
    },
    component: () => import(`@/views/JobPortal/job-category/index.vue`),
  },
]
