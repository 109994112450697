/* eslint-disable import/extensions */
import isAuthenticated from '@/middlewares/isAuthenticated'
import hasPermission from '@/middlewares/hasPermission'

export default [
  {
    path: '/crm',
    name: 'crm',
    meta: {
      middleware: [isAuthenticated, hasPermission],
      permissions: ['leads.create', 'leads.follow', 'leads.close'],
      breadcrumb: [
        {
          text: 'CRM',
          active: true,
        },
      ],
    },
    component: () => import('@/views/crm/Index.vue'),
  },
]
