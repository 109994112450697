<template>
  <b-modal
    id="rename-group-modal"
    centered
    title="Rename Conversation"
    ok-title="Rename"
    no-close-on-backdrop
    @ok="renameGroup"
  >
    <b-input-group>
      <template #prepend>
        <b-input-group-text>
          <feather-icon
            icon="Edit3Icon"
            class="text-primary"
          />
        </b-input-group-text>

      </template>
      <b-form-input
        v-model="form.name"
        placeholder="Conversation Name or Leave Empty for Default"
        autofocus
        @keydown.enter="renameGroup"
      />
    </b-input-group>
  </b-modal>
</template>

<script>
import {
  BModal, BInputGroup, BInputGroupText, BFormInput,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupText,
    FeatherIcon,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        roomUid: null,
        name: '',
      },
    }
  },
  watch: {
    group: {
      handler(val) {
        if (val.name) {
          this.form.name = val.name
          this.form.roomUid = val.roomUid
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    renameGroup() {
      this.$chatService.renameRoom(this.form.roomUid, this.form.name)
      this.$bvModal.hide('rename-group-modal')
    },
  },
}
</script>
