import gql from 'graphql-tag'

export default {
  REGISTER_COUNTRIES: gql`
    mutation RegisterCountries($input: [CountryInput!]!) {
            registerCountries(
                input: $input
            ) {
                status
                message
            }
        }
    `,
  REGISTER_LANGUAGE: gql`
      mutation RegisterLanguages($input: [LanguageInput!]!) {
        registerLanguages(
                  input: $input
              ) {
                  status
                  message
              }
          }
      `,

}
