function getSticker(category, sticker) {
  return require(`@/assets/stickers/${category}/${sticker}`)
}

export default [
  {
    category: 'communications',
    stickers: [
      {
        code: 'cool',
        sticker: getSticker('communications', 'cool.png'),
      },
      {
        code: 'hello',
        sticker: getSticker('communications', 'hello.png'),
      },
      {
        code: 'like',
        sticker: getSticker('communications', 'like.png'),
      },
      {
        code: 'yes',
        sticker: getSticker('communications', 'yes.png'),
      },
      {
        code: 'lol',
        sticker: getSticker('communications', 'lol.png'),
      },
      {
        code: 'have-a-good-day',
        sticker: getSticker('communications', 'have-a-good-day.png'),
      },
      {
        code: 'i-miss-you',
        sticker: getSticker('communications', 'miss-you.png'),
      },
      {
        code: 'ok',
        sticker: getSticker('communications', 'ok.png'),
      },
      {
        code: 'omg',
        sticker: getSticker('communications', 'omg.png'),
      },
      {
        code: 'thanks',
        sticker: getSticker('communications', 'thanks.png'),
      },
      {
        code: 'no',
        sticker: getSticker('communications', 'no.png'),
      },
      {
        code: 'idea',
        sticker: getSticker('communications', 'idea.png'),
      },
      {
        code: 'welcome',
        sticker: getSticker('communications', 'welcome.png'),
      },
    ],
  },
  {
    category: 'worker',
    stickers: [
      {
        code: 'break-time',
        sticker: getSticker('worker', 'break-time.png'),
      },
      {
        code: 'angry',
        sticker: getSticker('worker', 'angry.png'),
      },
      {
        code: 'headache',
        sticker: getSticker('worker', 'headache.png'),
      },
      {
        code: 'mad',
        sticker: getSticker('worker', 'mad.png'),
      },
      {
        code: 'no-money',
        sticker: getSticker('worker', 'no-money.png'),
      },
      {
        code: 'relieve',
        sticker: getSticker('worker', 'relieve.png'),
      },
      {
        code: 'sleeping',
        sticker: getSticker('worker', 'sleeping.png'),
      },
      {
        code: 'surprised',
        sticker: getSticker('worker', 'surprised.png'),
      },
      {
        code: 'vacation',
        sticker: getSticker('worker', 'vacation.png'),
      },
      {
        code: 'win',
        sticker: getSticker('worker', 'win.png'),
      },
      {
        code: 'lunch-break',
        sticker: getSticker('worker', 'lunch-break.png'),
      },
      {
        code: 'throwing',
        sticker: getSticker('worker', 'throwing.png'),
      },
      {
        code: 'mocking',
        sticker: getSticker('worker', 'mocking.png'),
      },
      {
        code: 'selfie',
        sticker: getSticker('worker', 'selfie.png'),
      },
      {
        code: 'thinking',
        sticker: getSticker('worker', 'thinking.png'),
      },
      {
        code: 'worrying',
        sticker: getSticker('worker', 'worrying.png'),
      },
      {
        code: 'hurry',
        sticker: getSticker('worker', 'hurry.png'),
      },
    ],
  },
  {
    category: 'monkey',
    stickers: [
      {
        code: 'hello',
        sticker: getSticker('monkey', 'hello.png'),
      },
      {
        code: 'good',
        sticker: getSticker('monkey', 'good.png'),
      },
      {
        code: 'lets-go',
        sticker: getSticker('monkey', 'lets-go.png'),
      },
      {
        code: 'banana',
        sticker: getSticker('monkey', 'banana.png'),
      },
      {
        code: 'celebration',
        sticker: getSticker('monkey', 'celebration.png'),
      },
      {
        code: 'like',
        sticker: getSticker('monkey', 'like.png'),
      },
      {
        code: 'no-answer',
        sticker: getSticker('monkey', 'no-answer.png'),
      },
      {
        code: 'playing',
        sticker: getSticker('monkey', 'playing.png'),
      },
    ],
  },
]
