<template>
  <div data-component="Peers">
    <transition appear>
      <slot />
    </transition>
    <div
      :class="{'d-flex flex-wrap': !sidebar}"
      class="w-100"
    >
      <transition
        v-for="peer in peers"
        :key="peer.id"
        appear
      >
        <div
          class="peer-container video-box"
          :class="{ 'active-speaker': peer.id === activeSpeakerId }"
          :style="sidebar ? peerStyle : 'flex: 50%'"
        >
          <Peer
            :peer="peer"
            :room-client="roomClient"
            :state="state"
            :sidebar="sidebar"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Peer from './Peer.vue'

export default {
  components: { Peer },
  props: {
    roomClient: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      default: () => {},
    },
    peerStyle: {
      type: String,
      default: () => '',
    },
    videoPeers: {
      type: Array,
      default: () => null,
    },
    sidebar: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    peers() {
      if (this.sidebar) return this.videoPeers
      return this.videoPeers?.length ? this.videoPeers : this.state.peers
    },
    activeSpeakerId() {
      return this.state.room.activeSpeakerId
    },
  },
}
</script>
