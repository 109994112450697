import gql from 'graphql-tag'

export default {
  GET_JOB_CATEGORY: gql`
    query jobCategory {
      jobCategories {
        data {
          displayName
          subCategories {
            displayName
            slug
            description
          }
        }
      }
    }
  `,
}
