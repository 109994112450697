import apolloProvider from '@/libs/vue-apollo'
import commonMixin from '@/utils/common'
import mutation from './mutation'
import queries from './queries'

export default {
  getUserActivities(data) {
    return apolloProvider.query({
      query: queries.MY_SCREEN_CASTS,
      fetchPolicy: 'no-cache',
      variables: {
        uuid: data.userId,
        first: data.first ? data.first : -1,
        ...data,
        startTime: data.startTime,
        endTime: data.endTime,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  getDurations(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_DURATION,
      variables: {
        ...data,
        startTime: data.startTime,
        endTime: data.endTime,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  getActivityByType(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_ACTIVITY_BY_TYPE,
      variables: {
        ...data,
        startTime: data.startTime,
        endTime: data.endTime,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  getDailyActivities(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_DAILY_ACTIVITIES,
      variables: {
        ...data,
        startTime: data.startTime,
        endTime: data.endTime,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  getAllDailyActivities(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_ALL_DAILY_ACTIVITIES,
      variables: {
        ...data,
        startTime: data.startTime,
        endTime: data.endTime,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  getIdleTime(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_IDLE_TIME,
      variables: {
        ...data,
        startTime: data.startTime,
        endTime: data.endTime,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },

  getWeeklyTime(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_WEEKLY_TIMELINE,
      variables: {
        ...data,
        startTime: data.startTime,
        endTime: data.endTime,
        timezone: commonMixin.getSavedTimezoneOffset(),
        ...commonMixin.GET_CURRENT_START_END_TIME(),
      },
    })
  },
  getWorkPattern(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_WORK_PATTERN,
      variables: {
        ...data,
        timezone: commonMixin.getSavedTimezoneOffset(),
        startTime: data.startTime,
        endTime: data.endTime,
      },
    })
  },
  updateManualTime(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_MANUAL_TIME,
      variables: {
        ...data,
        startTime: commonMixin.toUTC(data.startTime),
        endTime: commonMixin.toUTC(data.endTime),
      },
    })
  },
  destroyActivity(data) {
    return apolloProvider.mutate({
      mutation: mutation.DESTROY_ACTIVITY,
      variables: {
        ...data,
      },
    })
  },
  postMemo(data) {
    return apolloProvider.mutate({
      mutation: mutation.POST_MEMO,
      variables: {
        ...data,
      },
    })
  },

  getPendingManualTime(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_PENDING_MANUAL_TIME,
      variables: {
        ...data,
      },
    })
  },
  updatePendingManualTime(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_PENDING_TIME,
      variables: {
        ...data,
      },
    })
  },
  destroyManualTime(data) {
    return apolloProvider.mutate({
      mutation: mutation.DESTROY_MANUAL_TIME,
      variables: {
        ...data,
      },
    })
  },
}
