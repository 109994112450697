import gql from 'graphql-tag'

export default {
  GET_REGIONS: gql`
    query getRegions(
      $first: Int,
      $page: Int,
      $id: ID,
      $keyword: String,
    ) {
      regions(first: $first, page: $page, id: $id, keyword: $keyword){
        total
        data {
          id
          country
          city
          coverageCategories {
            total
            data {
              id
              name
            }
          }
          coverages{
            total
            data {
              id
              minAge
              maxAge
              low
              mid
              high
              category{
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  GET_ALL_COVERAGE_TYPES: gql`
    query getCoverageTypes{
      coverageTypes {
        id
        name
        coverages {
          total
        }
        regions {
          total
        }
      }
    }
  `,
  GET_ALL_EMPLOYEES: gql`
    query getAllEmployees(
      $companyId: ID
      $first: Int
      $page: Int
    ) {
      me{
        tempCompanies(id: $companyId){
          id
          name
          employees(
            first: $first
            page: $page
          ){
            total
            data {
              id,
              uuid,
              name,
              age,
              gender,
              salary,
              employeeType,
              workModality,
              coverageQuality,
              operationType,
              isCurrent,
              isRecommended,
              region{
                id
                country
                city
              }
              dependents{
                data{
                  id
                  name
                  age
                  gender
                  relationship
                  coverageQuality
                  contributions: employerContributions{
                    id
                    contribution
                    coverageCategory{
                      id
                      name
                    }
                  }
                }
              }
              companies{
                data{
                  id
                  name
                }
              }
              contributions: employerContributions{
                id
                contribution
                coverageCategory{
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_COVERAGES_BY_CATEGORY_IN_REGION: gql`
    query getCoveragesByCategoryInRegion(
      $regionId: ID
      $coverageCategoryId: ID
    ){
      regions(id: $regionId) {
        data{
          coverageCategories(categoryId: $coverageCategoryId){
            data{
              id
              name
              coverages(regionId: $regionId){
                total
                data{
                  id
                  minAge
                  maxAge
                  low
                  mid
                  high
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_ONLY_REGIONS: gql`    
    query getOnlyRegions(
      $first: Int
      $keyword: String
    ) {
      regions(first: $first, keyword: $keyword) {
        data{
          id
          country
          city
        }
      }
    }      
  `,
  GET_SYSTEM_USERS: gql`
    query getSystemUsers($first: Int, $keyword: [String!], $company: String!) {
      company(uuid: $company) {
        users(user: {searchKey: NAME, keyword: $keyword}, first: $first) {
          data {
            uuid
            name
            isAdmin
            companyRoles(
              company:{searchKey:UUID, keyword:[$company]}
            ){
              data{
                role{
                  displayName
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_MY_COMPANIES: gql`
    query getMyCompanies {
      me {
        tempCompanies {
          id
          name
        }
      }
    }
  `,
}
