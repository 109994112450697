import { getUserData } from '@/auth/utils'

export const timeZones = [
  {
    title: 'South Korea',
    value: 'Asia/Seoul',
  },
  {
    title: 'Kathmandu',
    value: 'Asia/Kathmandu',
  },
]
const prefix = process.env.VUE_APP_FCM_PREFIX
export const TOPICS = [
  `${prefix}.workzone`,
  `${prefix}.${getUserData()?.uuid}.avatarChange`,
  `${prefix}.${getUserData()?.uuid}.limitChange`,
  `${prefix}.${getUserData()?.uuid}.notificationReceived`,
  `${prefix}.${getUserData()?.uuid}.profileUpdated`,
  `${prefix}.${getUserData()?.uuid}.permissionUpdated`,
  `${prefix}.${getUserData()?.uuid}.userStatusInProjectChanged`,
  `${prefix}.${localStorage.getItem('selected_company')}.timezonesUpdated`,
]

export const leadStatus = [
  { text: 'New', value: 'NEWLEAD' },
  { text: 'In Progress', value: 'INPROGRESS' },
  { text: 'No Answer', value: 'NOANSWER' },
  { text: 'Waiting Closure', value: 'WAITINGCLOSURE' },
  { text: 'Signed', value: 'SIGNED' },
  { text: 'Declined', value: 'DECLINED' },
]

export const paymentReasons = [{
  reason: 'wages_salary',
  label: 'Salary',
},
{
  reason: 'donation_charitable_contribution',
  label: 'Donation (Charitable Contribution)',
},
{
  reason: 'personal_remittance',
  label: 'Personal Remittance',
},
{
  reason: 'transfer_to_own_account',
  label: 'Transfer to Own Account',
},
{
  reason: 'pension',
  label: 'Pension',
},
{
  reason: 'family_support',
  label: 'Family Support',
},
{
  reason: 'living_expenses',
  label: 'Living Expenses',
},
{
  reason: 'education_training',
  label: 'Education Training',
},
{
  reason: 'travel',
  label: 'Travel',
},
{
  reason: 'investment_proceeds',
  label: 'Investment Proceeds',
},
{
  reason: 'investment_capital',
  label: 'Investment Capital',
},
{
  reason: 'loan_credit_repayment',
  label: 'Loan Credit Repayment',
},
{
  reason: 'taxes',
  label: 'Taxes',
},
{
  reason: 'goods_purchased',
  label: 'Goods Purchased',
},
{
  reason: 'business_expenses',
  label: 'Business Expenses',
},
{
  reason: 'medical_services',
  label: 'Medical Services',
},
{
  reason: 'professional_business_services',
  label: 'Professional Business Services',
},
{
  reason: 'technical_services',
  label: 'Technical Services',
},
{
  reason: 'other_services',
  label: 'Other Services',
},
{
  reason: 'construction',
  label: 'Construction',
},
{
  reason: 'freight',
  label: 'Freight',
},
{
  reason: 'real_estate',
  label: 'Real Estate',
},
{
  reason: 'audio_visual_services',
  label: 'Audio Visual Services',
}]

export const reacts = [
  { text: 'like', value: '&#128077;' },
  { text: 'love', value: '&#10084;&#65039;' },
  { text: 'haha', value: '&#128514;' },
  { text: 'wow', value: '&#128562;' },
  { text: 'sad', value: '&#128577;' },
  { text: 'cry', value: '&#128557;' },
  { text: 'angry', value: '&#128545;' },
]

export const getStatus = status => leadStatus.find(option => option.value === status).text

export const mapPermissionSlug = {
  'timezones.update': 'setTimezonesEditPermission',
  'permissions.manage': 'setPermissionsManagePermission',
  'cms.update': 'setCMSEditPermission',
  'productivity.update': 'setProductivityEditPermission',
  'company.edit': 'setCompanyEditPermission',
  'leads.create': 'setCreateLeadsPermission',
  'leads.follow': 'setFollowLeadsPermission',
  'leads.close': 'setCloseLeadsPermission',
  'project.manageusers': 'setManageUserPermission',
}
