import isAuthenticated from '@/middlewares/isAuthenticated'
import isNotAuthenticated from '@/middlewares/isNotAuthenticated'
import hasPermission from '@/middlewares/hasPermission'
import enabledProjectSetting from '@/middlewares/enabledProjectSetting'

export default [
  {
    path: '/',
    name: 'splash',
    component: () => import('@/views/NewSplashScreen.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      layout: 'full',
    },
  },

  {
    path: '/cms',
    name: 'cms',
    component: () => import('@/views/NewSplashScreen.vue'),
    meta: {
      middleware: [hasPermission],
      layout: 'full',
      permissions: ['cms.update'],
    },
  },

  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      middleware: [isAuthenticated],
      pageTitle: 'Home',
      enableDropdown: false,
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register/index.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/views/Register/verify.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: () => import('@/views/Register/invitation.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      layout: 'full',
    },
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: () => import('@/views/downloads/index.vue'),
    meta: {
      middleware: [isAuthenticated],
    },
  },

  {
    path: '/gec/callback',
    name: 'gec',
    component: () => import('@/views/Auth/Oauth2Verification.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/gecBearerToken/callback',
    name: 'gecBearerToken',
    component: () => import('@/views/Auth/Oauth2Verification.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/accounts',
    name: 'wallet',
    component: () => import('@/views/Wallet/Index.vue'),
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/:userType/statement',
    name: 'wallet-statement',
    component: () => import('@/views/Wallet/components/Statement.vue'),
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
        {
          text: 'Statement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/:userType',
    name: 'wallet-accounts',
    component: () => import('@/views/Wallet/components/Wallets.vue'),
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
        {
          text: 'Your Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/:userType/scheduled-transfers',
    name: 'scheduled-transfers',
    component: () => import('@/views/Wallet/components/ScheduledTransfers.vue'),
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
        {
          text: 'Scheduled Transfers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pay/stripe/success',
    name: 'stripe-success',
    component: () => import('@/views/common/stripe/success.vue'),
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: '/pay/stripe/cancel',
    name: 'stripe-cancel',
    component: () => import('@/views/common/stripe/cancel.vue'),
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: '/verifyInvoice',
    name: 'invoice-verification',
    component: () => import('@/views/Auth/VerifyInvoiceAuth.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      middleware: [isAuthenticated],
    },
  },
  {
    path: '/processWalletShare',
    name: 'wallet-share-process',
    component: () => import('@/views/Wallet/components/WalletActions/WalletShareProcess.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/file-manager',
    name: 'file-manager',
    component: () => import('@/views/file-manager/Index.vue'),
    meta: {
      middleware: [isAuthenticated, enabledProjectSetting],
      setting: 'enableCloudService',
    },
  },
]
