// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn } from '@/auth/utils'
import store from '../store/index'

export default function onlyAdmin({ next, router }) {
  if (!isUserLoggedIn() || store.getters['project/USER_MENU'] !== 'admin') {
    return router.push('/error-404')
  }

  return next()
}
