import helper from '@/utils/common'

export default function useChat() {
  const resolveAvatarBadgeVariant = status => {
    if (status === 'online') return 'success'
    if (status === 'busy') return 'danger'
    if (status === 'away') return 'warning'
    return 'secondary'
  }

  const mapChat = (data, contacts, self) => (
    data.messages
      .map(
        ({
          sentAt,
          messageUid,
          messageType,
          messageContent,
          reacts,
          ...message
        }) => ({
          message:
              messageType === 'ATTACHMENT'
                ? JSON.parse(messageContent)
                : messageContent,
          messageUid,
          messageType,
          time: sentAt,
          deliveredAt: message.deliveries.find(
            delivery => delivery.deliveredTo === self.uuid,
          )?.deliveredAt,
          seenAt: message.views.find(view => view.viewedBy === self.uuid)
            ?.viewedAt,
          seenBy: message.views
            .filter(view => view.viewedBy !== self.uuid)
            .map(
              view => contacts.value.find(contact => contact.id === view.viewedBy)
                ?.name,
            ),
          seenByMe: !!message.views.find(
            view => view.viewedBy === self.uuid,
          ),
          senderId: message.senderUid,
          delivered: 1,
          seen: 1,
          reacts,
        }),
      )
      .reverse()
  )

  const createGroupName = group => {
    if (group.participants.length > 1) {
      const first2Participants = group.participants
        .slice(0, 2)
        .map(participant => participant.name)

      const remainingUsers = group.participants.length - 1
      return `${first2Participants.join(', ')}${
        remainingUsers
          ? ` and ${remainingUsers} other${remainingUsers > 1 ? 's' : ''}`
          : ''
      }`
    }
    return `${group.participants[0].name} and You`
  }

  const GET_CALL_ERRORS = (error, user, contactType = null) => {
    switch (error) {
      case 'E_PEER_BUSY':
        return `Sorry, ${user} is currently occupied. Please try again later.`
      case 'E_ALREADY_IN_ROOM':
        return 'Sorry, you are currently active in another conversation.'
      case 'E_NO_ANSWER':
        return contactType === 'group'
          ? `Sorry, no one in ${user} answered your call.`
          : `Sorry, ${user} didn't answer your call.`
      default:
        return 'Unknown error occurred!'
    }
  }

  const GET_MSG_TYPE = (msgType, msg, key = 'mime') => {
    if (msgType === 'ATTACHMENT') {
      if (msg[key]) {
        if (msg[key].includes('image')) return 'image'
        if (msg[key].includes('pdf')) return 'pdf'
        if (msg[key].includes('csv')) return 'csv'
        if (msg[key].includes('word')) return 'word document'
        if (msg[key].includes('spreadsheet')) return 'spreadsheet'
        if (msg[key].includes('powerpoint')) return 'powerpoint'
        if (msg[key].includes('audio')) return 'audio'
        if (msg[key].includes('video')) return 'video'
        return 'file'
      }
    }
    return msgType.toLowerCase()
  }

  const GET_FILE_ICON = type => {
    if (type.includes('image')) return 'fa-image'
    if (type.includes('pdf')) return 'fa-file-pdf'
    if (type.includes('csv')) return 'fa-file-csv'
    if (type.includes('word')) return 'fa-file-word'
    if (type.includes('spreadsheet')) return 'fa-file-excel'
    if (type.includes('powerpoint')) return 'fa-file-powerpoint'
    if (type.includes('audio')) return 'fa-file-audio'
    if (type.includes('video')) return 'fa-file-video'
    return 'fa-file-alt'
  }

  const GET_CALL_INFO = (callData, self) => {
    // Missed Call
    if (callData.type === 'MISSED') {
      if (callData.caller === self) {
        return {
          icon: 'PhoneOutgoingIcon',
          text: 'Not Answered',
          iconColorClass: 'text-danger bg-light-danger',
          sidebarIconColorClass: 'text-danger',
        }
      }
      return {
        icon: 'PhoneMissedIcon',
        text: 'Missed Call',
        iconColorClass: 'text-danger bg-light-danger',
        sidebarIconColorClass: 'text-danger',
      }
    }

    // Answered Call
    if (callData.type === 'ANSWERED') {
      if (callData.caller === self) {
        return {
          icon: 'PhoneOutgoingIcon',
          text: `Outgoing ${helper.CONVERT_HM(callData.duration)}`,
          iconColorClass: 'text-success bg-light-success',
          sidebarIconColorClass: 'text-success',
        }
      }
      return {
        icon: 'PhoneIncomingIcon',
        text: `Incoming ${helper.CONVERT_HM(callData.duration)}`,
        iconColorClass: 'text-success bg-light-success',
        sidebarIconColorClass: 'text-success',
      }
    }

    return {
      icon: 'PhoneOutgoingIcon',
      text: '',
      iconColorClass: 'text-success',
    }
  }

  return {
    resolveAvatarBadgeVariant,
    mapChat,
    createGroupName,
    GET_CALL_INFO,
    GET_CALL_ERRORS,
    GET_MSG_TYPE,
    GET_FILE_ICON,
  }
}
