// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn } from '@/auth/utils'

export default function isAuthenticated({ next, router }) {
  if (!isUserLoggedIn()) {
    return router.push('login')
  }
  const isProjectSelected = localStorage.selected_project && localStorage.selected_project !== 'null'
  const isTimezoneSelected = localStorage.timezone && localStorage.timezone !== 'null'
  if (!isProjectSelected || !isTimezoneSelected) {
    return router.replace({ name: 'select-project-roadblock' }).then(() => {
      window.location.reload()
    })
  }
  return next()
}
