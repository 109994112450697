<template>
  <div
    ref="rootNode"
    data-component="Me"
  >
    <div v-if="connected">
      <div class="controls">
        <div
          class="button mic"
          :class="micState"
          @click="toggleMicState"
        />

        <div
          class="button webcam"
          :class="{
            webcamState,
            disabled: me.webcamInProgress || me.shareInProgress
          }"
          @click="toggleWebcam"
        />

        <div
          class="button change-webcam"
          :class="{
            changeWebcamState,
            disabled: me.webcamInProgress || me.shareInProgress
          }"
          @click="changeWebcam"
        />

        <div
          class="button share"
          :class="{
            shareState,
            disabled: me.shareInProgress || me.webcamInProgress
          }"
          @click="toggleShare"
        />
      </div>
    </div>

    <peer-view
      :is-me="true"
      :peer="peer ? peer : me"
      :audio-producer-id="audioProducer ? audioProducer.id : null"
      :video-producer-id="videoProducer ? videoProducer.id : null"
      :audio-rtp-parameters="audioProducer ? audioProducer.rtpParameters : null"
      :video-rtp-parameters="videoProducer ? videoProducer.rtpParameters : null"
      :audio-track="audioProducer ? audioProducer.track : null"
      :video-track="videoProducer ? videoProducer.track : null"
      :video-visible="Boolean(videoProducer) && !videoProducer.paused"
      :audio-codec="audioProducer ? audioProducer.codec : null"
      :video-codec="videoProducer ? videoProducer.codec : null"
      :audio-score="audioProducer ? audioProducer.score : null"
      :video-score="videoProducer ? videoProducer.score : null"
      :face-detection="faceDetection"
      :state="state"
      @onChangeDisplayName="changeDisplayName"
      @onChangeMaxSendingSpatialLayer="changeMaxSendingSpatialLayer"
      @onStatsClick="setStatsPeerId"
    />
  </div>
</template>

<script>
import PeerView from './PeerView/PeerView.vue'

export default {
  components: { PeerView },
  props: {
    roomClient: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      default: () => {},
    },
    peer: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    connected() {
      return this.state.room.state === 'connected'
    },
    me() {
      return this.state.me
    },
    faceDetection() {
      return this.state.room.faceDetection
    },
    producers() {
      return Object.values(this.state.producers)
    },
    audioProducer() {
      return this.producers?.find(producer => producer.track.kind === 'audio')
    },
    videoProducer() {
      const objProducer = this.producers?.find(producer => producer.track.kind === 'video')
      if (objProducer?.type === 'recording') { return null }
      return objProducer
      // return this.producers?.find(producer => producer.track.kind === 'video')
    },
    changeWebcamState() {
      if (
        Boolean(this.videoProducer)
        && this.videoProducer.type !== 'share'
        && this.me.canChangeWebcam
      ) {
        return 'on'
      }
      return 'unsupported'
    },
    shareState() {
      if (Boolean(this.videoProducer) && this.videoProducer.type === 'share') {
        return 'on'
      }
      return 'off'
    },
    webcamState() {
      let webcamState

      if (!this.me.canSendWebcam) {
        webcamState = 'unsupported'
      } else if (this.videoProducer && this.videoProducer.type !== 'share') {
        webcamState = 'on'
      } else {
        webcamState = 'off'
      }

      return webcamState
    },
    micState() {
      let micState

      if (!this.me.canSendMic) {
        micState = 'unsupported'
      } else if (!this.audioProducer) {
        micState = 'unsupported'
      } else if (!this.audioProducer.paused) {
        micState = 'on'
      } else {
        micState = 'off'
      }

      return micState
    },
  },
  methods: {
    changeDisplayName(name) {
      this.roomClient.changeDisplayName(name)
    },
    changeMaxSendingSpatialLayer(spatialLevel) {
      this.roomClient.setMaxSendingSpatialLayer(spatialLevel)
    },
    setStatsPeerId(peerId) {
      window.STORE.dispatch({ type: 'setRoomStatsPeerId', payload: { peerId } })
      // this.$store.commit('chat/setRoomStatsPeerId', { peerId })
    },
    toggleShare() {
      if (this.shareState === 'on') {
        this.roomClient.disableShare()
      } else {
        this.roomClient.enableShare()
      }
    },
    changeWebcam() {
      this.sroomClient.changeWebcam()
    },
    toggleWebcam() {
      if (this.webcamState === 'on') {
        // cookiesManager.setDevices({ webcamEnabled: false })
        this.roomClient.disableWebcam()
      } else {
        // cookiesManager.setDevices({ webcamEnabled: true })
        this.roomClient.enableWebcam()
      }
    },
    toggleMicState() {
      this.micState === 'on'
        ? this.roomClient.muteMic()
        : this.roomClient.unmuteMic()
    },
  },
}
</script>
