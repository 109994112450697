// eslint-disable-next-line import/no-cycle
import store from '@/store'

const self = JSON.parse(localStorage.getItem('userData'))

/* eslint-disable global-require */
const data = {
  profileUser: {
    id: self?.uuid,
    avatar: self?.avatar,
    name: self?.name,
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false,
    },
  },
  contacts: [],
  chats: [],
}

export default {
  getChatContact(roomUid) {
    const contact = data.contacts.find(c => c.roomUid === roomUid)
    const chat = data.chats.find(ch => ch.roomUid === roomUid)

    return {
      contact,
      chat,
    }
  },
  getChatsAndContacts() {
    data.contacts = store.state.project.projectUsers
      .filter(user => user.uuid !== self?.uuid)
      .map(({ uuid, ...user }) => ({
        ...user,
        id: uuid,
      }))

    const groups = store.state.project.projectGroups
      .map(({ roomUid, ...user }) => ({
        ...user,
        id: roomUid,
        roomUid,
      }))

    data.contacts.push(...groups)
    groups.forEach(group => {
      if (!data.chats.some(chat => chat.roomUid === group.roomUid)) {
        data.chats.push({
          ...group,
          chat: [],
          lastMessage: {},
        })
      }
    })

    const chatsContacts = data.chats
      .filter(chat => data.contacts.find(c => c.roomUid === chat.roomUid))
      .map(chat => {
        const contact = data.contacts.find(c => c.roomUid === chat.roomUid)
        contact.chat = {
          id: chat.id,
          unseenMsgs: chat.unseenMsgs ?? 0,
          lastMessage: chat.chat[chat.chat.length - 1],
          chat: chat?.chat ?? [],
        }
        return contact
      })
      .reverse()

    const profileUserData = {
      id: data.profileUser.id,
      avatar: data.profileUser.avatar,
      name: data.profileUser.name,
      status: data.profileUser.status,
    }
    return {
      data: {
        chatsContacts,
        contacts: data.contacts,
        profileUser: profileUserData,
      },
    }
  },

  getProfileUser() {
    return data.profileUser
  },

  getChats({ userId, roomUid }) {
    const chat = data.chats.find(c => c.roomUid === roomUid)
    if (chat) chat.unseenMsgs = 0
    const contact = data.contacts.find(c => c.id === userId)

    return {
      data: {
        chat,
        contact: {
          ...contact,
          roomUid,
        },
      },
    }
  },

  setInitialChatLoad(payloads) {
    payloads.forEach(payload => {
      const { roomUid, participants, messages } = payload
      if (participants.length) {
        let chatObj = data.chats.find(ch => ch.roomUid === roomUid)
        const peer = participants.find(participant => participant.uuid !== self.uuid)?.uuid
        if (messages.length) {
          if (!chatObj) {
            chatObj = this.pushIntoChats(roomUid, 'received', roomUid ?? peer)
          }
          messages.forEach(({
            messageUid, sentAt, senderUid, messageContent, messageType, deliveries, views, reacts,
          }) => {
            chatObj.chat.unshift({
              time: sentAt,
              senderId: senderUid,
              message: messageType === 'ATTACHMENT' ? JSON.parse(messageContent) : messageContent,
              messageUid,
              messageType,
              delivered: deliveries.length,
              deliveredAt: deliveries[0]?.deliveredAt,
              views,
              seen: views.length,
              seenAt: views[0]?.viewedAt,
              seenBy: views.filter(view => view.viewedBy !== self.uuid)
                .map(view => data.contacts.find(contact => contact.id === view.viewedBy)?.name),
              reacts,
            })
          })
        }
      }
    })
  },

  setUnseenMsgCount(payloads) {
    payloads.forEach(({ roomUid, unseenCount }) => {
      const chatObj = data.chats.find(chat => chat.roomUid === roomUid)
      chatObj.unseenMsgs = unseenCount
      store.dispatch('chat/removeUnseenMsgCount', roomUid)
    })
  },

  pushIntoChats(roomUid, type, senderId, contactId = null) {
    const { length } = data.chats
    const lastId = data.chats?.[length - 1]?.id ?? 1

    data.chats.push({
      id: lastId + 1,
      userId: type === 'received' ? senderId : contactId,
      unseenMsgs: 0,
      chat: [],
      roomUid,
    })
    return data.chats[data.chats.length - 1]
  },

  setChat({
    roomUid, sender, message, messageUid, messageType, type,
  }) {
    let chatObj = data.chats.find(c => c.roomUid === roomUid)
    if (!chatObj) {
      chatObj = this.pushIntoChats(roomUid, type, sender)
    }
    chatObj.chat.push({
      time: new Date(),
      senderId: sender,
      message,
      messageType,
      messageUid,
      seenBy: [],
    })
    chatObj.unseenMsgs += 1
  },

  increaseUnseenMsg(roomUid) {
    const chatObj = data.chats.find(c => c.roomUid === roomUid)
    if (chatObj) chatObj.unseenMsgs += 1
  },

  removeGroup(roomUid) {
    const group = data.contacts.find(contact => contact.roomUid === roomUid)
    data.contacts.splice(data.contacts.indexOf(group), 1)
  },

  sendMessage({
    roomUid, message, senderId, contactId, messageRequestId, messageType, messageUid, type,
  }) {
    let activeChat = data.chats.find(chat => chat.roomUid === roomUid)
    const newMessageData = {
      message,
      messageUid,
      messageType,
      messageRequestId,
      time: new Date(),
      senderId,
      delivered: false,
      seen: false,
      seenBy: [],
      reacts: [],
    }

    // If there's new chat for user create one
    let isNewChat = false
    if (activeChat === undefined) {
      isNewChat = true

      activeChat = this.pushIntoChats(roomUid, type, senderId, contactId)
    }
    if (isNewChat) activeChat.chat.push(newMessageData)

    const response = {
      roomUid,
      newMessageData,
    }
    if (isNewChat) response.chat = activeChat

    return { data: response }
  },
  trimChat(roomUid) {
    const chats = data.chats.find(chat => chat.roomUid === roomUid)
    chats.chat = chats.chat.slice(-20)
  },
}
