import apolloProvider from '@/libs/vue-apollo'
import mutation from './mutation'
import queries from './queries'

export default {
  storeProject(data) {
    return apolloProvider.mutate({
      mutation: mutation.STORE_PROJECT,
      variables: {
        ...data,
      },
    })
  },
  getProjects(data) {
    return apolloProvider.query({
      query: queries.GET_MY_PROJECTS,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  searchProjects(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.SEARCH_PROJECTS,
      variables: {
        ...data,
      },
    })
  },
  updateProject(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_PROJECT,
      variables: {
        ...data,
      },
    })
  },
  addProjectUser(query) {
    return apolloProvider.mutate({
      mutation: mutation.ADD_PROJECT_USER(query),
      variables: {},
    })
  },
  getCompanyProject(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_COMPANY_PROJECT,
      variables: {
        uuid: data.companyId,
        first: data.first,
        page: data.page,
      },
    })
  },

  getProjectUser(data) {
    return apolloProvider.query({
      query: queries.GET_USER_WITH_ROLE,
      variables: {
        uuid: data.projectId,
        first: -1,
      },
    })
  },
  getAllProjectUser(data) {
    return apolloProvider.query({
      query: queries.GET_ALL_USERS_IN_PROJECT,
      variables: {
        ...data,
      },
    })
  },
  userProjectWithPermission(data) {
    return apolloProvider.query({
      query: queries.GET_ALL_USERS_IN_PROJECT_WITH_PERMISSION,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  getProjectProductivitySettings(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_PROJECT_PRODUCTIVITY_SETTINGS,
      variables: {
        ...data,
      },
    })
  },
  updateProductivityInfo(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_PROJECT_PRODUCTIVITY_SETTINGS,
      variables: {
        ...data,
      },
    })
  },
  getProjectRole() {
    return apolloProvider.query({
      query: queries.GET_PROJECT_ROLE,
      variables: {},
    })
  },
  getAllPermissionsByProject(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_ALL_PERMISSIONS,
      variables: {
        ...data,
      },
    })
  },
  getPermissions() {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_PERMISSIONS,
    })
  },
  updateProjectPermission(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_PROJECT_PERMISSION,
      variables: {
        ...data,
      },
    })
  },
  updateCompanyPermission(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_PERMISSION,
      variables: {
        ...data,
      },
    })
  },
  getUserTimeSettings(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_USER_TIME_SETTINGS,
      variables: {
        ...data,
      },
    })
  },
}
