<template>
  <div class="w-100 h-100">
    <div class="indicators">
      <div
        v-if="!audioEnabled"
        class="icon mic-off"
      />

      <div
        v-if="!videoConsumer"
        class="icon webcam-off"
      />
    </div>
    <peer-view
      :peer="peer"
      :sidebar="sidebar"
      :audio-consumer-id="audioConsumer ? audioConsumer.id : null"
      :video-consumer-id="videoConsumer ? videoConsumer.id : null"
      :audio-rtp-parameters="audioConsumer ? audioConsumer.rtpParameters : null"
      :video-rtp-parameters="videoConsumer ? videoConsumer.rtpParameters : null"
      :consumer-spatial-layers="
        videoConsumer ? videoConsumer.spatialLayers : null
      "
      :consumer-temporal-layers="
        videoConsumer ? videoConsumer.temporalLayers : null
      "
      :consumer-current-spatial-layer="
        videoConsumer ? videoConsumer.currentSpatialLayer : null
      "
      :consumer-current-temporal-layer="
        videoConsumer ? videoConsumer.currentTemporalLayer : null
      "
      :consumer-preferred-spatial-layer="
        videoConsumer ? videoConsumer.preferredSpatialLayer : null
      "
      :consumer-preferred-temporal-layer="
        videoConsumer ? videoConsumer.preferredTemporalLayer : null
      "
      :consumer-priority="videoConsumer ? videoConsumer.priority : null"
      :audio-track="audioConsumer ? audioConsumer.track : null"
      :video-track="videoConsumer ? videoConsumer.track : null"
      :audio-muted="audioMuted"
      :video-visible="videoVisible"
      :video-multi-layer="
        videoConsumer !== undefined &&
          videoConsumer &&
          videoConsumer.type !== 'simple'
      "
      :audio-codec="audioConsumer ? audioConsumer.codec : null"
      :video-codec="videoConsumer ? videoConsumer.codec : null"
      :audio-score="audioConsumer ? audioConsumer.score : null"
      :video-score="videoConsumer ? videoConsumer.score : null"
      :face-detection="faceDetection"
      :state="state"
      :remote-audio-mute="remoteAudioMute"
      @onChangeVideoPreferredLayers="onChangeVideoPreferredLayers"
      @onChangeMaxSendingSpatialLayer="onChangeMaxSendingSpatialLayer"
      @onChangeVideoPriority="onChangeVideoPriority"
      @onRequestKeyFrame="onRequestKeyFrame"
      @onStatsClick="onSetStatsPeerId"
    />
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import PeerView from './PeerView/PeerView.vue'

export default {
  components: { PeerView },
  props: {
    peer: {
      type: Object,
      required: true,
    },
    roomClient: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      default: () => {},
    },
    sidebar: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    audioConsumer() {
      const consumersArray = this.peer.consumers.map(consumerId => Object.values(this.state.consumers).find(
        consumer => consumer.id === consumerId,
      ))

      return consumersArray
        .filter(consumer => !!consumer)
        .find(consumer => consumer.track.kind === 'audio')
    },
    audioEnabled() {
      return (
        Boolean(this.audioConsumer)
        && !this.audioConsumer.locallyPaused
        && !this.audioConsumer.remotelyPaused
      )
    },
    remoteAudioMute() {
      return this.audioConsumer?.remotelyPaused
    },
    videoVisible() {
      return (
        Boolean(this.videoConsumer)
        && !this.videoConsumer.locallyPaused
        && !this.videoConsumer.remotelyPaused
      )
    },
    videoConsumer() {
      const consumersArray = this.peer.consumers.map(consumerId => Object.values(this.state.consumers).find(
        consumer => consumer.id === consumerId,
      ))

      return consumersArray
        .filter(consumer => !!consumer)
        .find(consumer => consumer.track.kind === 'video')
    },
    videoConsumerData() {
      return Object.values(this.state.consumers).filter(consumer => consumer.peerId === this.peer.id && consumer.track.kind === 'video')
    },
    videoConsumerDataLength() {
      return this.videoConsumerData.length
    },
    audioMuted() {
      return this.state.me.audioMuted
    },
    faceDetection() {
      return this.state.room.faceDetection
    },
  },
  watch: {
    videoConsumerDataLength(val, oldVal) {
      let type = this.videoConsumerData?.[0]?.type
      let consumerId = this.videoConsumerData?.[0]?.id
      const sharing = val > oldVal
      // if the peer is sharing both webcam and screen
      if (val > 1) {
        const secondConsumer = this.videoConsumerData.find(consumer => consumer.track.kind === 'video' && consumer.id !== consumerId)
        type = secondConsumer.type
        consumerId = secondConsumer.id
      }

      const payload = {
        peerId: this.peer.id,
        sharing,
        type,
        consumerId,
      }

      if (this.peer.id && (!sharing || consumerId)) {
        EventBus.$emit('video-sharing', payload)
      }
    },
  },
  methods: {
    onRequestKeyFrame() {
      this.roomClient.requestConsumerKeyFrame(this.videoConsumer.id)
    },
    onChangeVideoPreferredLayers(
      newPreferredSpatialLayer,
      newPreferredTemporalLayer,
    ) {
      this.roomClient.setConsumerPreferredLayers(
        this.videoConsumer.id,
        newPreferredSpatialLayer,
        newPreferredTemporalLayer,
      )
    },
    onChangeMaxSendingSpatialLayer(newMaxSpatialLayer) {
      this.roomClient.setConsumerPreferredLayers(
        this.videoConsumer.id,
        newMaxSpatialLayer,
        this.roomClient.temporalLayer,
      )
    },
    onChangeVideoPriority(priority) {
      this.roomClient.setConsumerPriority(this.videoConsumer.id, priority)
    },
    onSetStatsPeerId(peerId) {
      // this.$store.commit('room/setRoomStatsPeerId', { peerId })
      window.STORE.dispatch({ type: 'setRoomStatsPeerId', payload: { peerId } })
    },
  },
}
</script>
