export default {
  namespaced: true,
  state: {
    projectCount: 0,
    isTouring: false,
    isTouringPaused: false,
    isComponentReady: null,
  },
  mutations: {
    SET_PROJECT_COUNT: (state, payload) => { state.projectCount = payload },
    SET_TOURING: (state, payload) => { state.isTouring = payload },
    SET_TOURING_PAUSED: (state, payload) => { state.isTouringPaused = payload },
    SET_COMPONENT_READY: (state, payload) => { state.isComponentReady = payload },
  },
  actions: {
    setProjectCount({ commit }, payload) {
      commit('SET_PROJECT_COUNT', payload)
    },
    setTouring({ commit }, payload) {
      commit('SET_TOURING', payload)
    },
    pauseTouring({ commit }, payload) {
      commit('SET_TOURING_PAUSED', payload)
    },
    setComponentReady({ commit }, payload) {
      commit('SET_COMPONENT_READY', payload)
    },
  },
}
