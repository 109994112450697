import { render, staticRenderFns } from "./StickerContainer.vue?vue&type=template&id=43dcf6ec&scoped=true&"
import script from "./StickerContainer.vue?vue&type=script&lang=js&"
export * from "./StickerContainer.vue?vue&type=script&lang=js&"
import style0 from "./StickerContainer.vue?vue&type=style&index=0&id=43dcf6ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43dcf6ec",
  null
  
)

export default component.exports