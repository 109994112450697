import gql from 'graphql-tag'
import { AchBillingInformationFragment, BankTransferSuccessFragment, BankWalletFragment, BillingInformationFragment, FxBillingInformationFragment, ReconciliationTransactionFragment, WalletToWalletTransferFragment } from '../fragments'

export default {
  SEARCH_USER: gql`
    query users(
      $keyword: [String!]
      $projectId: [String!]
    ) {
      users(
        user: { keyword: $keyword }
        project: { keyword: $projectId, searchKey: UUID }
      ) {
        data {
          isOnline(project: { keyword: $projectId, searchKey: UUID })
          text: name
          value: uuid
          avatar
        }
      }
    }
  `,
  SEARCH_USER_WITH_PROJECTS: gql`
  query usersWithProjects(
    $keyword: [String!]
    $projectUid: [String!]
    $first: Int
  ) {
    users(
      user: { keyword: $keyword, searchKey: NAME }
      project: { keyword: $projectUid, searchKey: UUID }
      first:$first
    ) {
      data {
        uuid
        name
        projects {
          data {
            uuid
            name
            description
          }
        }
      }
    }
  }
`,
  SEARCH_USER_BY_COMPANY: gql`
    query usersByCompany(
      $keyword: [String!]
      $companyUid: [String!]
    ) {
      users(
        user: { keyword: $keyword }
        company: { keyword: $companyUid, searchKey: UUID }
      ) {
        data {
          # isOnline(project: { keyword: $projectId, searchKey: UUID })
          text: name
          value: uuid
          email
          avatar
        }
      }
    }
  `,
  SEARCH_USER_IN_COMPANY: gql`
  query usersInCompany(
    $keyword: [String!]
    $companyUid: [String!]
  ) {
    users(
      user: { keyword: $keyword, type: LIKE, searchKey: EMAIL }
      company: { keyword: $companyUid, searchKey: UUID }
    ) {
      data {
        # isOnline(project: { keyword: $projectId, searchKey: UUID })
        text: name
        value: uuid
        email
        companyRoles(company: { keyword: $companyUid, searchKey: UUID }) {
          data {
            role {
              name
              displayName
            }
          }
        }
      }
    }
  }
`,
  SEARCH_USER_WITH_PERMISSIONS: gql`
    query usersWithPermissions(
      $keyword: [String!]
      $projectUid: [String!]
      $companyUid: [String!]
      $first: Int
      $page:Int
    ) {
      users(
        user: { keyword: $keyword }
        project:{ keyword:$projectUid, searchKey:UUID}
        first:$first
        page:$page
      ) {
        total
        data {
          uuid
          name
          avatar
          projectRoles(project: { keyword:$projectUid, searchKey:UUID }) {
            data {
              role {
                name
                displayName
              }
            }
          }
          companyRoles(company: { keyword:$companyUid, searchKey:UUID }) {
            data {
              role {
                name
                displayName
              }
            }
          }
          isOnline(project: { keyword: $projectUid, searchKey: UUID })
          permissions (
            project: { keyword: $projectUid, searchKey: UUID}
          ){
            data{
              id
              slug
              description
              permissionLevel
              allow
            }
            total
          }
        }
      }
    }
  `,
  GET_MY_GENERAL_INFO: gql`
    query generalInfo {
      me {
        id
        avatar
        extraInformations(type: [BILLING, CONTACT,ACHBILLING, FXBILLING]) {
          data {
            information {
              ... on BillingInformation {
                id
                alias
                bankAccount
                bankName
                accountHolder
                bankAddress
                bankSwiftCode
                bankIbanCode
                country {
                  name 
                  code
                }
              }
              ... on AchBillingInformation {
                id
                alias
                bankAccount
                bankName
                country {
                  code
                  name 
                }
                accountHolder
                bankAddress
                routingNumber
              }
              ... on FxBillingInformation {
                id
                alias
                bankAccount
                bankName
                country {
                  code
                  name 
                }
                accountHolder
                bankAddress
                bankSwiftCode
              }
              ... on ContactInformation {
                id
                alias
                phoneNumber
                mobileNumber
                mobileNumber2
                streetAddress
                district
                postalCode
                country {
                  name 
                  code
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_USER_GENERAL_INFO: gql`
    query generalInfo($userId: String) {
      user(uuid: $userId) {
        id
        email
        avatar
        extraInformations(type: [BILLING, CONTACT,ACHBILLING, FXBILLING]) {
          data {
            information {
              ... on BillingInformation {
                id
                alias
                bankAccount
                bankName
                country {
                  code
                  name 
                }
                accountHolder
                bankAddress
                bankSwiftCode
                bankIbanCode
              }
              ... on AchBillingInformation {
                id
                alias
                bankAccount
                bankName
                country {
                  code
                  name 
                }
                accountHolder
                bankAddress
                routingNumber
              }
              ... on FxBillingInformation {
                id
                alias
                bankAccount
                bankName
                country {
                  code
                  name 
                }
                accountHolder
                bankAddress
                bankSwiftCode
              }
              ... on ContactInformation {
                id
                alias
                phoneNumber
                mobileNumber
                mobileNumber2
                streetAddress
                district
                postalCode
                country {
                  code
                  name 
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_USER_PROFILE_INFO: gql`
   query generalInfo($userId: String) {
     user(uuid: $userId) {
       id
       avatar
       email
       createdAt
       projects {
        data {
          name
          description
        }
       }
       extraInformations(type: [BILLING, CONTACT, ACHBILLING, FXBILLING]) {
         data {
           information {
             ... on BillingInformation {
               id
               alias
               bankAccount
               bankName
               accountHolder
               bankAddress
               bankSwiftCode
               bankIbanCode
               country {
                  code
                  name 
                }
                __typename
             }
             ... on AchBillingInformation {
                id
                alias
                bankAccount
                bankName
                country {
                  code
                  name 
                }
                accountHolder
                bankAddress
                routingNumber
                __typename
              }
              ... on FxBillingInformation {
                id
                alias
                bankAccount
                bankName
                country {
                  code
                  name 
                }
                accountHolder
                bankAddress
                bankSwiftCode
                __typename
              }
             ... on ContactInformation {
               id
               alias
               phoneNumber
               mobileNumber
               mobileNumber2
               streetAddress
               district
               country {
                  code
                  name 
                }
             }
           }
         }
       }
     }
   }
 `,
  GET_USER_PROJECT_ROLES: gql`
    query companyRole($companyUid:  [String!], $projectUid: [String!]) {
      me {
        isAdmin
        uuid
        companyRoles(company: {searchKey: UUID, keyword: $companyUid, type: EXACT }, first: 1) {
          data {
            role {
              name
              displayName
            }
          }
        }
        projectRoles(project: {searchKey: UUID, keyword: $projectUid, type: EXACT }, first: 1) {
          data {
            role {
              name
              displayName
            }
          }
        }
      }
    }
 `,

  GET_USER_PERMISSION: gql`
  query getInvitePermission($keyword: [String!], $slug: String!){
    me {
      hasPermission(
        slug: $slug
        project:{ keyword: $keyword, searchKey:UUID}
      )
    }
  }
 `,

  CHECK_USER_PERMISSION: gql`
  query checkUserPermissions(
    $slugs: [String!]
    $project: [String!]
  ) {
    me {
      name
      permissions(
        slugs:$slugs,
        project: {
          keyword: $project
          searchKey: UUID
        }
      ) {
        total
        data {
          slug
          allow
        }
      }
    }
  }
 `,
  GET_USER_GENERAL_SETTINGS: gql`
  query getUserGeneralSettings($projectUid:String!,$userUid:String!) {
    project(uuid: $projectUid) {       
      uuid
      name
      users(user:{keyword:[$userUid],searchKey:UUID}){
          data{
            name
            uuid
            generalSettings:projectSettings(
              type:PROJECTGENERALSETTINGS
              project:{keyword:[$projectUid],searchKey:UUID}
              first:1
            ) {
                total
                data{
                  information{
                    ...on ProjectGeneralSettings{
                        disableScreencast
                        idleTime
                    }
                  }
                }
            }
          }

        }
      }
    }
  `,
  GET_USER_INFO: gql`
    query getProfileInfo(
      $uuid: String!
    ) {
      me(uuid: $uuid) {
        id
        uuid
        name
        avatar
        isAdmin
      }
    }
  `,

  GET_USER_NOTIFICATIONS: gql`
  query getNotifications($projectUid:String!,$page:Int!){
    me{
        id
        uuid
        unRead:notifications (project:{ keyword:[$projectUid], searchKey:UUID },includeReadMessages:false, strictFilter:false){
             total
        }
        notifications(project:{ keyword:[$projectUid], searchKey:UUID },page:$page,strictFilter:false){
        total
        hasMorePages
        data{
            uuid
            isRead
            createdAt
          creator
          {
            name
            uuid
            avatar
          }
          notification{
            __typename
            ... on NotificationManualTimeAction{

              action
              startTime
              endTime
              user{
                name
                uuid
                avatar
            }
           
            }
            ... on NotificationTimeLimitCross{
              hours
              type
              user{
                name
                uuid
                avatar
            }

            }
            ... on NotificationTimeLimitChange{
              hours
              type
            
              user{
                name
                uuid
                avatar
              }

            }
            ... on NotificationFinalizePayment{
              user {
                name
                uuid
                avatar
              }
              project {
                uuid
                name
              }
              invoice {
                invoiceId
                paidAmount
              }
            }
            ... on NotificationWalletAction{
              user {
                name
                uuid
                avatar
              }
              share {
                description
              }
              walletAction:action
              amount
            }

            ... on NotificationInvoiceVerification{
              user {
                name
                uuid
                avatar
              }
              project {
                uuid
                name
              }
              invoice {
                invoiceId
                paidAmount
                verifiedAt
                rejectedAt
              }
              verificationAction
            }

            ... on NotificationPaidInvoiceVerification{
              user {
                name
                uuid
                avatar
              }
              project {
                uuid
                name
              }
              invoice {
                updator {
                  uuid
                  name
                }
                invoiceId
                paidAmount
                verifiedAt
                rejectedAt
              }
              paidOn
              remarks
              verificationAction
            }

            ... on NotificationBankWithdraw{
              user {
                name
                uuid
              }
              wallet {
                description
              }
              amount
              description
              success
            }

            ... on NotificationPermissionUpdate{
              user {
                uuid
                name
                avatar
              }
              project{
                uuid
                name
              }
              permission{
                id
                description
              }
              permissionAction:action
            }

            ... on NotificationUserStatusChangeInProject{
              user {
                uuid
                name
                avatar
              }
              project{
                uuid
                name
              }
              active
            }

            ... on NotificationWalletShareInvite{
              user {
                uuid
                name
                avatar
              }
              creator {
                uuid
                name
                avatar
              }
              invitation {
                checksum
                shareDirection
                accepted
                processedAt
              }
              share {
                uuid
                description 
                wallet{
                  __typename
                }
                invites {
                  total
                  data {
                    isInvalid
                    checksum
                  }
                }
                sharedTo {
                  ... on User{
                    uuid
                    name
                  }
                }
              }
              walletShareInviteAction:verificationAction
            }
            ... on NotificationWalletShareRevoke{
              user {
                uuid
                name
                avatar
              }
              creator {
                uuid
                name
                avatar
              }
              
              share {
                uuid
                description 
              }
            }
            ... on NotificationWalletTransferAction{
              receiver {
                ... on User {
                  uuid
                  name
                  avatar
                }
                ... on Company {
                  uuid
                  name
                }
              }
              # transferInvoice: invoice {
              #   invoiceId
              #   invoicedAt
              #   memo
              # }
              source {
                description
              }
              destination {
                __typename
                ... on WalletShare {
                  description
                }
                ... on WorkzoneWallet {
                  description
                }
              }
              walletTransferAction: action
              # remarks
              amount
            }
          }
        }
      }
    }
  }
  `,

  GET_USER_WALLET: gql`
    query getWallet(
      $familyType: WalletFamilyType
    ) {
      me {
        walletBalance
        wallets(
          familyType: $familyType
          orderBy: { column: ORDER, order: ASC }
        ) {
          total
          data {
            uuid
            isDefault
            familyType
            description
            balance
            shares(
              includeInactive: false
              orderBy: { column: ORDER, order: ASC }
            ) {
              uuid
              description
              active
              balance
              sharePercent
              shareDirection
              family {
                uuid
                description
              }
              invites(status:PENDING) {
                total
                data{
                  processedAt
                  accepted
                  checksum
                  sharedTo {
                    ... on User {
                      name
                    }
                  }
                }
              }
              owner {
                ... on User {
                  uuid
                  name
                }
                ... on Company {
                  uuid
                  name
                }
              }
              sharedTo {
                ... on User {
                  uuid
                  name
                }
              }
              wallet {
                ... on BankWallet {
                  information {
                    ...BillingInformationFragment,
                    ...AchBillingInformationFragment,
                    ...FxBillingInformationFragment,
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
          }
        }
      }
    }
  ${BillingInformationFragment}
  ${AchBillingInformationFragment}
  ${FxBillingInformationFragment}
  `,

  GET_MY_BILLING_INFO: gql`
  query billingInfo {
    me {
      id
      extraInformations(type: [BILLING, ACHBILLING, FXBILLING]) {
        total
        data {
          information {
            ...BillingInformationFragment,
            ...AchBillingInformationFragment,
            ...FxBillingInformationFragment,
          }
        }
      }
    }
  }
  ${BillingInformationFragment}
  ${AchBillingInformationFragment}
  ${FxBillingInformationFragment}
  `,

  GET_USER_TRANSACTIONS: gql`
    query getUserTransactions(
      $first: Int,
      $page: Int,
      $startTime: DateTime!,
      $endTime: DateTime!
    ) {
      me {
        balance:walletBalance(date: $endTime)
        openingBalance:walletBalance(filter: {date:$startTime, first: $first, page: $page})
        walletTransactions(
          first: $first,
          page: $page,
          orderBy: { column: TRANSACTION_DATE, order: ASC}
          between: { startTime: $startTime, endTime: $endTime }
        ) {
          total
          data {
            amount
            description
            creator {
              uuid
              name
            }
            createdAt
            transactionDate
            walletShare {
              description
              wallet {
                ... on InternalWallet {
                  id
                }
                ...BankWalletFragment
              }
            }
            transactionType {
              ...BankTransferSuccessFragment
              ...WalletToWalletTransferFragment
              ...ReconciliationTransactionFragment
            }
          }
        }
      }
    }
    ${BankWalletFragment}
    ${BankTransferSuccessFragment}
    ${WalletToWalletTransferFragment}
    ${ReconciliationTransactionFragment}
  `,

  GET_USER_WALLET_TRANSACTIONS: gql`
    query getTransactions(
      $first: Int,
      $page: Int,
      $startTime: DateTime!,
      $endTime: DateTime!,
      $walletUid: String,
    ) {
      me {
        wallets(walletUid: $walletUid){
          data {
            uuid
            description
            balance:balance(date: $endTime)
            openingBalance:balance(date: $startTime)
            transactions(
              first: $first,
              page: $page, 
              orderBy:{column:TRANSACTION_DATE, order:ASC}
              between:{startTime: $startTime, endTime: $endTime}
            ) {
              total
              data {
                description
                amount
                createdAt
                transactionDate
                walletShare {
                  description
                  wallet {
                    ... on InternalWallet {
                      id
                    }
                    ...BankWalletFragment
                  }
                }
                transactionType {
                  __typename
                  ...BankTransferSuccessFragment
                  ...WalletToWalletTransferFragment
                }
              }
            }
          }
        }
      }
    }
    ${BankWalletFragment}
    ${BankTransferSuccessFragment}
    ${WalletToWalletTransferFragment}
  `,

  GET_USER_SHARE_TRANSACTIONS: gql`
    query getUserShareTransactions(
      $first: Int,
      $page: Int,
      $startTime: DateTime!,
      $endTime: DateTime!,
      $keyword: [String!],
      $walletUid: String,
    ) {
      me {
        wallets(walletUid: $walletUid){
          data {
            uuid
            shares(
              share:{keyword: $keyword, searchKey: UUID}
            ) {
              uuid
              balance:balance(date: $endTime)
              openingBalance:balance(filter: { date: $startTime, first: $first, page: $page })
              description
              transactions(
                first: $first,
                page: $page, 
                orderBy:{column:TRANSACTION_DATE, order:ASC}
                between:{startTime: $startTime, endTime: $endTime}
              ) {
                total
                data {
                  description
                  amount
                  createdAt
                  transactionDate
                  walletShare {
                    description
                    wallet {
                      ... on InternalWallet {
                        id
                      }
                      ...BankWalletFragment
                    }
                  }
                  transactionType {
                    __typename
                    ...BankTransferSuccessFragment
                    ...WalletToWalletTransferFragment
                  }
                }
              }
            }
          }
        }
      }
    }
    ${BankWalletFragment}
    ${BankTransferSuccessFragment}
    ${WalletToWalletTransferFragment}
  `,

  GET_USER_DOCUMENTS: gql`
  query {
  me {
    name
    documents {
      id
      document
      documentType
      verifiedAt
    }

    proofOfAddress{
        id
        document
        documentType
        verifiedAt

    }
    }
    }`,
  GET_MY_COMPANIES: gql`
    query getMyCompanies {
      me {
        companyRoles {
          data {
            company {
              uuid
              name
            }
          }
        }
      }
    }
  `,
  GET_MY_INVOICE: gql`
    query getMyInvoice(
      $invoiceId: ID!
    ) {
    me {
      invoices(
        invoiceId: $invoiceId,
        includeUnverifiedInvoices: true
      ) {
        data {
          invoiceId
          from
          to
          paidAmount
          verifiedAt
          memo
          rejectedAt
          extraPayments {
            data {
              payment {
                amount
                record {
                  ... on ExtraPayment {
                    category {
                      name
                      displayName
                      __typename
                    }
                    remarks
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
        }
      }
    }
  }
  `,
  GET_WALLET_SHARES: gql`
    query getWalletShares(
      $walletUid: String
    ){
      me{
        wallets(walletUid:$walletUid){
          data{
            shares{
              uuid
              description
              shareDirection
              owner{
                ... on User{
                  isMe
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_WALLET_FAMILIES: gql`
    query getWalletFamily {
      me{
        wallets{
          data{
            uuid
            description
            shares {
              uuid
              shareDirection
              owner {
                ...on User {
                  uuid
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_SCHEDULED_TRANSFERS: gql`
    query getScheduledTransfers(
      $includeInactive: Boolean
      $first: Int
      $page: Int
    ) {
      me {
        scheduledTransfers(
          includeInactive: $includeInactive
          page: $page
          first: $first
        ) {
          total
          data {
            uuid
            name
            description
            transferAmount
            transferType
            deactivatedAt
            owner {
              ... on User {
                uuid
                name
              }
            }
            schedule{
              __typename
              ... on CompanyPaymentOnMonth {
                firstDay
              }
              ... on CompanyPaymentOnDate {
                day
              }
              ... on CompanyPaymentOnWeek {
                weekNum
                weekDay
              }
              ... on CompanyPaymentOnDays {
                runDate
                numberDays
              }
            }
            source {
              uuid 
              description
              balance
              wallet {
                __typename
              }
              family {
                __typename
                uuid
                description
              }
            }
            destination {
              ...on WorkzoneWallet {
                __typename
                uuid
                description
              }
              ...on WalletShare {
                __typename
                uuid
                description
                wallet {
                  __typename
                }
                family {
                  uuid
                  description
                }
                sharedFamily{
                  uuid
                  description
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_MY_COMPANIES_WITH_ROLES: gql`
    query getMyCompaniesWithRoles {
      me{
        companyRoles {
          data {
            role {
              name          
            }
            company {
              name
              uuid
            }
          }
        }
      }
    }
  `,
  GET_PROJECT_USERS: gql`
    query getProjectUsers($projectUid: [String!]) {
      users(
        project: {keyword: $projectUid, searchKey: UUID}
      ) {
        data {
          uuid
          name
          email
          avatar
          projectRoles(
            project:{
              searchKey:UUID
              keyword:$projectUid
            }
          ){
            data{
              role{
                name
              }
            }
          }
          projects {
            data {
              uuid
              name
              description
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `,
  GET_USER_INFO_STATUS: gql`
  query getUserInfoStatus($userId: String) {
    user(uuid: $userId) {
      extraInformations(type: [BILLING, CONTACT, ACHBILLING, FXBILLING]) {
        data {
          information {
            __typename
          }
        }
        total
      }
    }
  }
  `,
}
