<template>
  <div class="mx-1">
    <audio
      :ref="msgData.messageUid"
      class="audio-player"
      controls
    />
  </div>
</template>

<script>
import jwt from '@/auth/jwt/useJwt'

export default {
  props: {
    msgData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentResource: '',
    }
  },
  watch: {
    currentResource(val) {
      if (val) {
        this.$refs[this.msgData.messageUid].setAttribute('src', val)
      }
    },
  },
  mounted() {
    if (this.msgData) {
      this.getChatResource(this.msgData.msg.key)
    }
  },
  methods: {
    getChatResource(key) {
      jwt.getChatResource(key).then(response => {
        this.currentResource = response.data.url
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.audio-player {
  border: 2px solid;
  border-radius: 48px;
}
.dark-layout {
  .audio-player {
    border: none;
  }
}
</style>
