import apolloProvider from '@/libs/vue-apollo'
import commonMixin from '@/utils/common'
import queries from './queries'
import mutation from './mutation'

export default {
  searchUser(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.SEARCH_USER,
      variables: {
        ...data,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  searchUserWithProjects(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.SEARCH_USER_WITH_PROJECTS,
      variables: {
        ...data,
      },
    })
  },
  searchUserByCompany(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.SEARCH_USER_BY_COMPANY,
      variables: {
        ...data,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  searchUserInCompany(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.SEARCH_USER_IN_COMPANY,
      variables: {
        ...data,
        timezone: commonMixin.getSavedTimezoneOffset(),
      },
    })
  },
  searchUserWithPermissions(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.SEARCH_USER_WITH_PERMISSIONS,
      variables: {
        ...data,
      },
    })
  },
  inviteUser(data) {
    return apolloProvider.mutate({
      mutation: mutation.INVITE_USER,
      variables: {
        ...data,
      },
    })
  },
  getGeneralSetting(userId) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: userId ? queries.GET_USER_GENERAL_INFO : queries.GET_MY_GENERAL_INFO,
      variables: { userId },
    })
  },
  postGeneralSetting(data) {
    return apolloProvider.mutate({
      mutation: mutation.SAVE_GENERAL_SETTINGS,
      variables: data,
    })
  },
  postBillingSetting(data, billingType = 'swift') {
    let mutationEndpoint = mutation.SAVE_BILLING_SETTINGS
    if (billingType === 'ach') {
      mutationEndpoint = mutation.SAVE_ACH_BILLING_SETTINGS
    } else if (billingType === 'fx') {
      mutationEndpoint = mutation.SAVE_FX_BILLING_SETTINGS
    }

    return apolloProvider.mutate({
      mutation: mutationEndpoint,
      variables: {
        ...data,
      },
    })
  },
  getUserProfileInfo(userId) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_USER_PROFILE_INFO,
      variables: { userId },
    })
  },
  getUserCompanyRole(companyUid, projectUid) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_USER_PROJECT_ROLES,
      variables: { companyUid, projectUid },
    })
  },
  getUserPermission(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_USER_PERMISSION,
      variables: {
        ...data,
      },
    })
  },
  checkUserPermission(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.CHECK_USER_PERMISSION,
      variables: {
        ...data,
      },
    })
  },
  updateUserGeneralSettings(data) {
    return apolloProvider.mutate({
      mutation: mutation.SAVE_USER_GENERAL_SETTINGS,
      variables: data,
    })
  },
  getUserGeneralSettings(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_USER_GENERAL_SETTINGS,
      variables: {
        ...data,
      },
    })
  },
  updateUserAvatar(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_AVATAR,
      variables: data,
    })
  },
  getUserInfo(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_USER_INFO,
      variables: {
        ...data,
      },
    })
  },
  getUserNotifications(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_USER_NOTIFICATIONS,
      variables: {
        ...data,
      },
    })
  },

  markNotificationAsRead(data) {
    return apolloProvider.mutate({
      mutation: mutation.MARK_NOTIFICATION_AS_READ,
      variables: {
        ...data,
      },
    })
  },
  markAllNotificationsAsRead(data) {
    return apolloProvider.mutate({
      mutation: mutation.MARK_ALL_NOTIFICATIONS_AS_READ,
      variables: {
        ...data,
      },
    })
  },
  updateUserProfile(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_PROFILE,
      variables: {
        ...data,
      },
    })
  },
  getUserWallet(data) {
    return apolloProvider.query({
      query: queries.GET_USER_WALLET,
      fetchPolicy: 'no-cache',
      variables: { ...data },
    })
  },
  configUserWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.CONFIG_USER_WALLET,
      variables: {
        ...data,
      },
    })
  },
  getMyBillingInfo() {
    return apolloProvider.query({
      query: queries.GET_MY_BILLING_INFO,
      fetchPolicy: 'no-cache',
    })
  },
  getUserTransactions(data) {
    return apolloProvider.query({
      query: queries.GET_USER_TRANSACTIONS,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  getUserWalletTransactions(data) {
    return apolloProvider.query({
      query: queries.GET_USER_WALLET_TRANSACTIONS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  getUserShareTransactions(data) {
    return apolloProvider.query({
      query: queries.GET_USER_SHARE_TRANSACTIONS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  splitUserWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.SPLIT_USER_WALLET,
      variables: {
        ...data,
      },
    })
  },
  mergeUserWallets(data) {
    return apolloProvider.mutate({
      mutation: mutation.MERGE_USER_WALLETS,
      variables: {
        ...data,
      },
    })
  },
  withdrawFromBankWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.WITHDRAW_FROM_BANK_WALLET,
      variables: {
        ...data,
      },
    })
  },
  changeUserStatusInProject(data) {
    return apolloProvider.mutate({
      mutation: mutation.CHANGE_USER_STATUS_IN_PROJECT,
      variables: {
        ...data,
      },
    })
  },
  updateUserWalletShareDetails(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_WALLET,
      variables: {
        ...data,
      },
    })
  },

  getUserDocuments() {
    return apolloProvider.query({
      query: queries.GET_USER_DOCUMENTS,
      fetchPolicy: 'no-cache',
    })
  },

  deleteUserDocuments(data) {
    return apolloProvider.mutate({
      mutation: mutation.DELETE_USER_DOCUMENT,
      variables: {
        ...data,
      },
    })
  },

  getProofOfAddressImages() {
    return apolloProvider.query({
      query: queries.GET_USER_DOCUMENTS,
      fetchPolicy: 'no-cache',
    })
  },

  updateUserDocument(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_DOCUMENT,
      variables: {
        ...data,
      },
    })
  },
  updateUserProofOfAddress(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_PROOF_OF_ADDRESS,
      variables: {
        ...data,
      },
    })
  },
  getMyCompanies() {
    return apolloProvider.query({
      query: queries.GET_MY_COMPANIES,
      fetchPolicy: 'no-cache',
    })
  },
  attachBankToWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.ATTACH_BANK_TO_INTERNAL_WALLET,
      variables: {
        ...data,
      },
    })
  },
  getMyInvoice(data) {
    return apolloProvider.query({
      query: queries.GET_MY_INVOICE,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  approveAdjustedInvoice(data) {
    return apolloProvider.mutate({
      mutation: mutation.APPROVE_ADJUSTED_INVOICE,
      variables: {
        ...data,
      },
    })
  },
  makeWalletTransfer(data) {
    return apolloProvider.mutate({
      mutation: mutation.MAKE_WALLET_TRANSFER,
      variables: {
        ...data,
      },
    })
  },
  getWalletShares(data) {
    return apolloProvider.query({
      query: queries.GET_WALLET_SHARES,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  makeAltFamilyTransfer(data) {
    return apolloProvider.mutate({
      mutation: mutation.MAKE_ALT_FAMILY_TRANSFER,
      variables: {
        ...data,
      },
    })
  },
  getWalletFamilies() {
    return apolloProvider.query({
      query: queries.GET_WALLET_FAMILIES,
      fetchPolicy: 'no-cache',
    })
  },
  updateUserWalletDetails(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_WALLET_DETAILS,
      variables: {
        ...data,
      },
    })
  },
  getProjectUsers(data) {
    return apolloProvider.query({
      query: queries.GET_PROJECT_USERS,
      fetchPolicy: 'no-cache',
      variables: { ...data },
    })
  },
  inviteForWalletShare(data) {
    return apolloProvider.mutate({
      mutation: mutation.WALLET_SHARE_INVITE,
      variables: {
        ...data,
      },
    })
  },
  approveWalletShareInvite(data) {
    return apolloProvider.mutate({
      mutation: mutation.WALLET_SHARE_APPROVE,
      variables: {
        ...data,
      },
    })
  },
  processWalletShareInvite(data) {
    return apolloProvider.mutate({
      mutation: mutation.WALLET_SHARE_PROCESS,
      variables: {
        ...data,
      },
    })
  },
  revokeWalletShareInvite(data) {
    return apolloProvider.mutate({
      mutation: mutation.REVOKE_WALLET_SHARE_INVITE,
      variables: {
        ...data,
      },
    })
  },
  revokeWalletShare(data) {
    return apolloProvider.mutate({
      mutation: mutation.REVOKE_WALLET_SHARE,
      variables: {
        ...data,
      },
    })
  },
  getScheduledTransfers(data) {
    return apolloProvider.query({
      query: queries.GET_SCHEDULED_TRANSFERS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  createWalletTransferSchedule(data) {
    return apolloProvider.mutate({
      mutation: mutation.CREATE_WALLET_TRANSFER_SCHEDULE,
      variables: {
        ...data,
      },
    })
  },
  updateWalletTransferSchedule(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_WALLET_TRANSFER_SCHEDULE,
      variables: {
        ...data,
      },
    })
  },
  activateWalletTransferSchedule(data) {
    return apolloProvider.mutate({
      mutation: mutation.ACTIVATE_WALLET_TRANSFER_SCHEDULE,
      variables: {
        ...data,
      },
    })
  },
  makeReconciliationTransaction(data) {
    return apolloProvider.mutate({
      mutation: mutation.MAKE_RECONCILIATION_TRANSACTION,
      variables: {
        ...data,
      },
    })
  },
  deactivateWalletShare(data) {
    return apolloProvider.mutate({
      mutation: mutation.DEACTIVATE_OPEN_WALLET,
      variables: { ...data },
    })
  },
  addNewWalletShares(data) {
    return apolloProvider.mutate({
      mutation: mutation.ADD_NEW_OPEN_WALLET,
      variables: { ...data },
    })
  },
  getMyCompaniesWithRoles() {
    return apolloProvider.query({
      query: queries.GET_MY_COMPANIES_WITH_ROLES,
      fetchPolicy: 'no-cache',
    })
  },
  updateUserWalletOrders(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_WALLET_ORDERS,
      variables: { ...data },
    })
  },
  updateUserWalletShareOrders(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_USER_WALLET_SHARE_ORDERS,
      variables: { ...data },
    })
  },
  getUserInfoStatus(data) {
    return apolloProvider.query({
      query: queries.GET_USER_INFO_STATUS,
      variables: { ...data },
      fetchPolicy: 'no-cache',
    })
  },
}
