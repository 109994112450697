// eslint-disable-next-line import/no-cycle
import apolloProvider from '@/libs/vue-apollo'
import mutations from './mutations'
import queries from './queries'

export default {
  getServices() {
    return apolloProvider.query({
      query: queries.GET_SERVICES,
      fetchPolicy: 'no-cache',
    })
  },
  createService(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_SERVICE,
      variables: { ...data },
    })
  },
  updateService(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_SERVICE,
      variables: { ...data },
    })
  },

  deleteService(data) {
    return apolloProvider.mutate({
      mutation: mutations.DELETE_SERVICE,
      variables: { ...data },
    })
  },

  getFAQs() {
    return apolloProvider.query({
      query: queries.GET_FAQS,
      fetchPolicy: 'no-cache',
    })
  },
  createFAQ(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_FAQ,
      variables: { ...data },
    })
  },
  updateFAQ(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_FAQ,
      variables: { ...data },
    })
  },

  deleteFAQ(data) {
    return apolloProvider.mutate({
      mutation: mutations.DELETE_FAQ,
      variables: { ...data },
    })
  },

  getTestimonials() {
    return apolloProvider.query({
      query: queries.GET_TESTIMONIALS,
      fetchPolicy: 'no-cache',
    })
  },
  createTestimonial(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_TESTIMONIAL,
      variables: { ...data },
    })
  },
  updateTestimonial(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_TESTIMONIAL,
      variables: { ...data },
    })
  },

  deleteTestimonial(data) {
    return apolloProvider.mutate({
      mutation: mutations.DELETE_TESTIMONIAL,
      variables: { ...data },
    })
  },

  getWhoWeAre() {
    return apolloProvider.query({
      query: queries.GET_WHOWEARE,
      fetchPolicy: 'no-cache',
    })
  },
  createWhoWeAre(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_WHOWEARE,
      variables: { ...data },
    })
  },
  updateWhoWeAre(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_WHOWEARE,
      variables: { ...data },
    })
  },

  deleteWhoWeAre(data) {
    return apolloProvider.mutate({
      mutation: mutations.DELETE_WHOWEARE,
      variables: { ...data },
    })
  },

  getSlider() {
    return apolloProvider.query({
      query: queries.GET_SLIDERS,
      fetchPolicy: 'no-cache',
    })
  },
  createSlider(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_SLIDER,
      variables: { ...data },
    })
  },
  updateSlider(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_SLIDER,
      variables: { ...data },
    })
  },

  deleteSlider(data) {
    return apolloProvider.mutate({
      mutation: mutations.DELETE_SLIDER,
      variables: { ...data },
    })
  },

  getResults() {
    return apolloProvider.query({
      query: queries.GET_RESULTS,
      fetchPolicy: 'no-cache',
    })
  },

  addResult(data) {
    return apolloProvider.mutate({
      mutation: mutations.ADD_RESULT,
      variables: { ...data },
    })
  },

  updateResult(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_RESULT,
      variables: { ...data },
    })
  },

  deleteResult(data) {
    return apolloProvider.mutate({
      mutation: mutations.DELETE_RESULT,
      variables: { ...data },
    })
  },

  getAllSections() {
    return apolloProvider.query({
      query: queries.GET_ALL_SECTIONS,
      fetchPolicy: 'no-cache',
    })
  },

  updateSection(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_SECTION,
      variables: { ...data },
    })
  },

  getScreenshotTabs() {
    return apolloProvider.query({
      query: queries.GET_SCREENSHOTTABS,
      fetchPolicy: 'no-cache',
    })
  },

  createScreenshotTab(data) {
    return apolloProvider.mutate({
      mutation: mutations.CREATE_SCREENSHOT_TAB,
      variables: { ...data },
    })
  },

  updateScreenshotTab(data) {
    return apolloProvider.mutate({
      mutation: mutations.UPDATE_SCREENSHOT_TAB,
      variables: { ...data },
    })
  },

  deleteScreenshotTab(data) {
    return apolloProvider.mutate({
      mutation: mutations.DELETE_SCREENSHOT_TAB,
      variables: { ...data },
    })
  },
}
