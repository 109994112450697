<template>
  <div class="w-100 centralize">
    <div class="w-100">
      <hr
        class="hr-text group-action-text gradient text-secondary"
        :data-content="`${groupActionMessage}`"
      >
      <span
        class="centralize text-muted fmt-1"
        style="margin-top: -10px; font-size: 12px;"
      >{{ showTimeForChat(msgData.time) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msgData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    projectUsers() {
      return this.$store.state.project.projectUsers
    },
    jsonMessage() {
      return JSON.parse(this.msgData.msg)
    },
    groupActionMessage() {
      let { initiator, peerUid } = this.jsonMessage
      const { type, name } = this.jsonMessage
      if (!Array.isArray(peerUid)) { peerUid = [peerUid] }
      const users = peerUid.map(userUid => this.projectUsers.find(user => user.uuid === userUid)?.name)
      initiator = this.projectUsers.find(user => user.uuid === initiator)?.name

      switch (type) {
        case 'LEFT':
          return `${users[0]} has left the conversation`
        case 'ADD':
          if (users.length < 3) return `${initiator} has added ${users.join(' and ')} to the conversation`
          return `${initiator} has added ${users.slice(0, 2).map(username => username.split(' ')[0]).join(', ')} and ${users.length - 2} other${users.length - 2 > 1 ? 's' : ''} to the conversation`
        case 'KICK':
          if (users.length < 3) return `${initiator} has removed ${users.join(' and ')} from the conversation`
          return `${initiator} has removed ${users.slice(0, 2).map(username => username.split(' ')[0]).join(', ')} and ${users.length - 2} other${users.length - 2 > 1 ? 's' : ''} from the conversation`
        case 'RENAME':
          if (name) { return `${initiator}  has renamed the conversation to '${name}'` }
          return `${initiator}  has removed the name of the conversation`
        default:
          return ''
      }
    },
  },
}
</script>
