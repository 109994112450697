import gql from 'graphql-tag'

export default {
  INVITE_USER: gql`mutation inviteUser(
        $email:String!
        $companyUid:String!,
        $role:String!
        $projectRole: UserRoleInProjectInput
      ) 
      {
        inviteUser (
          email:$email,
          companyRole: {
            companyUid:$companyUid,
            role: $role
          },
          projectRole: $projectRole
          # projectRole: {
          #   projectUid:$projectUid,
          #   role: $role
          # }
        ) {
          message
          status
        }
  }`,
  SAVE_GENERAL_SETTINGS: gql`
    mutation saveGeneralSettings($userUid: String!,$input: ContactInformationInput!) {
      updateUserContactInformation(userUid: $userUid,input: $input ) {
        message,
        status
      }
    }
  `,
  SAVE_BILLING_SETTINGS: gql`
    mutation saveBillingSettings($userUid: String!,$input: BillingInformationInput!) {
      updateUserBillingInformation(userUid: $userUid,input: $input ) {
        message,
        status
      }
    }
  `,

  SAVE_ACH_BILLING_SETTINGS: gql`
mutation saveAchBillingSettings($userUid: String!,$input: AchBillingInformationInput!) {
  updateUserAchBillingInformation(userUid: $userUid,input: $input ) {
    message,
    status
  }
}
`,

  SAVE_FX_BILLING_SETTINGS: gql`
mutation saveFxBillingSettings($userUid: String!,$input: FxBillingInformationInput!) {
  updateUserFxBillingInformation(userUid: $userUid,input: $input ) {
    message,
    status
  }
}
`,
  SAVE_USER_GENERAL_SETTINGS: gql`
    mutation updateGeneralSettings(
      $userUid: String!,
      $projectUid: String!,
      $input: UserProjectGeneralSettingsInput!
    ) {
      updateUserProjectGeneralSettings(
        userUid: $userUid,
        projectUid: $projectUid,
        input: $input
      ) {
        status
        message
      }
    }
  `,

  UPDATE_USER_AVATAR: gql`
    mutation updateUserAvatar(
      $input: Base64AvatarImagesInput!
    ) {
      uploadUserAvatar(
        input: $input
      ) {
        status
        message
      }
    }
  `,
  UPDATE_USER_PROFILE: gql`
        mutation updateUserProfile(
            $input: UserProfileInput!
        ) {
            updateUserProfile(
            input: $input
            ) {
            status
            message
            }
        }

`,

  MARK_NOTIFICATION_AS_READ: gql`
  mutation markNotificationAsRead($uuid:String!){
    markNotificationAsRead(notificationId:$uuid){
        status
        message
    }
  }
  `,
  MARK_ALL_NOTIFICATIONS_AS_READ: gql`
mutation markAllNotificationsAsRead($projectUid:String!){
    markAllNotificationsAsRead(projectUid:$projectUid){
        status
        message
    }
}
`,

  CONFIG_USER_WALLET: gql`
    mutation configWallet(
      $description: String!
      $input: [WalletShareInput!]!
      $nonDefaultWallet: Boolean
      $familyType: WalletFamilyType
    ) {
    configUserWallet(
      description: $description
      input: $input
      nonDefaultWallet: $nonDefaultWallet
      familyType: $familyType
    ) {
      status
      message
    }
  }`,

  SPLIT_USER_WALLET: gql`
    mutation splitUserWallet(
      $wallet: WalletIdentifierInput!
      $input: [WalletShareInput!]!
    ) {
      splitUserWallet(
        wallet: $wallet,
        input: $input
      ) {
        status
        message
      }
    }
  `,

  MERGE_USER_WALLETS: gql`
    mutation mergeUserWallets(
      $destination: WalletIdentifierInput!,
      $shareUids: [String!]!,
    ) {
      mergeUserWallets(
        destination: $destination,
        shareUids: $shareUids
      ) {
        status
        message
      }
    }
  `,

  WITHDRAW_FROM_BANK_WALLET: gql`
    mutation withdrawFromBankWallet(
      $wallet: WalletIdentifierInput!,
      $input: BankWalletWithdrawInput!
    ) {
      withdrawFromBankWallet(
        wallet: $wallet
        input: $input
      ) {
        status
        message
      }
    }
  `,

  MAKE_WALLET_TRANSFER: gql`
    mutation makeWalletTransfer(
      $source: WalletIdentifierInput!
      $destination: WalletIdentifierInput!
      $input: WalletTransferInput!
      $documentData: String
    ) {
      makeWalletTransfer(
        source: $source,
        destination: $destination,
        input: $input
        documentData: $documentData
      ) {
        status
        message
      }
    }
  `,

  CHANGE_USER_STATUS_IN_PROJECT: gql`
    mutation changeUserStatusInProject(
      $projectUid: String!,
      $userUid: String!,
      $enable: Boolean!
    ) {
      changeUserStatusInProject(
        projectUid: $projectUid,
        userUid: $userUid,
        enable: $enable
    ) {
      status
      message
      }
    }
  `,
  UPDATE_USER_DOCUMENT: gql`
  mutation updateUserDocument($userUid:String!, $input:[UserDocumentInput!]){
    updateUserDocument(
        userUid:$userUid,
        input:$input
    ){
        status 
        message
    } 
  }`,

  UPDATE_USER_PROOF_OF_ADDRESS: gql`
  mutation updateUserProofOfAddress($userUid:String!, $input:[UserProofOfAddressInput!]){
    updateUserProofOfAddress(
        userUid:$userUid
        input:$input
    ){
        message
        status
        }
    }`,

  DELETE_USER_DOCUMENT: gql`
    mutation deleteUserDocument($userUid:String!,$documentId:ID!,$documentType:String!){
    deleteUserDocument(
      userUid:$userUid
      documentId:$documentId,
      documentType: $documentType
    ) {
      message
    }
  }`,

  UPDATE_USER_WALLET: gql`
    mutation updateUserWalletShareDetails(
      $wallet: WalletIdentifierInput!,
      $description: String!,
    ) {
      updateUserWalletShareDetails(
        wallet: $wallet,
        description: $description
      ) {
        status
        message
      }
    }
  `,

  ATTACH_BANK_TO_INTERNAL_WALLET: gql`
    mutation attachBankingInformationToInternalWallet(
      $wallet: WalletIdentifierInput!
      $input: AttachBankInformationType!
    ) {
      attachBankingInformationToInternalWallet(
        wallet: $wallet,
        input: $input,
      ) {
        status
        message
      }
    }
  `,
  APPROVE_ADJUSTED_INVOICE: gql`
    mutation approveAdjustedInvoice(
      $userUid: String!,
      $projectUid: String!,
      $record: Int!,
      $decline: Boolean,
    ) {
      approveAdjustedInvoice(
        userUid: $userUid,
        projectUid: $projectUid,
        record: $record,
        decline: $decline
      ) {
        status
        message
      }
    }
  `,
  MAKE_ALT_FAMILY_TRANSFER: gql`
    mutation makeAltFamilyTransfer(
      $source: WalletIdentifierInput!
      $destinationWalletUid: String!
      $input: WalletTransferInput!
      $documentData: String
    ) {
      makeAltFamilyTransfer(
        source: $source,
        destinationWalletUid: $destinationWalletUid
        input: $input
        documentData: $documentData
      ) {
        status
        message
      }
    }
  `,
  UPDATE_USER_WALLET_DETAILS: gql`
    mutation updateUserWalletDetails(
      $walletUid: String!
      $description: String!
    ) {
      updateUserWalletDetails(
        walletUid: $walletUid
        description: $description
      ) {
        status
        message
      }
    }
  `,
  WALLET_SHARE_INVITE: gql`
    mutation inviteForWalletShare(
      $wallet: WalletIdentifierInput!,
      $input: ShareWalletInput!
    ) {
      inviteForWalletShare(
        wallet: $wallet,
        input: $input
      ) {
        status
        message
      }
    }
  `,
  WALLET_SHARE_APPROVE: gql`
    mutation approveWalletShareInvite(
      $shareUid: String!,
      $input: WalletShareApprovalInput!,
      $decline: Boolean
    ) {
      approveWalletShareInvite(
        shareUid: $shareUid,
        input: $input,
        decline: $decline
      ) {
        status
        message
      }
    }
  `,
  WALLET_SHARE_PROCESS: gql`
    mutation processWalletShareInvite(
      $email: String!,
      $input: WalletShareProcessInput!
    ) {
      processWalletShareInvite(
        email: $email,
        input: $input
      ) {
        access_token
        refresh_token
        expires_in
        user {
          id
          name
          uuid
        }
      }
    }
  `,
  REVOKE_WALLET_SHARE_INVITE: gql`
    mutation revokeWalletShareInvite(
      $shareUid: String!,
      $checksum: String!,
    ) {
      revokeWalletShareInvite(
        shareUid: $shareUid,
        checksum: $checksum,
      ) {
        status
        message
      }
    }
  `,
  REVOKE_WALLET_SHARE: gql`
    mutation revokeWalletShare(
      $wallet: WalletIdentifierInput!,
      $remarks: String,
    ) {
      revokeWalletShare(
        wallet: $wallet,
        remarks: $remarks,
      ) {
        status
        message
      }
    }
  `,
  CREATE_WALLET_TRANSFER_SCHEDULE: gql`
    mutation createWalletTransferSchedule(
      $source: WalletIdentifierInput!
      $destination: WalletShareIdentifierInput!
      $schedule: PaymentSettingInput!
      $input: WalletTransferSettingInput!
      $remarks: RemarkInput!
    ) {
      createWalletTransferSchedule(
        source: $source
        destination: $destination
        schedule: $schedule
        input: $input
        remarks: $remarks
      ) {
        status
        message
      }
    }
  `,
  UPDATE_WALLET_TRANSFER_SCHEDULE: gql`
    mutation updateWalletTransferSchedule(
      $scheduleUid: String!
      $destination: WalletShareIdentifierInput
      $schedule: PaymentSettingInput
      $input: WalletTransferSettingInput
      $remarks: RemarkInput
    ) {
      updateWalletTransferSchedule(
        scheduleUid: $scheduleUid
        destination: $destination
        schedule: $schedule
        input: $input
        remarks: $remarks
      ) {
        status
        message
      }
    }
  `,
  ACTIVATE_WALLET_TRANSFER_SCHEDULE: gql`
    mutation activateWalletTransferSchedule(
      $scheduleUid: String!,
      $activate: Boolean!
    ) {
      activateWalletTransferSchedule(
        scheduleUid: $scheduleUid,
        activate: $activate
      ) {
        status
        message
      }
    }
  `,
  MAKE_RECONCILIATION_TRANSACTION: gql`
    mutation makeReconciliationTransaction(
      $source: WalletIdentifierInput!,
      $input: WalletTransferInput!,
      $documentData: String
    ) {
      makeReconciliationTransaction(
        source: $source,
        input: $input,
        documentData: $documentData
      ) {
        status
        message
      }
    }
  `,
  DEACTIVATE_OPEN_WALLET: gql`
    mutation deactivateWalletShare(
      $wallet: WalletIdentifierInput!
    ) {
      deactivateWalletShare(
        wallet: $wallet
      ) {
        status
        message
      }
    }
  `,
  ADD_NEW_OPEN_WALLET: gql`
    mutation addNewWalletShares(
      $walletUid: String!
      $input: [WalletShareInput!]!
    ) {
      addNewWalletShares(
        walletUid: $walletUid
        input: $input
      ) {
        status
        message
      }
    }
  `,
  UPDATE_USER_WALLET_ORDERS: gql`
    mutation updateUserWalletOrders(
      $orderedWalletUids: [String!]!
    ) {
      updateUserWalletOrders(
        orderedWalletUids: $orderedWalletUids
      ) {
        status
        message
      }
    }
  `,
  UPDATE_USER_WALLET_SHARE_ORDERS: gql`
  mutation updateUserWalletShareOrders(
    $walletUid: String!,
    $orderedShareUids: [String!]!
  ) {
    updateUserWalletShareOrders(
      walletUid: $walletUid
      orderedShareUids: $orderedShareUids
    ) {
      status
      message
    }
  }
`,
}
